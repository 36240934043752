import { useMutation, useQuery } from '@apollo/client'
import {
  AdminDeleteOrderV3Document,
  AdminGetLegalEntityDocument,
  AdminOrderOnboardingV3SearchOrdersV3WithResolutionDataDocument,
  AdminUpdateOrderv3TaskDocument,
  Core_OrderV3,
  Core_OrderV3Task,
  Core_OrderV3TaskCompletionType,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskResolutionData_DataOneof_ContributionAgreementPreparedTaskResolutionData,
  Core_OrderV3TaskStatus,
  Core_SearchOrdersV3Response,
  Core_UpdateOrderV3TaskRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useSnackbar } from '@flock/shared-ui'
import { filterOrderV3Tasks, ORDERS_URL } from '@flock/utils'
import { navigate } from 'gatsby'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

const useIndividualOrderV3Page = (orderUuid: string) => {
  const { data: orderData, loading } = useQuery(
    AdminOrderOnboardingV3SearchOrdersV3WithResolutionDataDocument,
    {
      variables: { input: { orderUuid } },
    }
  )

  const { data: legalEntityData } = useQuery(AdminGetLegalEntityDocument, {
    skip: !orderData,
    variables: {
      input: {
        legalEntityUuid:
          orderData?.searchOrdersV3?.orders[0]?.orderLegalEntities[0]
            .legalEntityUuid,
      },
    },
  })

  const methods = useForm({ mode: 'onSubmit' })
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = methods

  const [updateTask] = useMutation(AdminUpdateOrderv3TaskDocument)
  const [deleteOrder] = useMutation(AdminDeleteOrderV3Document)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const { notify } = useSnackbar()
  const onSubmit = async (formResult: any) => {
    try {
      setSaveDisabled(true)
      const updateTaskPromises = Object.keys(dirtyFields).map((fieldKey) => {
        const formValue = formResult[fieldKey]
        const updateOrderV3TaskInput: Core_UpdateOrderV3TaskRequestInput = {
          orderUuid,
          skipSideEffect: true,
          taskData: {
            taskUuid: fieldKey,
            completionType: formValue
              ? Core_OrderV3TaskCompletionType.OrderV3TaskCompletionTypeCompletedAdmin
              : null,
            status: formValue
              ? Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
              : Core_OrderV3TaskStatus.OrderV3TaskStatusNotStarted,
          },
        }
        return updateTask({
          refetchQueries: [
            AdminOrderOnboardingV3SearchOrdersV3WithResolutionDataDocument,
          ],
          variables: {
            updateOrderV3TaskInput,
          },
        })
      })
      await Promise.all(updateTaskPromises)
      notify('Successfully saved order status', 'success')
      reset(formResult)
    } catch (e) {
      notify('An error has occurred. Please refresh and try again.', 'error')
    }
    setSaveDisabled(false)
  }

  const onDelete = async () => {
    try {
      await deleteOrder({
        refetchQueries: [
          AdminOrderOnboardingV3SearchOrdersV3WithResolutionDataDocument,
        ],
        variables: {
          deleteOrderV3Input: {
            uuid: orderUuid,
          },
        },
      })
      navigate(ORDERS_URL)
      notify('Successfully deleted order', 'success')
    } catch (e) {
      notify('An error has occurred. Please refresh and try again.', 'error')
    }
  }

  const getDocusignUrl = (task: Core_OrderV3Task) => {
    const caAgreementPreparedResolutionData = task.resolutionData
      ?.data as Core_OrderV3TaskResolutionData_DataOneof_ContributionAgreementPreparedTaskResolutionData

    const envelope =
      caAgreementPreparedResolutionData
        .contributionAgreementPreparedTaskResolutionData?.docusignEnvelope

    return `https://apps-d.docusign.com/send/documents/details/${envelope}`
  }

  let order = {} as Core_OrderV3
  if (!loading) {
    const { orders }: { orders: Core_OrderV3[] } =
      orderData?.searchOrdersV3 as Core_SearchOrdersV3Response
    ;[order] = orders
  }

  const checkOldOrder = () => {
    const tasks = filterOrderV3Tasks(order, {
      keys: [
        Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution,
      ],
    })
    // we determine whether something is an old order if the property questionnaire completion type is auto.
    if (tasks.length > 0) {
      return (
        tasks[0].status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
        tasks[0].completionType ===
          Core_OrderV3TaskCompletionType.OrderV3TaskCompletionTypeCompletedAuto
      )
    }
    return false
  }

  return {
    order,
    legalEntityData: legalEntityData?.legalEntity?.legalEntity!,
    loading,
    onSubmit: handleSubmit(onSubmit),
    saveDisabled,
    isDirty,
    methods,
    onDelete,
    getDocusignUrl,
    checkOldOrder,
  }
}

export default useIndividualOrderV3Page
