import { Core_StaticDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { CircleCheckedIcon, CircleUncheckedIcon } from '@flock/shared-ui'
import { Download } from '@mui/icons-material'
import Delete from '@mui/icons-material/Delete'
import Edit from '@mui/icons-material/Edit'
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import DeleteStaticDocumentModal from './DeleteStaticDocumentModal'
import EditStaticDocumentModal from './EditStaticDocumentModal'

type StaticDocumentsTableProps = {
  title: string
  documents: Core_StaticDocument[]
}

const StaticDocumentsTable = (props: StaticDocumentsTableProps) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedDocument, setSelectedDocument] =
    useState<Core_StaticDocument>()
  const { title, documents } = props
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      <Typography variant="h4">{title}</Typography>
      <Table>
        <TableHead>
          <TableCell width="512px">Name</TableCell>
          <TableCell>Default Visible</TableCell>
          <TableCell>Actions</TableCell>
        </TableHead>
        <TableBody>
          {documents.map((document) => {
            const { uuid, name, contentUrl, defaultEnabled } = document
            return (
              <TableRow key={uuid}>
                <TableCell>{name}</TableCell>
                <TableCell>
                  {defaultEnabled ? (
                    <CircleCheckedIcon color="moneyGreen" />
                  ) : (
                    <CircleUncheckedIcon color="gray5" />
                  )}
                </TableCell>
                <TableCell>
                  <Box display="flex" gap="16px">
                    <IconButton
                      onClick={() => window.open(contentUrl!, '_blank')}
                    >
                      <Download />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document)
                        setEditModalOpen(true)
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setSelectedDocument(document)
                        setDeleteModalOpen(true)
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      <EditStaticDocumentModal
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        selectedDocument={selectedDocument!}
      />
      <DeleteStaticDocumentModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        selectedDocument={selectedDocument!}
      />
    </Box>
  )
}

export default StaticDocumentsTable
