import { useQuery } from '@apollo/client'
import { OvermoonAdminGetLegalEntityBankAccountsDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { useState } from 'react'
import { DistributionsManagementPagePresentationalProps } from './distributionsManagementPageTypes'

const useDistributionsManagementPage: () => DistributionsManagementPagePresentationalProps =
  () => {
    const [loading, setLoading] = useState(false)
    const { refetch: getLegalEntityBankAccounts } = useQuery(
      OvermoonAdminGetLegalEntityBankAccountsDocument
    )

    const generateBankAccountsCsv = async () => {
      setLoading(true)
      const legalEntityBankAccounts = await getLegalEntityBankAccounts()
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent +=
        'UUID,Legal Entity Name,Account Number,Routing Number,Is Personal\n'
      legalEntityBankAccounts.data!.legalEntityBankAccounts!.bankAccounts.forEach(
        (bankAccount: {
          uuid: string
          name: string
          accountNumber: string
          routingNumber: string
          isPersonal: boolean
        }) => {
          const { uuid, name, accountNumber, routingNumber, isPersonal } =
            bankAccount

          csvContent += `${uuid},${name},${accountNumber},${routingNumber},${isPersonal}\n`
        }
      )

      const currentTime = new Date().getTime()

      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `bank_accounts_${currentTime}.csv`)
      document.body.appendChild(link) // Required for FF
      link.click()

      setLoading(false)
    }

    return {
      generateBankAccountsCsv,
      loading,
    }
  }

export default useDistributionsManagementPage
