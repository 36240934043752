import { useQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import {
  AdminOrderOnboardingV2GetContributorNameDocument,
  AdminOrderOnboardingV2GetFormattedAddressDocument,
  Core_OrderV3Task,
  Core_OrderV3TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import React from 'react'

export type OrderMetadata = {
  uuid: string
  legalEntityUuid: string
  createdAt: string
}

export const AddressRender = (props: { addressUuid: string }) => {
  const { addressUuid } = props
  const { loading, data: addressData } = useQuery(
    AdminOrderOnboardingV2GetFormattedAddressDocument,
    {
      variables: {
        input: {
          addressUuid,
        },
      },
    }
  )

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>{addressData?.address?.address?.formattedAddress}</>
      )}
    </>
  )
}

export const GetProcessStatus = (tasks: Core_OrderV3Task[]) => {
  if (
    tasks.every(
      (task) =>
        task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
    )
  ) {
    return 'completed'
  } else if (
    tasks.every(
      (task) =>
        task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusNotStarted
    )
  ) {
    return 'not started'
  }

  return 'in progress'
}

export const SubscriberNameRender = (props: { legalEntityUuid: string }) => {
  const { legalEntityUuid } = props
  const { loading, data: legalEntityData } = useQuery(
    AdminOrderOnboardingV2GetContributorNameDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
    }
  )

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>{legalEntityData?.legalEntity?.legalEntity?.name}</>
      )}
    </>
  )
}
