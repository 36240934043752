import { useQuery } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminGetAllInvestorAccountsDocument,
  Core_CreateInvestorAccountWithLegalEntitiesRequestInput,
  Core_ConnectInvestorAccountToLegalEntitiesRequestInput,
  Core_LegalEntity,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { Autocomplete, Grid, TextField, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CreateInvestorAccountModal from './CreateInvestorAccountModal'

type LegalEntityToInvestorSectionProps = {
  orderLegalEntities: {
    legalEntityUuid: string
    legalEntity?: Pick<Core_LegalEntity, 'name' | 'investorAccounts'> | null
  }[]
  setNewAndExistingInvestorAccountsToLegalEntityUuids: (params: {
    newInvestorAccountsPayload: Core_CreateInvestorAccountWithLegalEntitiesRequestInput[]
    existingInvestorAccountsPayload: Core_ConnectInvestorAccountToLegalEntitiesRequestInput[]
  }) => void
}

const LegalEntityToInvestorSection = (
  props: LegalEntityToInvestorSectionProps
) => {
  const { notify } = useSnackbar()
  const {
    orderLegalEntities,
    setNewAndExistingInvestorAccountsToLegalEntityUuids,
  } = props
  const [newInvestorAccounts, setNewInvestorAccounts] = useState<
    Core_CreateInvestorAccountWithLegalEntitiesRequestInput[]
  >([])

  const [legalEntityUuidToAccountId, setLegalEntityUuidToAccountId] = useState<{
    [key: string]: string | undefined
  }>({})

  const [createInvestorAccountDisabled, setCreateInvestorAccountDisabled] =
    useState(false)

  const { data } = useQuery(AdminGetAllInvestorAccountsDocument, {
    onError: () => {
      notify('Failed to get all investor accounts', 'error')
    },
  })
  const accounts = data?.getAllInvestorAccounts?.investorAccounts || []

  // set the initial state. If each legal entity has at least 1 investor account, then we can initialize the new and existing investor account payloads to empty arrays. The
  // form will not allow the user to select an investor account.
  // Otherwise, we set an existing account uuid to undefined so that the form fails validation if submit is pressed
  useEffect(() => {
    const investorAccounts = orderLegalEntities.reduce(
      (val, orderLe) =>
        orderLe!.legalEntity!.investorAccounts!.length > 0 ? val + 1 : 0,
      0
    )
    if (investorAccounts < orderLegalEntities.length) {
      setNewAndExistingInvestorAccountsToLegalEntityUuids({
        existingInvestorAccountsPayload: [
          { investorAccountUuid: 'undefined', legalEntities: [] },
        ],
        newInvestorAccountsPayload: [],
      })
    } else {
      setNewAndExistingInvestorAccountsToLegalEntityUuids({
        existingInvestorAccountsPayload: [],
        newInvestorAccountsPayload: [],
      })
      setCreateInvestorAccountDisabled(true)
    }
  }, [orderLegalEntities, setNewAndExistingInvestorAccountsToLegalEntityUuids])

  const setNewInvestorAccountsHelper = (
    newAccount: Core_CreateInvestorAccountWithLegalEntitiesRequestInput,
    setModalOpen: (state: boolean) => void
  ) => {
    const newKey =
      `${newAccount.firstName}-${newAccount.lastName}-${newAccount.email}-${newAccount.phoneNumber}`.toLowerCase()
    const foundInNew = newInvestorAccounts.find(
      (account) =>
        newKey ===
        `${account.firstName}-${account.lastName}-${account.email}-${account.phoneNumber}`.toLowerCase()
    )

    const foundInExisting = accounts?.find(
      (account) =>
        newKey ===
        `${account?.firstName}-${account?.lastName}-${account?.email}-${account?.phoneNumber}`.toLowerCase()
    )

    if (foundInNew || foundInExisting) {
      notify('Investor account already exists', 'error')
    } else {
      setNewInvestorAccounts([...newInvestorAccounts, newAccount])
      notify('Created new investor account', 'success')
      setModalOpen(false)
    }
  }

  const setLegalEntityUuidToAccountIdHelper = (params: {
    accountId?: string | number
    legalEntityUuid: string
  }) => {
    const tempLegalEntityUuidToAccountId = legalEntityUuidToAccountId
    if (params.accountId === '') {
      tempLegalEntityUuidToAccountId[params.legalEntityUuid] = undefined
    } else {
      setLegalEntityUuidToAccountId({
        ...legalEntityUuidToAccountId,
        [params.legalEntityUuid]: String(params.accountId),
      })
      tempLegalEntityUuidToAccountId[params.legalEntityUuid] = String(
        params.accountId
      )
    }

    const reverseMapping = (o: any) =>
      Object.keys(o).reduce(
        (r: any, k: any) =>
          Object.assign(r, { [o[k]]: (r[o[k]] || []).concat(k) }),
        {}
      )

    // maps investor account id to legal entity uuids []
    const accountIdToLegalEntityUuids = reverseMapping(
      tempLegalEntityUuidToAccountId
    )

    const newInvestorAccountsPayload: Core_CreateInvestorAccountWithLegalEntitiesRequestInput[] =
      []

    const existingInvestorAccountsPayload: Core_ConnectInvestorAccountToLegalEntitiesRequestInput[] =
      []
    Object.entries(accountIdToLegalEntityUuids).forEach(
      ([accountId, legalEntityUuids]) => {
        if (Number.isInteger(Number(accountId))) {
          const account = newInvestorAccounts[Number(accountId)]
          newInvestorAccountsPayload.push({
            ...account,
            legalEntities: legalEntityUuids as string[],
          })
        } else {
          existingInvestorAccountsPayload.push({
            investorAccountUuid: accountId,
            legalEntities: legalEntityUuids as string[],
          })
        }
      }
    )

    setNewAndExistingInvestorAccountsToLegalEntityUuids({
      newInvestorAccountsPayload,
      existingInvestorAccountsPayload,
    })
  }

  return (
    <Grid item xs={12}>
      <Grid container rowSpacing={2} alignItems="center">
        <Grid item xs={4}>
          <Typography sx={{ fontWeight: 600 }}>Legal Entity Name</Typography>
        </Grid>
        <Grid item xs={8}>
          <CreateInvestorAccountModal
            setNewInvestorAccounts={setNewInvestorAccountsHelper}
            disabled={createInvestorAccountDisabled}
          />
        </Grid>
        {orderLegalEntities.map((orderLe) => {
          if (orderLe!.legalEntity!.investorAccounts!.length > 0) {
            const foundAccount = accounts.find(
              (account) =>
                account?.uuid === orderLe!.legalEntity!.investorAccounts![0]
            )
            return (
              <>
                <Grid item xs={4}>
                  {orderLe!.legalEntity!.name}
                </Grid>
                <Grid item xs={8}>
                  {`${foundAccount?.firstName} ${foundAccount?.lastName}, ${foundAccount?.email}`}
                </Grid>
              </>
            )
          }
          return (
            <>
              <Grid item xs={4}>
                {orderLe!.legalEntity!.name}
              </Grid>
              <Grid item xs={8}>
                <Autocomplete
                  disablePortal
                  onChange={(_, value) => {
                    setLegalEntityUuidToAccountIdHelper({
                      legalEntityUuid: orderLe.legalEntityUuid,
                      accountId: value?.id ?? '',
                    })
                  }}
                  options={[
                    ...newInvestorAccounts.map((account, idx) => ({
                      label: `${account.firstName} ${account.lastName}, ${account.email}`,
                      id: idx,
                    })),
                    ...accounts?.map((account) => ({
                      label: `${account?.firstName} ${account?.lastName}, ${account?.email}`,
                      id: account?.uuid,
                    })),
                  ]}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Investor Account" />
                  )}
                />
              </Grid>
            </>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default LegalEntityToInvestorSection
