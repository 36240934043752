import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminEditLegalEntityMutationDocument,
  AdminGetLegalEntitiesByInvestorAccountDocument,
  AdminGetLegalEntityDocument,
  AdminSearchLegalEntitiesDocument,
  Core_LegalEntity,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import ActionFormModal from '../shared/ActionFormModal'

type EditLegalEntityParams = {
  uuid: string
  name: string
  email: string
  phoneNumber: string
  flexibleDistributionPercent: number
  dateJoined: string
}

type EditLegalEntityModalParams = {
  legalEntity: Core_LegalEntity | undefined
  isOpen: boolean
  close: () => void
}

const EditLegalEntityModal = (props: EditLegalEntityModalParams) => {
  const { legalEntity, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [editLegalEntity, { loading }] = useMutation(
    AdminEditLegalEntityMutationDocument
  )

  const onSubmit = async (editLegalEntityParams: EditLegalEntityParams) => {
    const {
      name,
      email,
      phoneNumber,
      dateJoined,
      flexibleDistributionPercent,
    } = editLegalEntityParams
    const cashDistributionEnabled = flexibleDistributionPercent > 0
    const editLegalEntityInput = {
      uuid: legalEntity?.uuid,
      name,
      email,
      phoneNumber,
      dateJoined,
      cashDistributionEnabled,
      flexibleDistributionPercent,
    }
    try {
      await editLegalEntity({
        variables: {
          editLegalEntityInput,
        },
        refetchQueries: [
          AdminGetLegalEntityDocument,
          AdminSearchLegalEntitiesDocument,
          AdminGetLegalEntitiesByInvestorAccountDocument,
        ],
      })
      notify('Successfully updated legal entity', 'success')
      close()
    } catch (e) {
      notify('Failed to update legal entity', 'error')
    }
  }
  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Legal Entity"
      formProps={{
        defaultValues: {
          flexibleDistributionPercent: legalEntity?.flexibleDistributionPercent,
        },
      }}
      inputConfigs={[
        {
          inputName: 'name',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Name',
            defaultValue: legalEntity?.name,
            fullWidth: true,
          },
        },
        {
          inputName: 'email',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Email',
            defaultValue: legalEntity?.email,
            fullWidth: true,
          },
        },
        {
          inputName: 'phoneNumber',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Phone Number',
            defaultValue: legalEntity?.phoneNumber,
            fullWidth: true,
          },
        },
        {
          inputName: 'dateJoined',
          inputType: InputType.DatePicker,
          props: {
            label: 'Date Joined',
            defaultValue: new Date(legalEntity?.dateJoined || Date()),
            fieldProps: {
              fullWidth: false,
            },
          },
        },
        {
          inputName: 'flexibleDistributionPercent',
          inputType: InputType.Dropdown,
          props: {
            defaultValue: legalEntity?.flexibleDistributionPercent,
            label: 'Cash Distribution',
            fullWidth: false,
            options: [
              {
                text: '0%',
                value: 0,
              },
              {
                text: '100%',
                value: 100,
              },
            ],
          },
        },
      ]}
    />
  )
}

export default EditLegalEntityModal
