import React from 'react'
import { styled } from '@mui/material/styles'
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'mui-phone-input-ssr'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'
import flockColors from '../flockColors/flockColors'

const TextFieldWrapper = styled('div')({
  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const StyledPhoneInput = styled(MuiPhoneNumber)({
  '& > div > input': {
    paddingTop: '0.65rem',
    paddingBottom: '0.65rem',
    minWidth: '5rem',
  },

  '& > p': {
    marginLeft: 0,
  },

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const FieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const SmallFieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const PhoneInput = (props: MuiPhoneNumberProps) => {
  const { label, size, ...otherProps } = props
  return (
    <TextFieldWrapper>
      {size === 'small' ? (
        <SmallFieldLabel variant="body2">{label}</SmallFieldLabel>
      ) : (
        <FieldLabel variant="body2">{label}</FieldLabel>
      )}
      <StyledPhoneInput
        placeholder=""
        fullWidth
        {...otherProps}
        variant="outlined"
        onlyCountries={['us']}
        defaultCountry="us"
        disableDropdown
        disableCountryCode
      />
    </TextFieldWrapper>
  )
}

export default PhoneInput
