import { Close } from '@mui/icons-material'
import { IconButton, Modal, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import React from 'react'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Paper from '../Paper/Paper'
import Typography from '../Typography/Typography'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%)',
  width: '45rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '1rem',

  [MOBILE_BREAKPOINT]: {
    width: '100%',
    maxWidth: '100%',
    height: '100%',
    maxHeight: 'unset',
    top: 0,
    left: 0,
    transform: 'unset',
    padding: 0,
    paddingBottom: 0,
  },
})

const InnerWrapper = styled('div')({
  padding: '1rem',
})

const ModalHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

export type BaseModalProps = {
  title: string
  open: boolean
  closeOnClickoff?: boolean
  onClose: () => void
  children: React.ReactNode

  headerVariant?:
    | 'button'
    | 'caption'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'inherit'
    | 'overline'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | undefined
  size?: 'small' | 'medium' | 'large'
}

const BaseModal = (props: BaseModalProps) => {
  const {
    title,
    open,
    onClose,
    children,
    closeOnClickoff,
    size,
    headerVariant,
  } = props

  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)
  let width = 'fit-content'
  if (size === 'small') {
    width = '30rem'
  } else if (size === 'medium') {
    width = '45rem'
  }
  return (
    <Modal open={open} onClose={closeOnClickoff ? onClose : () => {}}>
      <ModalWrapper
        sx={
          !isMobile
            ? {
                width,
                maxWidth: size === 'large' ? '90vw' : undefined,
              }
            : undefined
        }
      >
        <InnerWrapper>
          <ModalHeader>
            <Typography variant={headerVariant || 'h2'}>{title}</Typography>
            <IconButton onClick={onClose}>
              <Close />
            </IconButton>
          </ModalHeader>
          {children}
        </InnerWrapper>
      </ModalWrapper>
    </Modal>
  )
}

BaseModal.defaultProps = {
  closeOnClickoff: false,
  size: 'medium',
  headerVariant: 'h2',
}

export default BaseModal
