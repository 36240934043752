import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import { formattedDollarsToCents } from '@flock/utils'
import { useMutation } from '@apollo/client'
import {
  AdminCreateInvestmentTransactionDocument,
  AdminGetInvestmentInfoForLegalEntityDocument,
  AdminGetInvestmentTransactionInfoForLegalEntityDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ActionFormModal from '../shared/ActionFormModal'

type CreateInvestmentTransactionParams = {
  equityValueChange: string
  transactionType: string
  effectiveDatetime: string
  notes: string
}

type CreateInvestmentTransactionModalParams = {
  legalEntityUuid: string
  isOpen: boolean
  close: () => void
}

const CreateInvestmentTransactionModal = (
  props: CreateInvestmentTransactionModalParams
) => {
  const { legalEntityUuid, isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createInvestmentTransaction, { loading }] = useMutation(
    AdminCreateInvestmentTransactionDocument
  )

  const onSubmit = async (
    createInvestmentTransactionParams: CreateInvestmentTransactionParams
  ) => {
    const { equityValueChange, transactionType, effectiveDatetime, notes } =
      createInvestmentTransactionParams
    const createInvestmentTransactionInput = {
      legalEntityUuid,
      equityValueChange: formattedDollarsToCents(equityValueChange.toString()),
      transactionType,
      effectiveDateTime: effectiveDatetime,
      notes,
    }

    try {
      await createInvestmentTransaction({
        variables: {
          createInvestmentTransactionInput,
        },
        refetchQueries: [
          AdminGetInvestmentTransactionInfoForLegalEntityDocument,
          AdminGetInvestmentInfoForLegalEntityDocument,
        ],
      })
      notify('Successfully created investment transaction.', 'success')
      close()
    } catch (e) {
      notify('Failed to create investment transaction.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Investment Transaction"
      inputConfigs={[
        {
          inputName: 'equityValueChange',
          inputType: InputType.Text,
          required: true,
          props: {
            type: 'text',
            label: 'Equity Value',
            placeholder: '$0.00',
            fullWidth: true,
          },
        },
        {
          inputName: 'transactionType',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            type: 'text',
            label: 'Transaction Type',
            fullWidth: true,
            options: [
              {
                text: 'Contribution',
                value: 'contribution',
              },
              {
                text: 'Redemption',
                value: 'redemption',
              },
              {
                text: 'Transfer Out',
                value: 'transfer_out',
              },
              {
                text: 'Transfer In',
                value: 'transfer_in',
              },
              {
                text: 'Share Appreciation',
                value: 'share_appreciation',
              },
            ],
          },
        },
        {
          inputName: 'effectiveDatetime',
          inputType: InputType.DatePicker,
          props: {
            label: 'Effective Date',
            defaultValue: new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'notes',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Notes',
            placeholder: 'additional notes (if needed)',
            fullWidth: true,
          },
        },
      ]}
    />
  )
}

export default CreateInvestmentTransactionModal
