import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'
import {
  AdminGetLegalEntityDocument,
  AdminInitiateInvestorOnboardingDocument,
  AdminSearchLegalEntitiesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { INVESTOR_MANAGEMENT_URL } from '../../constants'
import ActionFormModal from '../shared/ActionFormModal'

type InitiateInvestorOnboardingParams = {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  type: string
  dateJoined: string
  name: string
}

type InitiateInvestorOnboardingModalParams = {
  isOpen: boolean
  close: () => void
}

const InitiateInvestorOnboardingModal = (
  props: InitiateInvestorOnboardingModalParams
) => {
  const { isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [initiateInvestorOnboarding, { loading }] = useMutation(
    AdminInitiateInvestorOnboardingDocument
  )
  const onSubmit = async (
    initiateInvestorOnboardingParams: InitiateInvestorOnboardingParams
  ) => {
    const { firstName, lastName, name, type, email, phoneNumber, dateJoined } =
      initiateInvestorOnboardingParams
    const initiateInvestorOnboardingInput = {
      firstName,
      lastName,
      name,
      type,
      email,
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
      dateJoined: dateJoined || '',
    }

    try {
      const investorAccountData = await initiateInvestorOnboarding({
        variables: {
          initiateInvestorOnboardingInput,
        },
        refetchQueries: [
          AdminGetLegalEntityDocument,
          AdminSearchLegalEntitiesDocument,
        ],
      })
      notify('Successfully created investor and legal entity.', 'success')
      handleClose()
      navigate(
        `${INVESTOR_MANAGEMENT_URL}/${investorAccountData?.data?.initiateInvestorOnboarding?.investorLegalEntityRelationship?.investorAccountUuid}`
      )
    } catch (e) {
      notify('Failed to create investor and legal entity.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Initiate Investor Onboarding"
      inputConfigs={[
        {
          inputName: 'firstName',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'First Name',
            type: 'text',
            fullWidth: true,
            'data-cy': 'investorFirstName',
          },
        },
        {
          inputName: 'lastName',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'Last Name',
            type: 'text',
            fullWidth: true,
            'data-cy': 'investorLastName',
          },
        },
        {
          inputName: 'name',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'Legal Entity Name (full name if individual)',
            type: 'text',
            fullWidth: true,
            'data-cy': 'legalEntityName',
          },
        },
        {
          inputName: 'type',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            label: 'Type',
            fullWidth: true,
            options: [
              {
                text: 'Individual',
                value: 'individual',
              },
              {
                text: 'LLC',
                value: 'llc',
              },
              {
                text: 'Corporation',
                value: 'corporation',
              },
              {
                text: 'Trust',
                value: 'trust',
              },
            ],
          },
        },
        {
          inputName: 'dateJoined',
          inputType: InputType.DatePicker,
          props: {
            label: 'Date Joined',
            defaultValue: new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'email',
          inputType: InputType.Text,
          required: true,
          props: {
            fullWidth: true,
            type: 'email',
            label: 'Email',
            'data-cy': 'legalEntityEmail',
          },
        },
        {
          inputName: 'phoneNumber',
          inputType: InputType.Phone,
          required: true,
          props: {
            fullWidth: true,
            label: 'Phone Number',
            'data-cy': 'legalEntityPhone',
          },
        },
      ]}
    />
  )
}

export default InitiateInvestorOnboardingModal
