import { Tab, Tabs } from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { forwardRef } from 'react'
import slugify from 'slugify'
import flockColors from '../flockColors/flockColors'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'
import TrackingContextProvider from '../TrackingContextProvider/TrackingContextProvider'

const Container = styled('div')({
  marginLeft: '1rem',
  marginRight: '1rem',
  backgroundColor: 'white',
  [MOBILE_BREAKPOINT]: {
    marginLeft: 0,
    marginRight: 0,
  },
})

const InnerContainer = styled('div')({
  maxWidth: '74rem',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledTabs = styled(Tabs)({
  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },

  '& > div > div': {
    width: '100%',
  },

  '& > div > span': {
    backgroundColor: flockColors.black,
    height: '0.3rem',
  },
})

const StyledImage = styled('div')({
  display: 'flex',

  '& > svg': {
    width: '1.25rem',
    height: '1.25rem',
    paddingRight: '0.5rem',
  },
  [MOBILE_BREAKPOINT]: {
    display: 'none',
  },
})

const ImageTabContainer = styled('div')({
  width: '14rem',
  height: '3.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 0,

  [MOBILE_BREAKPOINT]: {
    fontSize: '0.75rem',
    width: '100%',
  },
})

const InnerWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: 'black',
})

type ImageTabProps = {
  text: string
  handleClick: () => void
  ImageComponent: React.ReactNode
  idx: number
  children: React.ReactNode
  trackingConfig?: TrackingConfig
}

const ImageTab = (props: ImageTabProps) => {
  const { text, handleClick, trackingConfig, ImageComponent, idx, children } =
    props

  const { track } = useTracking()

  const onClickTracked = () => {
    handleClick()
    const itemTrackingName =
      trackingConfig?.name || slugify(text.toLowerCase() || '')
    track(itemTrackingName, {
      ...trackingConfig?.properties,
      type: 'subheader-click',
    })
  }

  return (
    <ImageTabContainer
      key={text}
      role="tab"
      onClick={onClickTracked}
      data-cy={`tab${idx}`}
    >
      <InnerWrapper>
        <StyledImage>{ImageComponent}</StyledImage>
        {text}
        {children}
      </InnerWrapper>
    </ImageTabContainer>
  )
}

ImageTab.defaultProps = {
  trackingConfig: null,
}

type SubHeaderItemConfig = {
  text: string
  onClick: () => void
  ImageComponent: React.ReactNode
}

export type SubHeaderProps = {
  menuItemConfigs: SubHeaderItemConfig[]
  selectedTab: number
  trackingConfig?: TrackingConfig
}

const SubHeader = (props: SubHeaderProps) => {
  const { menuItemConfigs, selectedTab, trackingConfig } = props

  const trackingName = trackingConfig?.name || 'subheader'

  return (
    <TrackingContextProvider
      name={trackingName}
      initialTrackingProperties={trackingConfig?.properties}
    >
      <Container>
        <InnerContainer>
          <StyledTabs value={selectedTab}>
            {menuItemConfigs.map((config, idx) => (
              <Tab
                key={config.text}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                component={forwardRef((refProps, _) => (
                  <ImageTab
                    {...refProps}
                    text={config.text}
                    idx={idx}
                    ImageComponent={config.ImageComponent}
                    handleClick={config.onClick}
                  >
                    {/** @ts-ignore */}
                    {refProps.children}
                  </ImageTab>
                ))}
              />
            ))}
          </StyledTabs>
        </InnerContainer>
      </Container>
    </TrackingContextProvider>
  )
}

SubHeader.defaultProps = {
  trackingConfig: null,
}

export default SubHeader
