import React from 'react'
import MuiTextField, { TextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import WebFont from '../webfontloader'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'
import flockColors from '../flockColors/flockColors'

WebFont.load({
  google: {
    families: ['Inter&display=swap'],
  },
})

const TextFieldWrapper = styled('div')({
  width: '100%',
})

const FieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const SmallFieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const StyledTextField = styled(MuiTextField)({
  '& > div > input': {
    fontFamily: 'Inter',
    paddingTop: '0.65rem',
    paddingBottom: '0.65rem',
    minWidth: '5rem',
  },
  '& > div > textarea': {
    paddingBottom: '0.75rem',
  },

  '& > div > input::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& > div > input::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },

  '& > p': {
    marginLeft: 0,
    fontFamily: 'Inter',
  },

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const TextField = (props: TextFieldProps) => {
  const { label, size, ...otherProps } = props
  return (
    <TextFieldWrapper>
      {label && (
        <>
          {size === 'small' ? (
            <SmallFieldLabel variant="body2">{label}</SmallFieldLabel>
          ) : (
            <FieldLabel variant="body2">{label}</FieldLabel>
          )}
        </>
      )}

      <StyledTextField {...otherProps} size={size} />
    </TextFieldWrapper>
  )
}

export default TextField
