import {
  AdminCreateOrderV3Mutation,
  Core_OrderV3LegalEntity,
  Core_OrderV3PropertyContribution,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskRequestData_DataOneof_PropertyQuestionnaireCompletedTaskRequestData,
  Core_OrderV3TaskRequestData_DataOneof_TaxInformationCompletedTaskRequestData,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ExpandMore } from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material'
import React from 'react'

type OrderVisualizerSectionProps = {
  orderMutationResult: AdminCreateOrderV3Mutation
}

const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)

const taskKeyFormatter = (key: string) => {
  const strippedKey = key
    .replace('ORDER_V3_TASK_KEY_', '')
    .replace('_LEGAL_ENTITY', '')
    .replace('_PROPERTY_CONTRIBUTION', '')

  return capitalizeFirstLetter(strippedKey.replace(/_/g, ' ').toLowerCase())
}

const renderPropertyContributionTasksDropdown = (
  propertyContribution: Partial<Core_OrderV3PropertyContribution>
) => {
  const address = propertyContribution.address?.formattedAddress
  return (
    <Box display="flex" flexDirection="column">
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="h6">{`${address}`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
            <Typography>{`Title Company: ${propertyContribution.titleCompany}`}</Typography>
            <Typography fontWeight="600">
              Property information process
            </Typography>
            <Typography>
              {propertyContribution?.propertyInformationProcess
                ?.map((task) => {
                  const formattedTask = taskKeyFormatter(task.key)
                  if (
                    task.key ===
                    Core_OrderV3TaskKey.OrderV3TaskKeyTaxInformationCompletedPropertyContribution
                  ) {
                    const data = task?.requestData
                      ?.data as Core_OrderV3TaskRequestData_DataOneof_TaxInformationCompletedTaskRequestData
                    return `${formattedTask}(suppressed: ${data.taxInformationCompletedTaskRequestData?.suppressed})`
                  } else if (
                    task.key ===
                    Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution
                  ) {
                    const data = task?.requestData
                      ?.data as Core_OrderV3TaskRequestData_DataOneof_PropertyQuestionnaireCompletedTaskRequestData

                    const leases =
                      data.propertyQuestionnaireCompletedTaskRequestData
                        ?.addressUuidsWithLease

                    if (leases && leases.length > 0) {
                      if (leases.length > 1) {
                        const unitLeases: string[] = []

                        leases.forEach((leaseUuid) => {
                          const found =
                            propertyContribution.address?.units?.find(
                              (unit) => unit?.uuid === leaseUuid
                            )
                          unitLeases.push(found?.unit || '')
                        })
                        return `${formattedTask}(leases: ${unitLeases.join(
                          ', '
                        )})`
                      } else {
                        return `${formattedTask} (hasLease: true)`
                      }
                    }
                  }

                  return formattedTask
                })
                .join(' -> ')}
            </Typography>
            <Typography fontWeight="600">
              Property onboarding process
            </Typography>
            <Typography>
              {propertyContribution?.propertyOnboardingProcess
                ?.map((task) => taskKeyFormatter(task.key))
                .join(' -> ')}
            </Typography>
            <Typography fontWeight="600">Title and closing process</Typography>
            <Typography>
              {propertyContribution?.titleAndClosingProcess
                ?.map((task) => taskKeyFormatter(task.key))
                .join(' -> ')}
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

const renderLegalEntityTasksDropdown = (orderLe: Core_OrderV3LegalEntity) => {
  const name = orderLe.legalEntity?.name

  const signers = orderLe.authorizedSigners

  const sellers = orderLe.sellingEntities

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{`${name}, Ownership: $${
          (orderLe?.ownershipAmountCents || 100) / 100
        }`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
          {signers?.map((signer) => (
            <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
              <Typography variant="h6">Authorized Signer</Typography>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ marginLeft: '16px', gap: 1 }}
              >
                <Typography>{`Name: ${signer?.name}`}</Typography>
                <Typography>{`Email: ${signer?.email}`}</Typography>
                <Typography>{`Is Primary: ${
                  signer?.isPrimary || false
                }`}</Typography>
                <Typography fontWeight="600">
                  Contribution Agreement Process
                </Typography>
                <Typography>
                  {signer.contributionAgreementProcess
                    .map((task) => taskKeyFormatter(task.key))
                    .join(' -> ')}
                </Typography>
                <Typography fontWeight="600">
                  Personal Information Process
                </Typography>
                <Typography>
                  {signer.personalInformationProcess
                    .map((task) => taskKeyFormatter(task.key))
                    .join(' -> ')}
                </Typography>
              </Box>
            </Box>
          ))}
          {sellers?.map((seller) => (
            <Box display="flex" flexDirection="column" sx={{ gap: 1 }}>
              <Typography variant="h6">Seller Entities</Typography>
              <Box
                display="flex"
                flexDirection="column"
                sx={{ marginLeft: '16px', gap: 1 }}
              >
                <Typography>{`Name: ${seller?.name}`}</Typography>
                <Typography>{`Type: ${seller?.type}`}</Typography>
                <Typography>{`State: ${seller?.state}`}</Typography>
                <Typography fontWeight="600">Selling entity process</Typography>
                <Typography>
                  {seller.sellingEntityProcess
                    ?.map((task) => {
                      const formattedTask = taskKeyFormatter(task.key)
                      if (
                        task.key ===
                        Core_OrderV3TaskKey.OrderV3TaskKeySellingEntityInformationCompletedLegalEntity
                      ) {
                        return `${formattedTask}(entity name: ${seller.name}, entity type: ${seller.type}, entity state: ${seller.state})`
                      }

                      return formattedTask
                    })
                    .join(' -> ')}
                </Typography>
              </Box>
            </Box>
          ))}
          <Typography fontWeight="600">LP onboarding process</Typography>
          <Typography>
            {orderLe?.lpOnboardingProcess
              ?.map((task) => taskKeyFormatter(task.key))
              .join(' -> ')}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
const OrderVisualizerSection = (props: OrderVisualizerSectionProps) => {
  const { orderMutationResult } = props
  const { order } = orderMutationResult.createOrderV3!

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ gap: 2, marginBottom: '24px' }}
    >
      <Typography variant="h4">Order Visualizer</Typography>
      <Typography variant="h5">{`Final Offer Price: ${
        order.finalOfferPriceCents / 100
      }`}</Typography>
      <Typography variant="h5">Legal Entities</Typography>
      <Box display="flex" flexDirection="column">
        {order.orderLegalEntities.map((orderLegalEntity) =>
          renderLegalEntityTasksDropdown(
            orderLegalEntity as Core_OrderV3LegalEntity
          )
        )}
      </Box>
      <Typography variant="h5">Property Contributions</Typography>
      <Box display="flex" flexDirection="column">
        {order.orderPropertyContributions.map((contribution) =>
          renderPropertyContributionTasksDropdown(
            contribution as Core_OrderV3PropertyContribution
          )
        )}
      </Box>
    </Box>
  )
}

export default OrderVisualizerSection
