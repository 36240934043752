import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminAdminUpdateLeadDocument,
  AdminGetLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { InputType } from '@flock/shared-ui'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'

type UnitDetail = {
  name: string
  propertyType: string
  bedCount: number
  bathCount: number
  halfBathCount: number
  squareFootage: number
  basement: string
  mortgageAmount: string
}

type EditLeadParams = Partial<UnitDetail> & {
  unitDetails?: UnitDetail[]
}

type EditLeadAnswersModalProps = {
  open: boolean
  onClose: () => void
  leadUuid: string
  answers: { [key: string]: any }
}

const EditLeadAnswersModal = (props: EditLeadAnswersModalProps) => {
  const { open, onClose, leadUuid, answers } = props
  const [loading, setLoading] = useState(false)
  const [updateLeadLeaseDetails] = useMutation(AdminAdminUpdateLeadDocument)

  const { notify } = useSnackbar()

  const onSubmit = async (editLeadParams: EditLeadParams) => {
    const {
      propertyType,
      bedCount,
      bathCount,
      halfBathCount,
      squareFootage,
      mortgageAmount,
      basement,
      unitDetails,
    } = editLeadParams

    let result = {}
    if (propertyType === 'multifamily') {
      result = {
        ...answers,
        propertyType,
        mortgageAmount: mortgageAmount || 0,
        unitDetails,
      }
    } else {
      result = {
        ...answers,
        bedCount: bedCount
          ? parseInt(bedCount.toString().replace(/,/g, ''), 10)
          : bedCount,
        bathCount: bathCount
          ? parseInt(bathCount.toString().replace(/,/g, ''), 10)
          : bathCount,
        halfBathCount: halfBathCount
          ? parseInt(halfBathCount.toString().replace(/,/g, ''), 10)
          : halfBathCount,
        squareFootage: squareFootage
          ? parseInt(squareFootage.toString().replace(/,/g, ''), 10)
          : squareFootage,
        basement,
        unitDetails: [],
        propertyType,
        mortgageAmount: mortgageAmount || 0,
      }
    }

    setLoading(true)
    try {
      await updateLeadLeaseDetails({
        variables: {
          updateLeadInput: {
            leadUuid,
            answers: JSON.stringify(result),
          },
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully updated lead.', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update lead details', 'error')
    }
    setLoading(false)
  }

  let prefillData: { [key: string]: any } = {}
  const {
    propertyType,
    unitDetails,
    bedCount,
    bathCount,
    halfBathCount,
    squareFootage,
    basement,
    mortgageAmount,
  } = answers
  prefillData.propertyType = propertyType
  // All new leads should have unitDetails but some old leads may not.
  if (propertyType === 'multifamily' && unitDetails) {
    unitDetails.forEach((unitDetail: UnitDetail, idx: number) => {
      prefillData[`name${idx}`] = unitDetail.name
      prefillData[`bedCount${idx}`] = unitDetail.bedCount
      prefillData[`bathCount${idx}`] = unitDetail.bathCount
      prefillData[`halfBathCount${idx}`] = unitDetail.halfBathCount
      prefillData[`squareFootage${idx}`] = unitDetail.squareFootage
      prefillData[`basement${idx}`] = unitDetail.basement
    })
    prefillData.unitDetails = unitDetails.length
  } else {
    prefillData = {
      bedCount,
      bathCount,
      halfBathCount,
      squareFootage,
      basement,
      propertyType,
      mortgageAmount,
    }
  }

  const inputConfigs = [
    {
      name: 'propertyType',
      type: InputType.Dropdown,
      required: true,
      props: {
        label: 'Property Type',
        defaultValue: propertyType,
        options: [
          {
            value: 'singlefamily',
            label: 'Single Family Detached Home',
          },
          {
            value: 'multifamily',
            label: 'Duplex, Triplex, or other small Multifamily',
          },
          {
            value: 'townhouse',
            label: 'Attached Townhouse',
          },
        ],
      },
    },
    {
      name: 'bedCount',
      type: InputType.Text,
      required: true,
      props: {
        format: 'number',
        label: 'Bedrooms',
      },
    },
    {
      name: 'bathCount',
      type: InputType.Text,
      required: true,
      props: {
        format: 'number',
        label: 'Full Bathrooms',
      },
    },
    {
      name: 'halfBathCount',
      type: InputType.Text,
      required: true,
      defaultValue: 0,
      props: {
        format: 'number',
        label: 'Half Bathrooms',
      },
    },
    {
      name: 'squareFootage',
      type: InputType.Text,
      required: true,
      props: {
        format: 'number',
        label: 'Above Ground sqft',
        placeholder: 'sqft',
      },
    },
    {
      name: 'mortgageAmount',
      type: InputType.Text,
      required: true,
      props: {
        format: 'dollars',
        label: 'Mortgage',
        placeholder: '$0',
      },
    },
  ]

  return (
    <GridFormModal
      title="Edit Lead Details"
      open={open}
      onClose={onClose}
      loading={loading}
      onSubmit={onSubmit}
      formProps={{
        defaultValues: {
          ...prefillData,
        },
      }}
      gridProps={{
        spacing: 2,
      }}
      inputConfigs={inputConfigs}
    />

    // <ActionFormModal
    //   open={open}
    //   setOpen={onClose}
    //   loading={loading}
    //   onSubmit={onSubmit}
    //   actionText="Edit Lead Details"
    //   formProps={{
    //     defaultValues: {
    //       unitDetails: 1,
    //       ...prefillData,
    //     },
    //   }}
    //   inputConfigs={inputConfigs}
    // />
  )
}

export default EditLeadAnswersModal
