import { useQuery } from '@apollo/client'
import {
  Checkbox,
  useSnackbar,
  useRegister,
} from '@flock/flock-component-library'
import {
  AdminOrderOnboardingV2GetContributorNameDocument,
  AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
  Core_LegalEntity,
  Core_OrderV3Task,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskResolutionData_DataOneof_SubscriptionDocumentsSignedTaskResolutionData,
  Core_OrderV3TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { orderV3TaskToUserView } from '@flock/utils'
import {
  Tooltip,
  AccordionDetails,
  styled,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import {
  OrderV3EntityInformationModal,
  OrderV3FinancialInformationModal,
  OrderV3PersonalInformationModal,
  OrderV3TaxInformationModal,
} from './InformationModals'
import PropertyQuestionaireModal from './OrderV3PropertyQuestionnaireModal'

const FieldLabel = styled(Typography)({
  fontWeight: 500,
  opacity: 0.5,
})

type LabelledFieldProps = {
  label: string
  value: string
}

export const LabeledField = (props: LabelledFieldProps) => {
  const { label, value } = props
  return (
    <Grid item xs={3} flexDirection="column">
      <FieldLabel>{label}</FieldLabel>
      <Typography variant="h3">{value}</Typography>
    </Grid>
  )
}

export const ContributorName = (props: { legalEntityUuid: string }) => {
  const { legalEntityUuid } = props
  const { loading, data: legalEntityData } = useQuery(
    AdminOrderOnboardingV2GetContributorNameDocument,
    {
      variables: {
        input: {
          legalEntityUuid,
        },
      },
    }
  )

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <LabeledField
          label="Contributor Name"
          value={legalEntityData?.legalEntity?.legalEntity?.name as string}
        />
      )}
    </>
  )
}

const StyledAccordionDetails = styled(AccordionDetails)({
  paddingTop: '2px',
  paddingBottom: '2px',
})
const DefaultTaskRowRenderer = (props: { task: Core_OrderV3Task }) => {
  const { task } = props
  const { register } = useFormContext()

  const userView = orderV3TaskToUserView[task.key]

  const checkboxProps = useRegister(register, task.taskUuid)

  return (
    <Tooltip
      title={
        userView.customerDescription && (
          <div style={{ fontSize: '1rem' }}>
            {`Customer sees: ${userView.customerDescription}`}
          </div>
        )
      }
      placement="top"
      arrow
    >
      <div>
        {task.key === Core_OrderV3TaskKey.OrderV3TaskKeyUnspecified ? (
          userView.adminDescription
        ) : (
          // show failed tasks as completed, but disabled so we can look into it further
          <Checkbox
            label={userView.adminDescription}
            defaultChecked={
              task.status ===
                Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted ||
              task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusFailed
            }
            disabled={
              task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusFailed
            }
            {...checkboxProps}
          />
        )}
      </div>
    </Tooltip>
  )
}

const DocumentDownloadButton = (props: {
  label: string
  legalEntityUuid: string
  documentUuid: string
}) => {
  const { label, legalEntityUuid, documentUuid } = props
  const [downloading, setDownloading] = useState(false)
  const { notify } = useSnackbar()
  const { refetch } = useQuery(
    AdminOrderOnboardingV2GetDocumentDownloadUrlDocument,
    {
      skip: true,
    }
  )

  const downloadDocument = async () => {
    try {
      setDownloading(true)
      const urlData = await refetch({
        input: {
          legalEntityUuid,
          documentUuid,
        },
      })
      window.open(
        urlData?.data?.legalEntityDocumentPresignedUrl?.presignedUrl as string,
        '_blank'
      )
    } catch (e) {
      notify(
        'An error while downloading the file. Please refresh or try again.',
        'error'
      )
    }
    setDownloading(false)
  }
  return (
    <Button
      variant="secondary"
      onClick={downloadDocument}
      disabled={downloading}
      sx={{
        marginLeft: '2rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }}
    >
      {label}
    </Button>
  )
}

const OpenModalButton = (props: {
  setOpenModal: (status: boolean) => void
  text: string
}) => {
  const { setOpenModal, text } = props
  return (
    <Button
      variant="secondary"
      onClick={() => setOpenModal(true)}
      sx={{
        marginLeft: '2rem',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
      }}
    >
      {text}
    </Button>
  )
}

export type LegalEntityProcessProps = {
  legalEntity: Core_LegalEntity
}

export type RenderProcessProps = {
  tasks: Core_OrderV3Task[]
  legalEntityUuid?: string
  AdditionalProps?: LegalEntityProcessProps
}

export type TaskProcessUtils = {
  processName: string
  processString: string
  ProcessComponent: (props: RenderProcessProps) => JSX.Element
}

const RenderContributionAgreementProcess = (props: RenderProcessProps) => {
  const { tasks } = props
  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementSignedAuthorizedSigner &&
            task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
            task.resolutionData?.data && <>Test</>}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

const RenderPropertyInformationProcess = (props: RenderProcessProps) => {
  const { tasks } = props

  const [propertyQuestionnaireModalOpen, setPropertyQuestionnaireModalOpen] =
    useState(false)
  const [financialInformationModalOpen, setFinancialInformationModalOpen] =
    useState(false)
  const [taxInformationModalOpen, setTaxInformationModalOpen] = useState(false)

  const renderPropertyInformationHelper = (task: Core_OrderV3Task) => {
    if (
      task.key ===
        // allow viewing even if incomplete
        Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution &&
      task.resolutionData?.data
    ) {
      return (
        <>
          <PropertyQuestionaireModal
            questionnaireTask={task}
            isOpen={propertyQuestionnaireModalOpen || false}
            close={() =>
              setPropertyQuestionnaireModalOpen
                ? setPropertyQuestionnaireModalOpen(false)
                : undefined
            }
          />
          <OpenModalButton
            setOpenModal={() =>
              setPropertyQuestionnaireModalOpen
                ? setPropertyQuestionnaireModalOpen(true)
                : undefined
            }
            text="Property Questionnaire"
          />
        </>
      )
    } else if (
      task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeyFinancialInformationCompletedPropertyContribution &&
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
      task.resolutionData?.data
    ) {
      return (
        <>
          <OrderV3FinancialInformationModal
            task={task}
            isOpen={financialInformationModalOpen || false}
            close={() =>
              setFinancialInformationModalOpen
                ? setFinancialInformationModalOpen(false)
                : undefined
            }
            legalEntityUuid=""
          />
          <OpenModalButton
            setOpenModal={() =>
              setFinancialInformationModalOpen
                ? setFinancialInformationModalOpen(true)
                : undefined
            }
            text="Financial Information"
          />
        </>
      )
    } else if (
      task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeyTaxInformationCompletedPropertyContribution &&
      task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
      task.resolutionData?.data
    ) {
      return (
        <>
          <OrderV3TaxInformationModal
            task={task}
            isOpen={taxInformationModalOpen || false}
            close={() =>
              setTaxInformationModalOpen
                ? setTaxInformationModalOpen(false)
                : undefined
            }
            legalEntityUuid=""
          />
          <OpenModalButton
            setOpenModal={() =>
              setTaxInformationModalOpen
                ? setTaxInformationModalOpen(true)
                : undefined
            }
            text="Tax Information"
          />
        </>
      )
    }
    return undefined
  }
  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {renderPropertyInformationHelper(task)}
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderPropertyOnboardingProcess = (props: RenderProcessProps) => {
  const { tasks } = props
  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderTitleAndClosingProcess = (props: RenderProcessProps) => {
  const { tasks } = props
  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeyTitleCommitmentRequestedPropertyContribution && (
            <Typography variant="p1" sx={{ fontWeight: 'bold' }}>
              Title Commitment
            </Typography>
          )}
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementSubmittedToTitlePropertyContribution && (
            <Typography variant="p1" sx={{ fontWeight: 'bold' }}>
              Closing with the Title Company
            </Typography>
          )}
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeyLeaseSentPropertyContribution && (
            <Typography variant="p1" sx={{ fontWeight: 'bold' }}>
              Title Company Signed Closing Package Items
            </Typography>
          )}
          <DefaultTaskRowRenderer task={task} />
        </StyledAccordionDetails>
      ))}
    </>
  )
}
const RenderPersonalInformationProcess = (props: RenderProcessProps) => {
  const { tasks, legalEntityUuid } = props

  const [personalInformationModalOpen, setPersonalInformationModalOpen] =
    useState(false)

  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeyPersonalInformationCompletedAuthorizedSigner &&
            task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
            task.resolutionData?.data && (
              <>
                <OrderV3PersonalInformationModal
                  task={task}
                  isOpen={personalInformationModalOpen || false}
                  close={() =>
                    setPersonalInformationModalOpen
                      ? setPersonalInformationModalOpen(false)
                      : undefined
                  }
                  legalEntityUuid={legalEntityUuid || ''}
                />
                <OpenModalButton
                  setOpenModal={() =>
                    setPersonalInformationModalOpen
                      ? setPersonalInformationModalOpen(true)
                      : console.log('undef')
                  }
                  text="Personal Information"
                />
              </>
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

const RenderSellingEntityInformationProcess = (props: RenderProcessProps) => {
  const { tasks, legalEntityUuid } = props

  const [entityInformationModalOpen, setEntityInformationModalOpen] =
    useState(false)

  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeySellingEntityInformationCompletedLegalEntity &&
            task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
            task.resolutionData?.data && (
              <>
                <OrderV3EntityInformationModal
                  task={task}
                  isOpen={entityInformationModalOpen || false}
                  close={() =>
                    setEntityInformationModalOpen
                      ? setEntityInformationModalOpen(false)
                      : undefined
                  }
                  legalEntityUuid={legalEntityUuid || ''}
                />
                <OpenModalButton
                  setOpenModal={() =>
                    setEntityInformationModalOpen
                      ? setEntityInformationModalOpen(true)
                      : undefined
                  }
                  text="Selling Entity Information"
                />
              </>
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

const RenderLpOnboardingProcess = (props: RenderProcessProps) => {
  const { tasks, legalEntityUuid } = props
  return (
    <>
      {tasks.map((task: Core_OrderV3Task) => (
        <StyledAccordionDetails key={task.taskUuid}>
          <DefaultTaskRowRenderer task={task} />
          {task.key ===
            Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSignedLegalEntity &&
            task.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted &&
            task.resolutionData?.data && (
              <DocumentDownloadButton
                label="Download Signed Subscription Booklet"
                documentUuid={
                  (
                    task.resolutionData
                      .data as Core_OrderV3TaskResolutionData_DataOneof_SubscriptionDocumentsSignedTaskResolutionData
                  ).subscriptionDocumentsSignedTaskResolutionData
                    ?.subscriptionBookletSignedDocumentUuid || ''
                }
                legalEntityUuid={legalEntityUuid || ''}
              />
            )}
        </StyledAccordionDetails>
      ))}
    </>
  )
}

export const propertyContributionProcessUtils = [
  {
    processName: 'propertyInformationProcess',
    processString: 'Property Information',
    ProcessComponent: RenderPropertyInformationProcess,
  },
  {
    processName: 'propertyOnboardingProcess',
    processString: 'Property Onboarding',
    ProcessComponent: RenderPropertyOnboardingProcess,
  },
  {
    processName: 'titleAndClosingProcess',
    processString: 'Title and Closing',
    ProcessComponent: RenderTitleAndClosingProcess,
  },
]

export const legalEntityProcessUtils = [
  {
    processName: 'lpOnboardingProcess',
    processString: 'Limited Partner Onboarding',
    ProcessComponent: RenderLpOnboardingProcess,
  },
]

export const authorizedSignerProcessUtils = [
  {
    processName: 'contributionAgreementProcess',
    processString: 'Contribution Agreement',
    ProcessComponent: RenderContributionAgreementProcess,
  },
  {
    processName: 'personalInformationProcess',
    processString: 'Personal Information',
    ProcessComponent: RenderPersonalInformationProcess,
  },
]

export const sellingEntityProcessUtils = [
  {
    processName: 'sellingEntityProcess',
    processString: 'Selling Entity',
    ProcessComponent: RenderSellingEntityInformationProcess,
  },
]
