import {
  HOME_OPS_TASKS,
  SALES_OUTBOUND_TASKS,
  SALES_TASKS,
} from '../../constants'
// Keep this in sync with entities/tasks.go in the go backend

export type TaskData = {
  uuid: string
  type: string
  description: string
  status: TaskStatus
  requestData: {
    requestData: string
    rejectionOptions: RejectionOption[]
    taskUrls: TaskLink[]
  }
  operatorUuid: string
}

export type RejectionOption = {
  label: string
  value: string
}

export type TaskLink = {
  detail: string
  url: string
}

export enum TaskRole {
  SALES = 'sales',
  OUTBOUND_SALES = 'outboundSales',
  HOME_OPS = 'homeOps',
}

export enum Task {
  REQUEST_ADDITIONAL_INFO = 'request_additional_info',
  DELIVER_VALUATION = 'deliver_valuation',
  OUTBOUND_INITIAL_VALUATION = 'outbound_initial_valuation',
  CREATE_VALUATION = 'create_valuation',
  DST_LEAD = 'dst_lead',
}

export const taskTypesByRole = {
  [TaskRole.SALES]: [Task.REQUEST_ADDITIONAL_INFO, Task.DELIVER_VALUATION],
  [TaskRole.OUTBOUND_SALES]: [Task.OUTBOUND_INITIAL_VALUATION],
  [TaskRole.HOME_OPS]: [Task.CREATE_VALUATION, Task.DST_LEAD],
}

export const taskUrlsByRole = {
  [TaskRole.SALES]: SALES_TASKS,
  [TaskRole.OUTBOUND_SALES]: SALES_OUTBOUND_TASKS,
  [TaskRole.HOME_OPS]: HOME_OPS_TASKS,
}

export enum TaskStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
}
