import React from 'react'
import { styled } from '@mui/material/styles'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'
import CircularProgress from '../CircularProgress/CircularProgress'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
  height: '50vh',

  [MOBILE_BREAKPOINT]: {
    height: 'calc(100vh - 11rem)',
  },
})

const LoadingWrapper = styled('div')({
  paddingTop: '2rem',
})

type LoadingCardProps = {
  text: string
  hideLoader?: boolean
}

const LoadingCard = (props: LoadingCardProps) => {
  const { text, hideLoader } = props
  return (
    <Container>
      <Typography variant="h2" align="center">
        {text}
      </Typography>
      {!hideLoader && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}
    </Container>
  )
}

LoadingCard.defaultProps = {
  hideLoader: false,
}

export default LoadingCard
