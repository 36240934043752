import { Box, Typography } from '@mui/material'
import React from 'react'

type PageWrapperProps = {
  title: string
  children: React.ReactNode
}

const PageWrapper = (props: PageWrapperProps) => {
  const { title, children } = props
  return (
    <Box display="flex" flexDirection="column" py="48px" gap="32px">
      <Typography variant="h2">{title}</Typography>
      {children}
    </Box>
  )
}

export default PageWrapper
