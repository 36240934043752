import React from 'react'
import { flockColors } from '@flock/flock-component-library'
import { styled, TableCell, TableRow } from '@mui/material'
import { navigate } from 'gatsby'
import { formatAddressString, formatCityStateZip } from '@flock/utils'
import { ArrowForwardIos } from '@mui/icons-material'
import {
  Core_ValidatedAddress,
  Core_Lead,
} from '@flock/flock-gql-server/src/__generated__/graphql'

export const formatLead = (lead: Core_Lead | undefined | null) => {
  const address = `${formatAddressString(
    lead?.address as Core_ValidatedAddress
  )} ${formatCityStateZip(lead?.address as Core_ValidatedAddress)}`
  return {
    name: lead?.fullName,
    address,
    email: lead?.email,
    phoneNumber: lead?.phoneNumber,
    leadActionsParams: {
      lead,
    },
  }
}

export const leadColumns = [
  { name: 'name', label: 'Name' },
  { name: 'address', label: 'Address' },
  { name: 'email', label: 'Email' },
  { name: 'phoneNumber', label: 'Phone' },
  {
    name: 'leadActionsParams',
    options: {
      customHeadLabelRender: () => '',
      draggable: false,
      download: false,
      filter: false,
      print: false,
      sort: false,
    },
  },
]

export const StyledLeadRow = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

// TODO: parametrize this in terms of an onclick that takes (leadActionsParams) => void
export const CustomLeadRowRender =
  (path: string) => (data: [string, string, string, string, any]) => {
    const [name, address, email, phoneNumber, leadActionsParams] = data

    return (
      <StyledLeadRow
        onClick={() => {
          navigate(`${path}/${leadActionsParams.lead.uuid}`)
        }}
        style={{ cursor: 'pointer' }}
        data-cy="leadDetailsButton"
      >
        <TableCell>{name}</TableCell>
        <TableCell>{address}</TableCell>
        <TableCell>{email}</TableCell>
        <TableCell>{phoneNumber}</TableCell>
        <TableCell align="right">
          <ArrowForwardIos />
        </TableCell>
      </StyledLeadRow>
    )
  }
