/* eslint-disable no-case-declarations */
import React, { useState } from 'react'
import { Modal, Paper } from '@mui/material'
import { styled } from '@mui/material/styles'
import TextField from '../TextField/TextField'
import Button from '../Button/Button'
import Typography from '../Typography/Typography'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '5rem',
  left: '50%',
  transform: 'translate(-50%, 0)',
  maxHeight: '75vh',
  minWidth: '25vw',
  overflowY: 'scroll',
  padding: '2rem',
  marginTop: '2rem',
})

const ValidationContainer = styled('div')({
  paddingTop: '0.5rem',
})

const ContentContainer = styled('div')({
  padding: '1.5rem',
  justifyContent: 'center',
  textAlign: 'center',
})

const YesNoContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
})

const StyledButton = styled(Button)({
  marginLeft: '0.5rem',
  marginRight: '0.5rem',
})

type TextWithConfirmationProps = {
  onChange: any
  onBlur: any
  helperText?: string | null
  fetchDisplayData: (text: string) => any
  TextToConfirmDisplay: (props: any) => JSX.Element
}

const TextWithConfirmation = (props: TextWithConfirmationProps) => {
  // TODO: improve UX around error handling if we encounter more use cases for this
  const {
    helperText,
    onBlur,
    onChange: onFormChange,
    fetchDisplayData,
    TextToConfirmDisplay,
    ...textFieldProps
  } = props as TextWithConfirmationProps

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false)
  const [confirmed, setConfirmed] = useState(false)
  const [textRequiringConfirmation, setTextRequiringConfirmation] = useState('')
  const [displayData, setDisplayData] = useState<any>()
  const [loadingDisplayData, setLoadingDisplayData] = useState(false)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // stage new value for confirmation, reset other state and form values to defaults
    setTextRequiringConfirmation(e.target.value)
    setConfirmed(false)
    onFormChange({ target: { value: undefined } })
  }

  return (
    <>
      <TextField
        onChange={onChange}
        helperText={helperText}
        {...textFieldProps}
      />
      <ValidationContainer>
        {!confirmed ? (
          <Button
            disabled={loadingDisplayData}
            variant="outlined"
            onClick={async () => {
              if (textRequiringConfirmation !== '') {
                // Load the data requiring validation and open the modal
                setLoadingDisplayData(true)
                const data = await fetchDisplayData(textRequiringConfirmation)
                setLoadingDisplayData(false)
                setConfirmationModalOpen(true)
                setDisplayData(data)
              } else {
                // register error
                setTextRequiringConfirmation('')
                setConfirmed(false)
                onFormChange({ target: { value: '' } })
                onBlur()
              }
            }}
          >
            {loadingDisplayData ? 'Loading...' : 'Validate'}
          </Button>
        ) : (
          <div>
            &#9989; Validated:{' '}
            <TextToConfirmDisplay displayData={displayData} />
          </div>
        )}
      </ValidationContainer>
      <Modal open={confirmationModalOpen}>
        <ModalWrapper>
          <Typography variant="h2">Is this correct?</Typography>
          <ContentContainer>
            <TextToConfirmDisplay displayData={displayData} />
          </ContentContainer>
          <YesNoContainer>
            <StyledButton
              variant="contained"
              onClick={() => {
                setConfirmed(true)
                onFormChange({ target: { value: textRequiringConfirmation } })
                onBlur()
                setConfirmationModalOpen(false)
              }}
            >
              Yes
            </StyledButton>
            <StyledButton
              variant="contained"
              onClick={() => {
                setConfirmed(false)
                onBlur()
                setConfirmationModalOpen(false)
              }}
            >
              No
            </StyledButton>
          </YesNoContainer>
        </ModalWrapper>
      </Modal>
    </>
  )
}

TextWithConfirmation.defaultProps = {
  helperText: null,
}

export default TextWithConfirmation
