import {
  Core_OrderV2,
  Core_OrderV2Task,
  Core_OrderV2TaskKey,
  Core_OrderV2TaskSource,
  Core_OrderV2TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { flattenDeep, flatMapDeep } from 'lodash'

type OrderV2TaskUserView = {
  [key: string]: {
    adminActionDescription: string
    customerActionDescription: string
    customerWaitingForDescription: string
  }
}

export const orderV2TaskToUserView: OrderV2TaskUserView = {
  ORDER_V2_TASK_KEY_CONTRIBUTION_AGREEMENT_PREPARED: {
    adminActionDescription:
      '[System] Automatically fill Contribution Agreement and surface to customer with link to sign via DocuSign.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_CONTRIBUTION_AGREEMENT_SIGNED: {
    adminActionDescription:
      'Confirm customer has signed contribution agreement in DocuSign.',
    customerActionDescription: 'Sign your contribution agreement.',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_EARNEST_MONEY_WIRED: {
    adminActionDescription: 'Wire earnest money to the title company.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_PROPERTY_QUESTIONNAIRE_COMPLETED: {
    adminActionDescription:
      'Customer has completed property questionnaire form with additional property information.',
    customerActionDescription:
      "Fill out the property questionnaire to share some information we'll need to manage the property.",
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_INSURANCE_CERTIFICATE_REQUESTED: {
    adminActionDescription:
      "Email Flock's insurance company a request to insure the property's address starting on the closing date.",
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_INSURANCE_CERTIFICATE_RECEIVED: {
    adminActionDescription:
      'Confirm insurance company sent a certificate of insurance.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_CUSTOMER_INSURANCE_CANCELLATION_SENT: {
    adminActionDescription:
      'Email customer to remind them they can cancel their insurance starting on the closing date.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_INSPECTION_SCHEDULED: {
    adminActionDescription: 'Schedule inspection.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_TITLE_COMMITMENT_REQUESTED: {
    adminActionDescription:
      'Email completed Contribution Agreement to the title company to initiate Title Commitment and Flock legal counsel for review.',
    customerActionDescription: '',
    customerWaitingForDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V2_TASK_KEY_TITLE_COMMITMENT_SENT: {
    adminActionDescription:
      'Confirm the title company has sent the Title Commitment to the customer.',
    customerActionDescription: '',
    customerWaitingForDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V2_TASK_KEY_TITLE_AUTHORIZATION_PREPARED: {
    adminActionDescription: 'Title Prepared',
    customerActionDescription: '',
    customerWaitingForDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V2_TASK_KEY_TITLE_AUTHORIZATION_SIGNED: {
    adminActionDescription: 'Title Signed',
    customerActionDescription: '',
    customerWaitingForDescription:
      'The title company is preparing the Title Commitment and will email it in the coming days.',
  },
  ORDER_V2_TASK_KEY_TITLE_COMMITMENT_REVIEWED: {
    adminActionDescription:
      "Review the Title Commitment with Flock's legal counsel as needed.",
    customerActionDescription: '',
    customerWaitingForDescription:
      'You should have received a Title Commitment from the title company.',
  },
  ORDER_V2_TASK_KEY_CONTRIBUTION_AGREEMENT_SUBMITTED_TO_TITLE: {
    adminActionDescription:
      'Email/upload Contribution Agreement to title company.',
    customerActionDescription: '',
    customerWaitingForDescription:
      'The title company will be reaching out via email to kick off the title process and collect information. Then title will work to prepare a clean transfer.',
  },
  ORDER_V2_TASK_KEY_SETTLEMENT_STATEMENT_COMPLETED: {
    adminActionDescription:
      'Work with title to ensure all requests and documents are completed.',
    customerActionDescription: '',
    customerWaitingForDescription:
      'Flock will reach out once the settlement statement is ready for your review.',
  },
  ORDER_V2_TASK_KEY_TITLE_CLEAR_TO_CLOSE: {
    adminActionDescription:
      'Confirm all outstanding requests are completed. CTC (clear to close)',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_SETTLEMENT_STATEMENT_SIGNED: {
    adminActionDescription:
      '[Waiting for] Customer to review and sign settlement statement. Flock approves of settlement statement received from title company.',
    customerActionDescription:
      "Review the settlement statement sent by Flock and schedule a time to sign with the title company. If you're using Blueprint or Endpoint title, this may include reviewing your closing documents on their website.",
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_CLOSING_COMPLETED: {
    adminActionDescription: '[Waiting For] Customer to schedule closing.',
    customerActionDescription: 'Schedule closing with the title company.',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_GENERAL_WARRANTY_DEED_PREPARED: {
    adminActionDescription:
      'Confirm that the title company has prepared General Warranty Deed.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_BILL_OF_SALE_PREPARED: {
    adminActionDescription:
      'Confirm that the title company has prepared Bill of Sale.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_TITLE_AFFADAVIT_PREPARED: {
    adminActionDescription:
      'Confirm that the title company has prepared Title Affadavit.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_OWNER_TITLE_INSURANCE_PREPARED: {
    adminActionDescription:
      'Confirm that the title company has prepared Owner Title Insurance.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_LEASE_SENT: {
    adminActionDescription: 'Email lease to the title company.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_BASIC_INVESTOR_INFO_SUBMITTED_ORDERPROCESS: {
    adminActionDescription:
      '[Waiting For] Customer to start LP onboarding by providing some basic info.',
    customerActionDescription:
      'Get started with LP Onboarding by sharing your contact information.',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_INVESTOR_QUALIFICATION_FORM_COMPLETED_ORDERPROCESS: {
    adminActionDescription:
      '[Waiting For] Customer to complete investor qualification statement',
    customerActionDescription:
      'Your next step of LP Onboarding is to fill out the Investor Qualification form.',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_IDENTITY_AND_ACCREDITATION_VERIFICATION_REQUESTED_ORDERPROCESS:
    {
      adminActionDescription:
        '[Waiting For] Customer to complete Identity and Accreditation Verification',
      customerActionDescription:
        'Your next step of LP Onboarding is to go through Identity and Accreditation Verification.',
      customerWaitingForDescription: '',
    },
  ORDER_V2_TASK_KEY_IDENTITY_AND_ACCREDITATION_VERIFICATION_COMPLETED_ORDERPROCESS:
    {
      adminActionDescription:
        '[Waiting For] Parallel Markets to complete Identity and Accreditation Verification',
      customerActionDescription: '',
      customerWaitingForDescription:
        "We're completing your Identity and Accreditation Verification. For the next step, please review and sign the Subscription Booklet via the link in Pending Tasks below.",
    },
  ORDER_V2_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SIGNATURE_REQUESTED_ORDERPROCESS: {
    adminActionDescription:
      '[System] Automatically fill Subscription Booklet and email customer with link to sign via DocuSign.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SIGNED_ORDERPROCESS: {
    adminActionDescription:
      '[Waiting For] Customer to sign the Subscription Booklet in DocuSign.',
    customerActionDescription:
      'For the last piece of LP Onboarding, please review and sign the Subscription Booklet via DocuSign.',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_SUBSCRIPTION_DOCUMENTS_SENT_ORDERPROCESS: {
    adminActionDescription:
      '[System] Send the completed Subscription Booklet and Parallel Markets Identity and Accreditation documents to fund administrator for review.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_SUBSCRIPTION_DOCUMENTS_COMPLETED_ORDERPROCESS: {
    adminActionDescription:
      "Confirm customer has completed the Subscription Documents with no outstanding issues from fund administrator's review.",
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_CLOSING_INFORMATION_COMPLETED_ORDERPROCESS: {
    adminActionDescription: 'Verify closing information completed',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_PERSONAL_INFORMATION_COMPLETED_ORDERPROCESS: {
    adminActionDescription: 'Verify personal information completed',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  ORDER_V2_TASK_KEY_ONBOARDING_EMAIL_SENT_ORDERPROCESS: {
    adminActionDescription: 'Verify onboarding email completed',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
  // If an order is old and does not have the most up to date tasks, any of the newer tasks will not be in the db, and the backend will return this task instead.
  ORDER_V2_TASK_KEY_UNSPECIFIED: {
    adminActionDescription: 'Order does not have up to date schema.',
    customerActionDescription: '',
    customerWaitingForDescription: '',
  },
}

type FilterOrderV2TasksParams = {
  order: Core_OrderV2
  taskUuid?: string[]
  key?: Core_OrderV2TaskKey[]
  sourceType?: Core_OrderV2TaskSource[]
  sourceUuid?: string[]
  status?: Core_OrderV2TaskStatus[]
}
export const filterOrderV2Tasks = (
  params: FilterOrderV2TasksParams
): Core_OrderV2Task[] => {
  const propertyContributionTasks: Core_OrderV2Task[] = flattenDeep(
    params.order.propertyContributions.map((x) =>
      flatMapDeep(x, (t: any) =>
        typeof t === (typeof {} as unknown as Core_OrderV2Task as unknown)
          ? t
          : undefined
      )
    )
  ).filter((x: any) => x !== undefined)

  const perOrderTasks: Core_OrderV2Task[] = flattenDeep(
    flatMapDeep(params.order.orderProcesses, (t: any) =>
      typeof t === (typeof {} as unknown as Core_OrderV2Task as unknown)
        ? t
        : undefined
    )
  ).filter((x: any) => x !== undefined)

  const filteredTasks = [...perOrderTasks, ...propertyContributionTasks].filter(
    (task: Core_OrderV2Task) => {
      if (params.taskUuid && !params.taskUuid.includes(task.taskUuid)) {
        return false
      }
      if (params.key && !params.key.includes(task.key)) {
        return false
      }
      if (params.sourceType && !params.sourceType.includes(task.sourceType)) {
        return false
      }
      if (params.sourceUuid && !params.sourceUuid.includes(task.sourceUuid)) {
        return false
      }
      if (params.status && !params.status.includes(task.status)) {
        return false
      }

      return true
    }
  )

  return filteredTasks
}
