import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminConnectInvestorAccountToLegalEntitiesDocument,
  AdminCreateInvestorAccountWithLegalEntitiesDocument,
  AdminCreateOrderV3Mutation,
  AdminDeleteOrderV3Document,
  AdminGetAllInvestorAccountsDocument,
  AdminUploadContributionAgreementToOrderDocument,
  Core_ConnectInvestorAccountToLegalEntitiesRequestInput,
  Core_CreateInvestorAccountWithLegalEntitiesRequestInput,
  Core_OrderV3,
  Core_OrderV3TaskKey,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { filterOrderV3Tasks } from '@flock/utils'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Modal,
  Paper,
  styled,
} from '@mui/material'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { ORDER_ONBOARDING_URL } from '../../../constants'
import LegalEntityToInvestorSection from './LegalEntityToInvestorSection'
import OrderVisualizerSection from './OrderVisualizerSection'
import UploadContributionAgreementSection from './UploadContributionAgreementSection'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  maxHeight: '85%',
  overflowY: 'scroll',
  padding: '2rem',
})

type OrderConfirmationModalParams = {
  isOpen: boolean
  close: () => void
  orderResponse: AdminCreateOrderV3Mutation
}

const OrderConfirmationModal = (props: OrderConfirmationModalParams) => {
  const { notify } = useSnackbar()
  const { isOpen, close, orderResponse } = props
  const { order } = orderResponse.createOrderV3!

  const [
    newAndExistingInvestorAccountsToLegalEntityUuids,
    setNewAndExistingInvestorAccountsToLegalEntityUuids,
  ] = useState<{
    newInvestorAccountsPayload: Core_CreateInvestorAccountWithLegalEntitiesRequestInput[]
    existingInvestorAccountsPayload: Core_ConnectInvestorAccountToLegalEntitiesRequestInput[]
  }>(
    {} as {
      newInvestorAccountsPayload: Core_CreateInvestorAccountWithLegalEntitiesRequestInput[]
      existingInvestorAccountsPayload: Core_ConnectInvestorAccountToLegalEntitiesRequestInput[]
    }
  )

  const [onSubmitLoading, setOnSubmitLoading] = useState<Boolean>(false)

  const [onDeleteLoading, setOnDeleteLoading] = useState<Boolean>(false)

  const [contributionAgreementFile, setContributionAgreementFile] =
    useState<File>({} as File)

  const [deleteOrderV3] = useMutation(AdminDeleteOrderV3Document)
  const [createInvestorAccountWithLegalEntities] = useMutation(
    AdminCreateInvestorAccountWithLegalEntitiesDocument
  )
  const [connectInvestorAccountToLegalEntities] = useMutation(
    AdminConnectInvestorAccountToLegalEntitiesDocument
  )
  const [adminUploadContributionAgreementToOrder] = useMutation(
    AdminUploadContributionAgreementToOrderDocument
  )

  const filteredOrderResponse = filterOrderV3Tasks(
    orderResponse as Core_OrderV3,
    {
      keys: [
        Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementPreparedAuthorizedSigner,
        Core_OrderV3TaskKey.OrderV3TaskKeyContributionAgreementSignedAuthorizedSigner,
      ],
    }
  )

  const renderContributionAgreementUpload = filteredOrderResponse.length === 0

  const onDelete = async () => {
    try {
      setOnDeleteLoading(true)
      await deleteOrderV3({
        variables: {
          deleteOrderV3Input: {
            uuid: order.uuid,
          },
        },
      })
      notify('Order deleted.', 'success')
      setOnDeleteLoading(false)
      close()
    } catch (e) {
      setOnDeleteLoading(false)
      notify(`Failed to delete order: ${e}.`, 'error')
    }
  }

  // the main onsubmit function for all sections in this modal
  const onSubmit = async () => {
    setOnSubmitLoading(true)
    try {
      const allMutations: Promise<any>[] = []

      if (
        newAndExistingInvestorAccountsToLegalEntityUuids.existingInvestorAccountsPayload.some(
          (accountMapping) => accountMapping.investorAccountUuid === 'undefined'
        )
      ) {
        notify(
          'Each legal entity must have a valid investor account uuid.',
          'error'
        )
        setOnSubmitLoading(false)

        return
      }

      if (renderContributionAgreementUpload) {
        if (!contributionAgreementFile) {
          notify(
            'Contribution agreement file must be uploaded before submitting.',
            'error'
          )
          setOnSubmitLoading(false)

          return
        }

        await adminUploadContributionAgreementToOrder({
          variables: {
            createContributionAgreementSignedDocumentInput: {
              orderUuid: order.uuid,
              file: contributionAgreementFile,
              fileName: contributionAgreementFile.name,
            },
          },
          refetchQueries: [AdminGetAllInvestorAccountsDocument],
        })
      }

      newAndExistingInvestorAccountsToLegalEntityUuids.newInvestorAccountsPayload.forEach(
        (accountMapping) =>
          allMutations.push(
            createInvestorAccountWithLegalEntities({
              variables: {
                createInvestorAccountWithLegalEntitiesInput: {
                  ...accountMapping,
                },
              },
              refetchQueries: [AdminGetAllInvestorAccountsDocument],
            })
          )
      )

      newAndExistingInvestorAccountsToLegalEntityUuids.existingInvestorAccountsPayload.forEach(
        (accountMapping) =>
          allMutations.push(
            connectInvestorAccountToLegalEntities({
              variables: {
                connectInvestorAccountToLegalEntitiesInput: {
                  ...accountMapping,
                },
              },
              refetchQueries: [AdminGetAllInvestorAccountsDocument],
            })
          )
      )

      await Promise.all(allMutations)
      setOnSubmitLoading(false)
      notify(
        'Successfully created order and notified new investors.',
        'success'
      )
      close()
      navigate(`${ORDER_ONBOARDING_URL}/${order.uuid}`)
    } catch (e) {
      setOnSubmitLoading(false)
      notify(`An error occurred ${e}.`, 'error')
    }
  }

  return (
    <Modal open={isOpen}>
      <ModalWrapper>
        <h1>Order Confirmation {order.uuid}</h1>
        <Box display="flex" flexDirection="column" width="100%" gap="16px">
          <Grid container>
            <OrderVisualizerSection orderMutationResult={orderResponse} />
            <LegalEntityToInvestorSection
              orderLegalEntities={order.orderLegalEntities}
              setNewAndExistingInvestorAccountsToLegalEntityUuids={
                setNewAndExistingInvestorAccountsToLegalEntityUuids
              }
            />
            {renderContributionAgreementUpload && (
              <UploadContributionAgreementSection
                setCAFile={setContributionAgreementFile}
              />
            )}
          </Grid>
          <Box display="flex" justifyContent="flex-end" gap="16px">
            <Box>
              <Button variant="secondary" color="error" onClick={onDelete}>
                {onDeleteLoading ? <CircularProgress size="24px" /> : 'Delete'}
              </Button>
            </Box>
            <Box>
              <Button variant="secondary" onClick={onSubmit}>
                {onSubmitLoading ? <CircularProgress size="24px" /> : 'Confirm'}
              </Button>
            </Box>
          </Box>
        </Box>
      </ModalWrapper>
    </Modal>
  )
}

export default OrderConfirmationModal
