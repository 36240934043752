import { WindowLocation } from '@reach/router'
import { navigate } from 'gatsby'
import _ from 'lodash'
import {
  FUND_MANAGEMENT_URL,
  INVESTOR_MANAGEMENT_URL,
  ORDER_ONBOARDING_URL,
  SALES_BASE_URL,
  SALES_LEAD_MANAGEMENT_URL,
} from './constants'

export const onLogoClick = () => {
  navigate(SALES_LEAD_MANAGEMENT_URL)
}

const isSelected = (highlightUrl: string, location?: WindowLocation) =>
  (location?.href && location?.href?.includes(highlightUrl)) || false

export const getHeaderConfigs = (
  logout: () => void,
  location?: WindowLocation
) => [
  {
    text: 'Sales',
    onClick: () => {
      navigate(SALES_LEAD_MANAGEMENT_URL)
    },
    selected: isSelected(SALES_BASE_URL, location),
  },
  {
    text: 'Exchange',
    onClick: () => {
      navigate(ORDER_ONBOARDING_URL)
    },
    selected: isSelected(ORDER_ONBOARDING_URL, location),
  },
  {
    text: 'Fund Management',
    onClick: () => {
      navigate(INVESTOR_MANAGEMENT_URL)
    },
    selected: isSelected(FUND_MANAGEMENT_URL, location),
  },
  {
    text: 'Log Out',
    onClick: () => {
      logout()
    },
    selected: false,
  },
]

export const snakeToTitleCase = (text: string) => {
  if (!text) {
    return ''
  }
  const lowercaseText = text.toLowerCase().replace(/_/g, ' ')
  return lowercaseText.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export const prettyPrintJsonKeys = (key: string) => _.startCase(key)
