import React from 'react'
import { Typography, Modal, Paper, styled } from '@mui/material'
import { Form, InputConfig } from '@flock/flock-component-library'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '5rem',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

type ActionFormModalProps = {
  actionText: string
  formProps?: any
  inputConfigs: InputConfig[]
  extraContent?: any
  onSubmit: (result: any) => void
  onUpdated?: (formData: any) => void
  loading: boolean
  open: boolean
  setOpen: (value: boolean) => void
}

const ActionFormModal = (props: ActionFormModalProps) => {
  const {
    actionText,
    formProps,
    inputConfigs,
    onSubmit,
    extraContent,
    onUpdated,
    loading,
    open,
    setOpen,
  } = props
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          {actionText}
        </Typography>
        {extraContent}
        <Form
          onSubmit={onSubmit}
          formProps={formProps}
          onUpdated={onUpdated}
          ctaText={loading ? 'Submitting...' : actionText}
          ctaProps={{
            disabled: loading,
            'data-cy': 'submitButton',
          }}
          inputConfigs={inputConfigs}
        />
      </ModalWrapper>
    </Modal>
  )
}

ActionFormModal.defaultProps = {
  extraContent: undefined,
  formProps: undefined,
  onUpdated: undefined,
}

export default ActionFormModal
