import { useQuery } from '@apollo/client'
import {
  AdminStaticDocumentsGetStaticDocumentsDocument,
  Core_GetStaticDocumentsResponse,
  Core_StaticDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useState } from 'react'
import { LeadDocumentType } from '../shared/sharedTypes'

type StaticDocumentConfig = {
  title: string
  documents: Core_StaticDocument[]
}

type StaticDocumentConfigs = {
  [key in LeadDocumentType]: StaticDocumentConfig
}

const displayOrder = [
  LeadDocumentType.EDUCATIONAL,
  LeadDocumentType.PERFORMANCE,
  LeadDocumentType.LEGAL,
]

export const useStaticDocuments = () => {
  const [staticDocumentConfigs, setStaticDocumentConfigs] = useState<
    StaticDocumentConfig[]
  >([])

  const { loading } = useQuery(AdminStaticDocumentsGetStaticDocumentsDocument, {
    onCompleted: (result) => {
      const response =
        result.getStaticDocuments as Core_GetStaticDocumentsResponse
      const staticDocuments = response.staticDocuments as Core_StaticDocument[]

      const newDocumentConfigMap: StaticDocumentConfigs = {
        educational: {
          title: 'Educational',
          documents: [],
        },
        performance: {
          title: 'Performance',
          documents: [],
        },
        legal: {
          title: 'Legal',
          documents: [],
        },
      } as any

      staticDocuments.forEach((document) => {
        Object.values(LeadDocumentType).forEach((documentType) => {
          if (document.documentType === documentType) {
            newDocumentConfigMap[
              documentType as LeadDocumentType
            ].documents.push(document)
          }
        })
      })

      // Enforce an order
      const orderedDocumentConfigs = displayOrder.map(
        (documentType) => newDocumentConfigMap[documentType]
      )
      setStaticDocumentConfigs(orderedDocumentConfigs)
    },
  })

  return { staticDocumentConfigs, loading }
}

export default useStaticDocuments
