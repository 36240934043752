import React from 'react'
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables'
import { ThemeProvider } from '@mui/material/styles'
import flockTheme from '../flockTheme/flockTheme'
import useTracking, { TrackingConfig } from '../useTracking/useTracking'

type DataTableProps = MUIDataTableProps & {
  trackingConfig?: TrackingConfig
  options: any
}

const DataTable = (props: DataTableProps) => {
  const { trackingConfig, options } = props

  const { track } = useTracking()

  const onChangePageTracked = (currentPage: number) => {
    const trackingName =
      `${trackingConfig?.name}-changed-page` || 'data-table-changed-page'
    track(trackingName, trackingConfig)
    if (options?.onChangePage) {
      options.onChangePage(currentPage)
    }
  }

  return (
    <ThemeProvider theme={flockTheme}>
      {/** @ts-ignore */}
      <MUIDataTable
        {...props}
        options={{ ...options, onChangePage: onChangePageTracked }}
      />
    </ThemeProvider>
  )
}

export default DataTable

DataTable.defaultProps = {
  trackingConfig: {
    name: 'data-table',
  },
}
