import {
  FileUpload,
  FileUploadFileType,
  FileUploadMimeType,
} from '@flock/shared-ui'
import { Grid, Typography } from '@mui/material'
import React, { useState } from 'react'

type UploadContributionAgreementSectionProps = {
  setCAFile: (uploadFile: File) => void
}

const UploadContributionAgreementSection = (
  props: UploadContributionAgreementSectionProps
) => {
  // const { notify } = useSnackbar()
  const { setCAFile } = props
  const [error, setError] = useState<boolean>(false)

  const onChange = (files: Array<File>) => {
    if (files.length > 1) {
      setError(true)
    }
    if (files.length === 1) {
      setError(false)
    }
    setCAFile(files[0])
  }

  return (
    <Grid item xs={12} pt="30px">
      <FileUpload
        label={
          <>
            <Typography fontWeight={600}>
              Upload CA Here (only PDFs are accepted)
            </Typography>
            <Typography>
              File name should be in format of (Order
              Name)_Contribution_Agreement.pdf
            </Typography>
          </>
        }
        helperText={error ? 'Only one file can be uploaded' : ''}
        defaultFiles={[]}
        onChange={onChange}
        onBlur={() => {}}
        accept={{
          [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
        }}
        error={error}
      />
    </Grid>
  )
}

export default UploadContributionAgreementSection
