import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import { AdminCreateOperatorDocument } from '@flock/flock-gql-server/src/__generated__/graphql'
import { InputType } from '@flock/shared-ui'
import { Button } from '@mui/material'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'

type CreateOperatorParams = {
  fullName: string
  email: string
  slackID: string
}

const CreateOperatorModal = () => {
  const { notify } = useSnackbar()

  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [createNewOperator] = useMutation(AdminCreateOperatorDocument) // change this

  const onSubmit = async (inputParams: CreateOperatorParams) => {
    const { fullName, email, slackID } = inputParams
    setLoading(true)
    try {
      const data = await createNewOperator({
        variables: {
          input: {
            fullName,
            email,
            slackId: slackID,
          },
        },
      })
      window.open(
        data.data?.createOperator?.passwordResetUrl as string,
        '_blank'
      )
      notify('Successfully created new operator.', 'success')
      setOpen(false)
    } catch (e) {
      notify('Failed to create new operator.', 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <Button size="small" variant="secondary" onClick={() => setOpen(true)}>
        Create New Operator
      </Button>
      <GridFormModal
        open={open}
        loading={loading}
        title="Create New Operator"
        onClose={() => setOpen(false)}
        onSubmit={onSubmit}
        gridProps={{
          spacing: 2,
        }}
        inputConfigs={[
          {
            name: 'fullName',
            type: InputType.Text,
            required: true,
            props: {
              label: 'Full Name',
              format: 'fullName',
            },
          },
          {
            name: 'email',
            type: InputType.Text,
            required: true,
            props: {
              label: 'Email',
              format: 'email',
            },
          },
          {
            name: 'slackID',
            type: InputType.Text,
            required: true,
            props: {
              label: 'Slack ID',
            },
          },
        ]}
      />
    </>
  )
}

export default CreateOperatorModal
