import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminGetLeadDocument,
  AdminLeadManagementUpdateLeadDocumentDocument,
  Core_LeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  AnyInputConfig,
  InputType,
  FileUploadFileType,
  FileUploadMimeType,
} from '@flock/shared-ui'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'
import { LeadDocumentType } from '../shared/sharedTypes'

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'name',
    type: InputType.Text,
    props: {
      label: 'Display Name',
    },
  },
  {
    name: 'documentType',
    type: InputType.Dropdown,
    props: {
      label: 'Document Type',
      options: [
        {
          label: 'Additional Offer Details',
          value: LeadDocumentType.ADDITIONAL_OFFER_DETAILS,
        },
        {
          label: 'Other',
          value: LeadDocumentType.OTHER,
        },
      ],
    },
  },
  {
    name: 'uploadedFile',
    type: InputType.FileUpload,
    props: {
      label: 'Upload an updated version of this file. (Only PDFs are accepted)',
      accept: {
        [FileUploadMimeType.PDF]: [FileUploadFileType.PDF],
      },
    },
  },
]

type EditLeadDocumentParams = {
  name: string
  documentType: string
  uploadedFile: File[]
}

type EditLeadDocumentModalProps = {
  leadUuid: string
  selectedDocument: Core_LeadDocument
  open: boolean
  onClose: () => void
}

const EditLeadDocumentModal = (props: EditLeadDocumentModalProps) => {
  const { open, onClose, selectedDocument, leadUuid } = props
  const { notify } = useSnackbar()
  const [loading, setLoading] = useState(false)

  const [EditLeadDocument] = useMutation(
    AdminLeadManagementUpdateLeadDocumentDocument
  )

  const onSubmit = async (result: EditLeadDocumentParams) => {
    setLoading(true)
    const { name, documentType, uploadedFile } = result
    const editLeadDocumentInput: any = {
      documentUuid: selectedDocument.uuid,
      leadUuid,
      name,
      documentType,
    }
    if (uploadedFile) {
      const file = uploadedFile[0]
      editLeadDocumentInput.file = file
    }

    try {
      await EditLeadDocument({
        variables: {
          input: editLeadDocumentInput,
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully updated lead document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to update lead document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Update Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      formProps={{
        defaultValues: {
          ...selectedDocument,
        },
      }}
      gridProps={{
        spacing: 3,
      }}
      loading={loading}
    />
  )
}

export default EditLeadDocumentModal
