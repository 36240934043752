import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import {
  AdminGetInvestmentInfoForLegalEntityDocument,
  AdminGetInvestmentTransactionInfoForLegalEntityDocument,
  AdminUpdateInvestmentTransactionDocument,
  Core_InvestmentTransaction,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'

import { formattedDollarsToCents } from '@flock/utils'
import ActionFormModal from '../shared/ActionFormModal'

type UpdateInvestmentTransactionParams = {
  newEquityValueChange: string
  newShareCountChange: string
  newNotes: string
}

type UpdateInvestmentTransactionModalParams = {
  existingInvestmentTransaction: Core_InvestmentTransaction | undefined
  isOpen: boolean
  close: () => void
}

const UpdateInvestmentTransactionsModal = (
  props: UpdateInvestmentTransactionModalParams
) => {
  const { existingInvestmentTransaction, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [updateInvestmentTransaction, { loading }] = useMutation(
    AdminUpdateInvestmentTransactionDocument
  )

  const onSubmit = async (
    updateInvestmentTransactionParams: UpdateInvestmentTransactionParams
  ) => {
    const { newEquityValueChange, newShareCountChange, newNotes } =
      updateInvestmentTransactionParams
    const updateInvestmentTransactionInput = {
      investmentTransactionUuid: existingInvestmentTransaction?.uuid as string,
      equityValueChange: formattedDollarsToCents(
        newEquityValueChange.toString()
      ),
      shareCountChange: formattedDollarsToCents(newShareCountChange.toString()),
      notes: newNotes,
    }
    try {
      await updateInvestmentTransaction({
        variables: {
          updateInvestmentTransactionInput,
        },
        refetchQueries: [
          AdminGetInvestmentTransactionInfoForLegalEntityDocument,
          AdminGetInvestmentInfoForLegalEntityDocument,
        ],
      })
      notify('Successfully updated investment transaction', 'success')
      close()
    } catch (e) {
      notify('Failed to update investment transaction', 'error')
    }
  }

  const defaultValues = {
    newEquityValueChange:
      (existingInvestmentTransaction?.equityValueChangeCents || 0) / 100,
    newShareCountChange:
      (existingInvestmentTransaction?.shareCountChange || 0) / 100,
    newNotes: existingInvestmentTransaction?.notes || '',
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Update Investment Transaction"
      formProps={{
        defaultValues,
      }}
      inputConfigs={[
        {
          inputName: 'newEquityValueChange',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Equity Value Change',
            fullWidth: true,
          },
        },
        {
          inputName: 'newShareCountChange',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Share Count Change',
            fullWidth: true,
          },
        },
        {
          inputName: 'newNotes',
          inputType: InputType.Text,
          required: false,
          props: {
            type: 'text',
            label: 'Notes',
            fullWidth: true,
          },
        },
      ]}
    />
  )
}

export default UpdateInvestmentTransactionsModal
