import React from 'react'
import { useSnackbar, InputType } from '@flock/flock-component-library'
import { useMutation } from '@apollo/client'

import { navigate } from 'gatsby'
import {
  AdminCreateLegalEntityForExistingInvestorDocument,
  AdminGetLegalEntityDocument,
  AdminSearchLegalEntitiesDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { INVESTOR_MANAGEMENT_URL } from '../../constants'
import ActionFormModal from '../shared/ActionFormModal'

type CreateInvestorLegalEntityParams = {
  investorAccountUuid: string
  name: string
  type: string
  email: string
  phoneNumber: string
  dateJoined: string
}

type CreateInvestorLegalEntityModalParams = {
  isOpen: boolean
  close: () => void
}

const CreateInvestorLegalEntityModal = (
  props: CreateInvestorLegalEntityModalParams
) => {
  const { isOpen, close } = props
  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const [createLegalEntityForExistingInvestor, { loading }] = useMutation(
    AdminCreateLegalEntityForExistingInvestorDocument
  )
  const onSubmit = async (
    createInvestorLegalEntityParams: CreateInvestorLegalEntityParams
  ) => {
    const { investorAccountUuid, name, type, email, phoneNumber, dateJoined } =
      createInvestorLegalEntityParams
    const createInvestorLegalEntityInput = {
      investorAccountUuid,
      name,
      type,
      email,
      phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
      dateJoined: dateJoined || '',
    }

    try {
      const investorAccountData = await createLegalEntityForExistingInvestor({
        variables: {
          createInvestorLegalEntityInput,
        },
        refetchQueries: [
          AdminGetLegalEntityDocument,
          AdminSearchLegalEntitiesDocument,
        ],
      })
      notify('Successfully created new legal entity for investor.', 'success')
      handleClose()
      navigate(
        `${INVESTOR_MANAGEMENT_URL}/${investorAccountData?.data?.createLegalEntityForExistingInvestor?.investorLegalEntityRelationship?.legalEntityUuid}`
      )
    } catch (e) {
      notify('Failed to create new legal entity for investor.', 'error')
    }
  }

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Create Legal Entity for Investor"
      inputConfigs={[
        {
          inputName: 'investorAccountUuid',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'Investor Account UUID',
            type: 'text',
            fullWidth: true,
            'data-cy': 'investorAccountUuid',
          },
        },
        {
          inputName: 'name',
          inputType: InputType.Text,
          required: true,
          props: {
            label: 'Legal Entity Name',
            type: 'text',
            fullWidth: true,
            'data-cy': 'legalEntityName',
          },
        },
        {
          inputName: 'type',
          inputType: InputType.Dropdown,
          required: true,
          props: {
            label: 'Type',
            fullWidth: true,
            options: [
              {
                text: 'Individual',
                value: 'individual',
              },
              {
                text: 'LLC',
                value: 'llc',
              },
              {
                text: 'Corporation',
                value: 'corporation',
              },
              {
                text: 'Trust',
                value: 'trust',
              },
            ],
          },
        },
        {
          inputName: 'dateJoined',
          inputType: InputType.DatePicker,
          props: {
            label: 'Date Joined',
            defaultValue: new Date(),
            fieldProps: {
              fullWidth: true,
            },
          },
        },
        {
          inputName: 'email',
          inputType: InputType.Text,
          required: true,
          props: {
            fullWidth: true,
            type: 'email',
            label: 'Email',
            'data-cy': 'legalEntityEmail',
          },
        },
        {
          inputName: 'phoneNumber',
          inputType: InputType.Phone,
          required: true,
          props: {
            fullWidth: true,
            label: 'Phone Number',
            'data-cy': 'legalEntityPhone',
          },
        },
      ]}
    />
  )
}

export default CreateInvestorLegalEntityModal
