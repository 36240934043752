import React, { useState } from 'react'
import { gql, useQuery } from '@apollo/client'
import { LoadingCard, useSnackbar } from '@flock/flock-component-library'
import { TrackedLink, PopoverMenu } from '@flock/shared-ui'

import { RouteComponentProps, useParams } from '@reach/router'
import { startCase } from 'lodash'

import { Divider, Grid, Typography, styled, Box } from '@mui/material'
import { formatAddressString, formatCityStateZip } from '@flock/utils'
import {
  Core_ValuationType,
  Core_ValuationV1,
  AdminGetLeadDocument,
  AdminGetValuationDocument,
  AdminHomeOpsGetValuationV1Document,
  AdminGetOperatorDocument,
  Core_Valuation,
  AdminGetBrokerDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import ValuationModal from './ValuationModal'
import { BROKER_MANAGEMENT_URL, FLOCK_LANDING_URL } from '../../constants'
import RequestContributionAgreementModal from '../TaskManagement/RequestContributionAgreementModal'

import DataMapTable from './DataMapTable'
import DataMapSection from './DataMapSection'
import { prettyPrintJsonKeys } from '../../utils'
import EditLeadAnswersModal from './EditLeadAnswersModal'
import EditLeadOverridesModal from './EditLeadOverridesModal'
import LeadDocumentsSection from '../LeadManagementComponents/LeadDocumentsSection'

// TODO: refactor these into shared list of answers that is shared with FlowData struct in flock-landing-fe
const propertyDetailAnswers = [
  'propertyType',
  'bedCount',
  'bathCount',
  'hasUnit',
  'unitNumber',
  'squareFootage',
  'additionalInfo',
  'propertyCondition',
  'hasMortgage',
  'mortgageAmount',
  'prefillSquareFootage',
  'prefillBedCount',
  'prefillBathCount',
  'prefillHalfBathCount',
  'prefillBasement',
  'prefillYearBuilt',
  'prefillBuildingQualityScore',
  'prefillBuildingConditionScore',
]

const offerPageOverrides = [
  'waiveOnboardingFee',
  'feeOverride',
  'leadNameOverride',
  'addressDisplayOverride',
  'cityStateZipDisplayOverride',
  'capexCosts',
  'offerDisclaimer',
  'cashTakeout',
  'rentReduction',
  'daysInRemodelDeduction',
  'closingCosts',
  'brokerCommission',
  'portfolioName',
  'portfolioAssetCount',
  'portfolioDoorCount',
  'portfolioCityState',
  'portfolioSFRDoors',
  'portfolioMultifamilyDoors',
]

const multifamilyPropertyDetailAnswers = [
  'additionalInfo',
  'propertyType',
  'prefillSquareFootage',
  'prefillBedCount',
  'prefillBathCount',
  'prefillHalfBathCount',
  'prefillBasement',
  'prefillYearBuilt',
  'prefillBuildingQualityScore',
  'prefillBuildingConditionScore',
  'hasPool',
  'hasSolarPanels',
  'structuralIssues',
  'upgradedRoof',
  'upgradedHVAC',
  'upgradedWaterHeater',
  'upgradedAppliances',
  'defectStructural',
  'defectElectrical',
  'defectFloodZone',
  'defectFireDamage',
  'defectSepticWellWater',
  'defectUnpermittedAdditions',
]

const miscellaneousAnswers = [
  'attribution',
  'attributionDetail',
  'utmSource',
  'utmCampaign',
  'utmMedium',
]

const Heading = styled('div')({
  marginTop: '4rem',
  marginBottom: '1rem',
})

export const GET_VALUATION_V1 = gql`
  fragment ValuationTrailFields on Core_ValuationTrailNode {
    name
    description
    value
  }
  query AdminHomeOpsGetValuationV1($input: Core_GetValuationV1RequestInput!) {
    getValuationV1(input: $input) {
      valuation {
        uuid
        type
        finalOfferPrice
        expiresAt
        inputs {
          offerPrice {
            housecanaryCondition
            comps {
              source
              similarityScore
              url
              avmPrice
              squareFootage
              estimatedRemodelCost
              address
              beds
              baths
              halfBaths
              yearBuild
              soldDate
              notes
            }
            housecanaryAdjustedAvmPrice
            notes
            selfReportedValue
            useCma
          }
          remodelCost {
            score
            cost
            daysInRemodel
            daysInRemodelDeduction
            sowCost
            notes
          }
          marketRent {
            housecanaryProjectedRent
            comps {
              source
              marketRent
              rentalDate
              squareFootage
              url
              address
              notes
            }
            analystProjectedRent
            notes
            currentlyOccupied
            currentRent
            monthsRemainingOnLease
          }
          otherCosts {
            acquisition
            miscellaneous
            hoa
            propertyTaxes
            notes
            addressNearbyHomeHoa
            urlNearbyHomeHoa
            hoaExists
          }
        }
        outputs {
          impliedYieldMinimums
          msaLevelMinimums
          finalOfferPrice
          netYieldAdjustedOfferPrice
          grossYieldOnAdjustedOfferPrice
          grossYield
          capRate
          netYield
          noiMarginAverage
          pass
        }
        property {
          sqft
          yearBuilt
        }
        leadUuid
        lead {
          answers
          fullName
          address {
            street
            unit
            city
            state
            zipcode
          }
        }
        rejectionReason
        operatorUuid
        valuationTrail {
          ...ValuationTrailFields
          nodes {
            ...ValuationTrailFields
            nodes {
              ...ValuationTrailFields
              nodes {
                ...ValuationTrailFields
                nodes {
                  ...ValuationTrailFields
                  nodes {
                    ...ValuationTrailFields
                    nodes {
                      ...ValuationTrailFields
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const IndividualLeadPage = (_: RouteComponentProps) => {
  const { leadUuid } = useParams()
  const { notify } = useSnackbar()

  const [answersModalOpen, setAnswersModalOpen] = useState(false)
  const [overridesModalOpen, setOverridesModalOpen] = useState(false)
  const [
    showValuationRequiresSalesApproval,
    setShowValuationRequiresSalesApproval,
  ] = useState(false)

  const { loading: leadLoading, data: leadData } = useQuery(
    AdminGetLeadDocument,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
      onError: () => {
        notify('Failed to get lead data', 'error')
      },
    }
  )

  const { data: internalValuation } = useQuery(
    AdminHomeOpsGetValuationV1Document,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
      onCompleted: () => {
        const internalValuationV1Details: Core_ValuationV1 = internalValuation
          ?.getValuationV1?.valuation as Core_ValuationV1
        if (
          internalValuationV1Details?.type ===
            Core_ValuationType.ValuationTypeFinal &&
          internalValuationV1Details.finalOfferPrice &&
          !internalValuationV1Details.salesApproved
        ) {
          setShowValuationRequiresSalesApproval(true)
        }
      },
    }
  )

  const { loading: valuationLoading, data: valuationData } = useQuery(
    AdminGetValuationDocument,
    {
      variables: {
        input: {
          leadUuid,
        },
      },
    }
  )
  const valuation = valuationData?.valuation?.valuation as Core_Valuation
  const { loading: operatorLoading, data: operatorData } = useQuery(
    AdminGetOperatorDocument,
    {
      variables: {
        input: { operatorUuid: leadData?.lead?.lead?.customer?.operatorUuid },
      },
      onError: () => {
        notify('Failed to get operator', 'error')
      },
      skip: leadLoading || !leadData?.lead?.lead?.customer?.operatorUuid,
    }
  )

  const { loading: brokerLoading, data: brokerData } = useQuery(
    AdminGetBrokerDocument,
    {
      skip: leadLoading || !leadData?.lead?.lead?.brokerUuid,
      variables: {
        input: { brokerUuid: leadData?.lead?.lead?.brokerUuid },
      },
      onError: () => {
        notify('Failed to get broker', 'error')
      },
    }
  )

  let addressPhotos = leadData?.lead?.lead?.address?.photos || []
  let addressDocuments = leadData?.lead?.lead?.address?.documents || []
  const addressUnits = leadData?.lead?.lead?.address?.units || []
  if (addressUnits?.length > 0) {
    addressUnits?.forEach((unit: any) => {
      addressPhotos = [...addressPhotos, ...unit?.photos]
      addressDocuments = [...addressDocuments, ...unit?.documents]
    })
  }

  let lead: any
  let leadAnswersMap: { [x: string]: any } = {}
  let overridesMap: { [x: string]: any } = {}
  if (!leadLoading) {
    lead = leadData?.lead?.lead || {}

    if (lead?.answers) {
      leadAnswersMap = JSON.parse(lead.answers)
    }
    // because we cannot be certain about this value for old leads and even current leads due to sendgrid flakiness/current implementation
    // only show if we actually have the value. Mainly used for admin created leads anyways.
    if (
      leadAnswersMap.isInMqlAutomation !== undefined &&
      leadAnswersMap.isInMqlAutomation !== null
    ) {
      if (!miscellaneousAnswers.includes('isInMqlAutomation')) {
        miscellaneousAnswers.push('isInMqlAutomation')
      }
    }
    if (lead?.reitFit) {
      leadAnswersMap.reitFit = lead.reitFit
    }
    if (lead?.overrides) {
      overridesMap = JSON.parse(lead.overrides) || {}
    }
  }

  const isMultifamily = leadAnswersMap.unitDetails?.length > 0
  const propertyAnswers = isMultifamily
    ? multifamilyPropertyDetailAnswers
    : propertyDetailAnswers

  if (leadLoading || valuationLoading) {
    return <LoadingCard text="Loading details..." />
  }
  const actions = [
    <ValuationModal lead={lead} valuation={valuation as Core_Valuation} />,
    <RequestContributionAgreementModal lead={lead} />,
  ]

  const links = [
    {
      text: 'Open Offer Page',
      onClick: () => {
        if (lead.source === 'dst_lead') {
          window.open(
            `${FLOCK_LANDING_URL}/property-estimate/1031/${leadUuid}`,
            '_blank'
          )
        }
        window.open(
          `${FLOCK_LANDING_URL}/property-estimate/${leadUuid}`,
          '_blank'
        )
      },
    },
    {
      text: 'Copy Offer Page Link',
      onClick: () => {
        navigator.clipboard.writeText(
          `${FLOCK_LANDING_URL}/property-estimate/${leadUuid}`
        )
        notify('Offer page copied to Clipboard')
      },
    },
    {
      text: 'View Hotjar Activity',
      onClick: () => {
        const hotjarUrl = `https://insights.hotjar.com/sites/3669022/playbacks?sort_by=created&filters=%7B%22AND%22:%5B%7B%22DAYS_AGO%22:%7B%22created%22:7%7D%7D%2C%7B%22CONTAINS%22:%7B%22all_page_paths%22:%22${leadUuid}%22%7D%7D%5D%7D`
        window.open(hotjarUrl, '_blank')
      },
    },
  ]

  return (
    <>
      <Heading sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h2">Lead</Typography>
        <Box display="flex" gap="8px">
          <PopoverMenu
            text="Links"
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            buttonProps={{
              sx: {
                fontWeight: '500',
              },
            }}
            actions={links}
          />

          <PopoverMenu
            text="Actions"
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            buttonProps={{
              sx: {
                fontWeight: '500',
              },
            }}
            actions={actions}
          />
        </Box>
      </Heading>
      {leadLoading ? (
        <LoadingCard text="" />
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography variant="p1">{lead.fullName}</Typography>
          <Typography variant="p1">{lead.phoneNumber}</Typography>
          <Typography variant="p1">
            {lead.address ? formatAddressString(lead.address) : ''}
          </Typography>
          <Typography variant="p1">
            {formatCityStateZip(lead.address)}
          </Typography>
          <br />
          <Typography variant="p1">Source: {startCase(lead.source)}</Typography>
          <Typography variant="p1">
            Assignee:
            {operatorLoading
              ? ''
              : ` ${
                  operatorData?.operator?.operator?.fullName || 'Unassigned'
                }`}
          </Typography>
          <Typography variant="p1">
            Transaction Type: {startCase(lead.transactionType)}
          </Typography>
          <Typography variant="p1">
            {brokerLoading || !brokerData ? (
              ''
            ) : (
              <TrackedLink
                href={`${BROKER_MANAGEMENT_URL}/${
                  brokerData?.broker?.broker?.uuid as string
                }`}
                target="_blank"
              >
                <b>Agent / Broker:</b>{' '}
                {brokerData?.broker?.broker?.firstName as string}{' '}
                {brokerData?.broker?.broker?.lastName as string}
              </TrackedLink>
            )}
          </Typography>

          {showValuationRequiresSalesApproval && (
            <Typography variant="p1" color="error">
              Final Offer Price has not been pushed to the Final Offer Page.
              <br />
              Sales must approve the valuation in the valuation tool until it
              updates.
            </Typography>
          )}

          <DataMapSection
            title="Answers"
            actionText="Edit"
            onAction={() => setAnswersModalOpen(true)}
          >
            <DataMapTable fields={propertyAnswers} dataMap={leadAnswersMap} />
          </DataMapSection>

          <DataMapSection
            title="Overrides"
            actionText="Edit"
            onAction={() => setOverridesModalOpen(true)}
          >
            <DataMapTable fields={offerPageOverrides} dataMap={overridesMap} />
          </DataMapSection>
          <DataMapSection title="Miscellaneous Details">
            {miscellaneousAnswers.map((key) => (
              <Grid item xs={4}>
                <Typography variant="h4">{prettyPrintJsonKeys(key)}</Typography>
                <Typography variant="p1">
                  {leadAnswersMap[key] || leadAnswersMap[key] === false
                    ? leadAnswersMap[key].toString()
                    : '-'}
                </Typography>
              </Grid>
            ))}
          </DataMapSection>
          <Divider />
          <Grid item xs={12} sx={{ paddingBottom: '256px' }}>
            <LeadDocumentsSection
              lead={lead}
              leadUuid={leadUuid}
              overrides={(overridesMap as any) || {}}
            />
          </Grid>
        </Box>
      )}
      <EditLeadAnswersModal
        open={answersModalOpen}
        onClose={() => setAnswersModalOpen(false)}
        leadUuid={leadUuid}
        answers={leadAnswersMap}
      />
      <EditLeadOverridesModal
        open={overridesModalOpen}
        onClose={() => setOverridesModalOpen(false)}
        leadUuid={leadUuid}
        overrides={overridesMap}
      />
    </>
  )
}

export default IndividualLeadPage
