import React from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import flockColors from '../flockColors/flockColors'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'

const CheckboxWrapper = styled('div')({
  width: '100%',
})

const StyledLabel = styled(FormControlLabel)(
  ({ outlined }: { outlined: string }) => ({
    border: outlined === 'true' ? `2px solid ${flockColors.black}` : 'unset',
    borderRadius: '0.3rem',
    paddingRight: outlined === 'true' ? '1rem' : 0,
    paddingBottom: outlined === 'true' ? '0.25rem' : 0,
    paddingTop: outlined === 'true' ? '0.25rem' : 'unset',
    marginBottom: outlined === 'true' ? '1rem' : 0,
    width: '100%',

    '& > span': {
      fontFamily: 'Inter!important',
      fontSize: '1rem',
    },

    [MOBILE_BREAKPOINT]: {
      padding: 'unset',
      marginLeft: 'unset',
      width: 'calc(100% - 0.25rem)',

      '& > span': {
        fontSize: '1rem',
      },
    },
  })
)

const StyledTypography = styled(Typography)({
  fontSize: '1',
})

export type CheckboxProps = MuiCheckboxProps & {
  label: string
  error?: boolean
  outlined?: boolean
}

const Checkbox = (props: CheckboxProps) => {
  const { outlined, label, error, ...otherProps } = props
  return (
    <CheckboxWrapper>
      <StyledLabel
        control={<MuiCheckbox color="primary" {...otherProps} />}
        label={<StyledTypography variant="body1">{label}</StyledTypography>}
        outlined={outlined ? 'true' : 'false'}
      />
    </CheckboxWrapper>
  )
}

Checkbox.defaultProps = {
  outlined: false,
}

export default Checkbox
