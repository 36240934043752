import React from 'react'
import { Typography, Paper, Button } from '@flock/flock-component-library'
import {
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type DeleteModalParams = {
  displayHeaders: string[]
  displayCells: string[]
  isOpen: boolean
  close: () => void
  onClick: () => void
}

const DeleteModal = (props: DeleteModalParams) => {
  const { displayHeaders, displayCells, isOpen, close, onClick } = props
  return (
    <Modal open={isOpen} onClose={close}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              {displayHeaders.map((displayHeader) => (
                <TableCell>{displayHeader}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {displayCells.map((displayCell) => (
                <TableCell>{displayCell}</TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton onClick={close}>Cancel</CancelButton>
          <Button variant="secondary" color="error" onClick={onClick}>
            Delete
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteModal
