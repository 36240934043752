import React from 'react'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  RadioProps as MuiRadioProps,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import flockColors from '../flockColors/flockColors'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'

const StyledFormControlLabel = styled(FormControlLabel)(
  ({ outlined }: { outlined: string }) => ({
    border:
      outlined === 'true' ? `2px solid ${flockColors.darkerBlue}` : 'unset',
    borderRadius: '0.3rem',

    paddingRight: '4rem',
    paddingBottom: outlined === 'true' ? '0.25rem' : 0,
    paddingTop: outlined === 'true' ? '0.25rem' : 0,
    marginBottom: outlined === 'true' ? '1rem' : 0,

    '& > span': {
      fontFamily: 'Inter!important',
      fontSize: '1rem',
      color: `${flockColors.darkerBlue}!important`,
    },

    [MOBILE_BREAKPOINT]: {
      padding: 'unset',
      marginLeft: 'unset',
      width: 'calc(100% - 0.25rem)',

      '& > span': {
        fontSize: '1rem',
      },
    },
  })
)

const ErrorText = styled(Typography)({
  color: flockColors.red,
  fontSize: '0.75rem',
})

const FieldLabel = styled(Typography)({
  fontWeight: 'normal',
  paddingBottom: '0.25rem',
  color: flockColors.darkGray,
})

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'column',
  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

type RadioChoice = {
  text: string
  value: string | number
}

export type RadioSelectProps = {
  label: string
  options: RadioChoice[]
  defaultValue?: any
  noDefault?: boolean
  helperText?: string
  outlined?: boolean
  RadioProps?: MuiRadioProps
  RadioGroupProps?: MuiRadioGroupProps
}

const RadioSelect = (props: RadioSelectProps) => {
  const {
    label,
    options,
    outlined,
    defaultValue,
    noDefault,
    helperText,
    RadioProps,
    RadioGroupProps,
  } = props
  return (
    <>
      <FieldLabel variant="body2">{label}</FieldLabel>
      <StyledRadioGroup
        key={label}
        aria-label={label}
        name={label}
        defaultValue={noDefault ? '' : defaultValue || options[0]?.value}
        {...RadioGroupProps}
      >
        {options.map((choice) => (
          <StyledFormControlLabel
            key={choice.value}
            value={choice.value}
            outlined={outlined?.toString() || ''}
            control={<Radio key={choice.value} {...RadioProps} />}
            label={choice.text}
          />
        ))}
      </StyledRadioGroup>
      <ErrorText>{helperText}</ErrorText>
    </>
  )
}

RadioSelect.defaultProps = {
  RadioProps: undefined,
  outlined: false,
  defaultValue: false,
}

export default RadioSelect
