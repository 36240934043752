import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminStaticDocumentsGetStaticDocumentsDocument,
  AdminStaticDocumentsMutationDocument,
  Core_StaticDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType } from '@flock/shared-ui'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'

type DeleteStaticDocumentModalProps = {
  selectedDocument: Core_StaticDocument
  open: boolean
  onClose: () => void
}

const DeleteStaticDocumentModal = (props: DeleteStaticDocumentModalProps) => {
  const { open, onClose, selectedDocument } = props
  const { notify } = useSnackbar()

  const [loading, setLoading] = useState(false)
  const [deleteStaticDocument] = useMutation(
    AdminStaticDocumentsMutationDocument
  )

  const inputConfigs: AnyInputConfig[] = [
    {
      name: 'confirmation',
      type: InputType.CustomComponent,
      props: {
        component: (
          <Typography>
            Are you sure you want to delete &quot;{selectedDocument?.name}
            &quot;?
          </Typography>
        ),
      },
    },
  ]

  const onSubmit = async () => {
    setLoading(true)

    const { uuid } = selectedDocument
    try {
      await deleteStaticDocument({
        variables: {
          input: {
            documentUuid: uuid,
          },
        },
        refetchQueries: [AdminStaticDocumentsGetStaticDocumentsDocument],
      })
      notify('Successfully deleted static document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to delete static document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Delete Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      formProps={{
        defaultValues: {
          ...selectedDocument,
        },
      }}
      gridProps={{
        spacing: 3,
      }}
      ctaText="Confirm"
      loading={loading}
    />
  )
}

export default DeleteStaticDocumentModal
