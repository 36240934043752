import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  styled,
  Typography,
  Grid,
  Button,
} from '@mui/material'
import { PopoverMenu } from '@flock/shared-ui'

import {
  flockColors,
  LoadingCard,
  useSnackbar,
} from '@flock/flock-component-library'

import { FormProvider, useForm } from 'react-hook-form'
import { navigate, RouteComponentProps, useParams } from '@reach/router'
import { useMutation, useQuery } from '@apollo/client'
import {
  AdminGetLegalEntityDocument,
  AdminOrderOnboardingV2SearchOrdersV2WithResolutionDataDocument,
  AdminOrderOnboardingV2UpdateOrderV2TaskDocument,
  Core_OrderPropertyContribution,
  Core_OrderV2,
  Core_OrderV2Task,
  Core_OrderV2TaskCompletionType,
  Core_OrderV2TaskKey,
  Core_OrderV2TaskSource,
  Core_OrderV2TaskStatus,
  Core_SearchOrdersV2Response,
  Core_UpdateOrderTaskRequestInput,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { ExpandMore } from '@mui/icons-material'
import { filterOrderV2Tasks } from '@flock/utils'
import {
  ContributorName,
  LabeledField,
  orderProcessUtils,
  propertyContributionProcessUtils,
  TaskProcessUtils,
} from './IndividualOrderV2PageUtils'
import {
  FLOCK_INVESTOR_SIMULATOR_URL,
  ORDER_ONBOARDING_URL,
} from '../../constants'
import {
  AddressRender,
  GetProcessStatus as GetStatusForTasks,
  OrderMetadata,
} from './OrderV2Utils'
import DeleteOrderV2Modal from './DeleteOrderV2Modal'

const PageWrapper = styled('div')({
  paddingBottom: '8rem',
})

const PageTitle = styled(Typography)({
  marginTop: '4rem',
  marginBottom: '2rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledSummary = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
})

const statusColors: { [key: string]: string } = {
  completed: flockColors.aquamarine,
  'in progress': flockColors.blue,
  'not started': flockColors.darkGray,
}

type IndividualOrderPageProps = RouteComponentProps & {
  orderUuid?: string
}

const IndividualOrderPageV2 = (props: IndividualOrderPageProps) => {
  let { orderUuid } = useParams()
  const { orderUuid: orderUuidProp } = props
  if (orderUuidProp) {
    orderUuid = orderUuidProp
  }
  const methods = useForm({ mode: 'onSubmit' })
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = methods
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false)
  const [propertyQuestionnaireModalOpen, setPropertyQuestionnaireModalOpen] =
    useState(false)
  const [ownerInformationModalOpen, setOwnerInformationModalOpen] =
    useState(false)
  const { notify } = useSnackbar()

  const [updateTask] = useMutation(
    AdminOrderOnboardingV2UpdateOrderV2TaskDocument
  )

  const {
    error,
    loading: orderLoading,
    data: orderData,
  } = useQuery(AdminOrderOnboardingV2SearchOrdersV2WithResolutionDataDocument, {
    variables: {
      input: {
        orderUuid,
      },
    },
    onError: () => {
      notify('Failed to get order', 'error')
    },
  })

  const { data: legalEntityData } = useQuery(AdminGetLegalEntityDocument, {
    skip: !orderData,
    variables: {
      input: {
        legalEntityUuid: orderData?.searchOrdersV2?.orders[0]?.legalEntityUuid,
      },
    },
  })

  if (orderLoading) {
    return <LoadingCard text="Loading Order..." />
  }

  if (error) {
    return (
      <LoadingCard
        text="Failed to find order. Please refresh or contact engineering if the issue persists."
        hideLoader
      />
    )
  }

  const { orders }: { orders: Core_OrderV2[] } =
    orderData?.searchOrdersV2 as Core_SearchOrdersV2Response
  const order = orders[0]
  const checkOldOrder = () => {
    const tasks = filterOrderV2Tasks({
      order,
      key: [Core_OrderV2TaskKey.OrderV2TaskKeyPropertyQuestionnaireCompleted],
    })
    // we determine whether something is an old order if the property questionnaire completion type is auto.
    if (tasks.length > 0) {
      return (
        tasks[0].status === Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted &&
        tasks[0].completionType ===
          Core_OrderV2TaskCompletionType.OrderV2TaskCompletionTypeCompletedAuto
      )
    }
    return false
  }

  const onSubmit = async (formResult: any) => {
    try {
      setSaveDisabled(true)
      const updateTaskPromises = Object.keys(dirtyFields).map((fieldKey) => {
        const formValue = formResult[fieldKey]
        const updateOrderTaskInput: Core_UpdateOrderTaskRequestInput = {
          orderUuid: order.uuid,
          skipSideEffect: true,
          taskData: {
            taskUuid: fieldKey,
            completionType: formValue
              ? Core_OrderV2TaskCompletionType.OrderV2TaskCompletionTypeCompletedAdmin
              : null,
            status: formValue
              ? Core_OrderV2TaskStatus.OrderV2TaskStatusCompleted
              : Core_OrderV2TaskStatus.OrderV2TaskStatusNotStarted,
          },
        }
        return updateTask({
          refetchQueries: [
            AdminOrderOnboardingV2SearchOrdersV2WithResolutionDataDocument,
          ],
          variables: {
            updateOrderTaskInput,
          },
        })
      })
      await Promise.all(updateTaskPromises)
      notify('Successfully saved order status', 'success')
      reset(formResult)
    } catch (e) {
      notify('An error has occurred. Please refresh and try again.', 'error')
    }
    setSaveDisabled(false)
  }

  // if an order is an old order, redirect to the account page
  const createOrderSimulatorLink = () =>
    // we determine whether something is an old order if the property questionnaire completion type is auto.
    checkOldOrder()
      ? `${FLOCK_INVESTOR_SIMULATOR_URL}/app/simulator/${legalEntityData?.legalEntity?.legalEntity?.investorAccounts[0]}/app/account/`
      : `${FLOCK_INVESTOR_SIMULATOR_URL}/app/simulator/${legalEntityData?.legalEntity?.legalEntity?.investorAccounts[0]}/app/orders/${order.uuid}`

  const links = [
    {
      key: 'openInvestorViewButton',
      text: 'Open Investor View',
      onClick: () => window.open(createOrderSimulatorLink(), '_blank'),
    },

    {
      key: 'deleteOrderButton',
      text: 'Delete Order',
      onClick: () => setDeleteOrderModalOpen(true),
    },
  ]
  if (checkOldOrder()) {
    links.unshift({
      key: 'viewOldOrderButton',
      text: 'View Old Order',
      onClick: () =>
        window.open(`${ORDER_ONBOARDING_URL}v1/${orderUuid}`, '_blank'),
    })
  }

  const allInformationTasksStatus = GetStatusForTasks(
    filterOrderV2Tasks({
      order,
      sourceType: [Core_OrderV2TaskSource.OrderV2TaskSourceOrder],
    }).filter(
      (task: Core_OrderV2Task) =>
        task.key !== Core_OrderV2TaskKey.OrderV2TaskKeyUnspecified
    )
  )
  return (
    <PageWrapper>
      <DeleteOrderV2Modal
        isOpen={deleteOrderModalOpen}
        orderMetadata={order as OrderMetadata}
        close={() => {
          navigate(`${ORDER_ONBOARDING_URL}v2/`)
          setDeleteOrderModalOpen(false)
        }}
      />
      <PageTitle variant="h3">
        Order Tasks {checkOldOrder() && '(Old Order)'}
        <div>
          <PopoverMenu
            text="Links"
            buttonProps={{
              variant: 'outlined',
              sx: {
                marginRight: '1rem',
              },
            }}
            popoverProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
              transformOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }}
            actions={links}
          />
          <Button
            disabled={!isDirty || saveDisabled || checkOldOrder()}
            variant="secondary"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
      </PageTitle>
      <Grid container sx={{ marginBottom: '2rem' }}>
        <ContributorName legalEntityUuid={order.legalEntityUuid} />
        <LabeledField
          label="Date Created"
          value={new Date(order.createdAt).toLocaleDateString('en-US')}
        />
        <LabeledField
          label="Date Updated"
          value={new Date(order.updatedAt).toLocaleDateString('en-US')}
        />
        {order.targetCloseDate && (
          <LabeledField
            label="Target Contribution Date"
            value={new Date(order.targetCloseDate).toLocaleDateString('en-US')}
          />
        )}
      </Grid>
      <FormProvider {...methods}>
        {order.propertyContributions.map(
          (propertyContribution: Core_OrderPropertyContribution) => {
            const contributionStatus = GetStatusForTasks(
              filterOrderV2Tasks({
                order,
                sourceUuid: [propertyContribution.uuid],
              })
            )
            return (
              <Accordion key={propertyContribution.uuid}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <StyledSummary>
                    <Typography variant="h3">
                      <AddressRender
                        addressUuid={propertyContribution.addressUuid}
                      />
                    </Typography>
                    <Chip
                      label={contributionStatus}
                      sx={{
                        textTransform: 'capitalize',
                        color: 'white',
                        backgroundColor: statusColors[contributionStatus],
                        pointerEvents: 'none',
                      }}
                    />
                  </StyledSummary>
                </AccordionSummary>
                {propertyContributionProcessUtils.map(
                  (utils: TaskProcessUtils) => {
                    const { ProcessComponent, processName, processString } =
                      utils
                    const currentProcess: Core_OrderV2Task[] =
                      propertyContribution[
                        processName as keyof typeof propertyContribution
                      ] as Core_OrderV2Task[]
                    const status = GetStatusForTasks(currentProcess)
                    return (
                      <AccordionDetails key={processName}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMore />}>
                            <StyledSummary>
                              <Typography variant="h3">
                                {processString}
                              </Typography>
                              <Chip
                                label={status}
                                sx={{
                                  textTransform: 'capitalize',
                                  color: 'white',
                                  backgroundColor: statusColors[status],
                                  pointerEvents: 'none',
                                }}
                              />
                            </StyledSummary>
                          </AccordionSummary>
                          <ProcessComponent
                            tasks={currentProcess}
                            legalEntityUuid={order.legalEntityUuid}
                            propertyQuestionnaireModalOpen={
                              propertyQuestionnaireModalOpen
                            }
                            setPropertyQuestionnaireModalOpen={
                              setPropertyQuestionnaireModalOpen
                            }
                          />
                        </Accordion>
                      </AccordionDetails>
                    )
                  }
                )}
              </Accordion>
            )
          }
        )}
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <StyledSummary>
              <Typography variant="h3">Information Tasks</Typography>
              <Chip
                label={allInformationTasksStatus}
                sx={{
                  textTransform: 'capitalize',
                  color: 'white',
                  backgroundColor: statusColors[allInformationTasksStatus],
                  pointerEvents: 'none',
                }}
              />
            </StyledSummary>
          </AccordionSummary>
          {orderProcessUtils.map((utils: TaskProcessUtils) => {
            const { ProcessComponent, processName, processString } = utils
            const processes = order.orderProcesses!
            const currentProcess: Core_OrderV2Task[] = processes[
              processName as keyof typeof processes
            ] as Core_OrderV2Task[]
            const status = GetStatusForTasks(currentProcess)
            return (
              <AccordionDetails key={processName}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <StyledSummary>
                      <Typography variant="h3">{processString}</Typography>
                      <Chip
                        label={status}
                        sx={{
                          textTransform: 'capitalize',
                          color: 'white',
                          backgroundColor: statusColors[status],
                          pointerEvents: 'none',
                        }}
                      />
                    </StyledSummary>
                  </AccordionSummary>
                  <ProcessComponent
                    tasks={currentProcess}
                    legalEntityUuid={order.legalEntityUuid}
                    ownerInformationModalOpen={ownerInformationModalOpen}
                    setOwnerInformationModalOpen={setOwnerInformationModalOpen}
                  />
                </Accordion>
              </AccordionDetails>
            )
          })}
        </Accordion>
      </FormProvider>
    </PageWrapper>
  )
}

IndividualOrderPageV2.defaultProps = {
  orderUuid: undefined,
}

export default IndividualOrderPageV2
