import { DataTable } from '@flock/flock-component-library'
import { Core_OrderV2 } from '@flock/flock-gql-server/src/__generated__/graphql'
import React, { useState } from 'react'
import DeleteOrderV2Modal from './DeleteOrderV2Modal'
import IndividualOrderV2Modal from './IndividualOrderV2Modal'
import OrderRowRender, {
  createOrderRows,
  orderColumns,
} from './OrderV2RowRender'
import { OrderMetadata } from './OrderV2Utils'

const options = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  search: false,
  sort: true,
  selectableRows: 'none',
  responsive: 'standard',
  customRowRender,
  elevation: 0,
})

const OrderV2Table = (props: { orders: Core_OrderV2[] }) => {
  const { orders } = props
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] =
    useState<boolean>(false)
  const [existingOrder, setExistingOrder] = useState<OrderMetadata>(
    {} as OrderMetadata
  )
  const openDeleteOrderModal = (order: OrderMetadata) => {
    setDeleteOrderModalOpen(true)
    setExistingOrder(order)
  }
  const closeDeleteOrderModal = () => {
    setDeleteOrderModalOpen(false)
  }

  const [individualOrderV2ModalOpen, setIndividualOrderV2ModalOpen] =
    useState<boolean>(false)
  const openIndividualOrderV2Modal = (order: OrderMetadata) => {
    setIndividualOrderV2ModalOpen(true)
    setExistingOrder(order)
  }
  const closeIndividualOrderV2Modal = () => {
    setIndividualOrderV2ModalOpen(false)
  }
  const orderRows = createOrderRows(orders || [])

  return (
    <>
      <DataTable
        title=""
        data={orderRows}
        columns={orderColumns}
        options={
          options(
            OrderRowRender(openDeleteOrderModal, openIndividualOrderV2Modal)
          ) as any
        }
      />
      {existingOrder?.legalEntityUuid && (
        <DeleteOrderV2Modal
          orderMetadata={existingOrder}
          isOpen={deleteOrderModalOpen}
          close={closeDeleteOrderModal}
        />
      )}
      {existingOrder?.uuid && (
        <IndividualOrderV2Modal
          orderUuid={existingOrder?.uuid as string}
          isOpen={individualOrderV2ModalOpen}
          close={closeIndividualOrderV2Modal}
        />
      )}
    </>
  )
}

export default OrderV2Table
