import React, { useEffect, useState } from 'react'
import {
  BaseModal,
  AuditLogTable,
  useSnackbar,
  AuditTableEntry,
} from '@flock/flock-component-library'
import { gql, useQuery } from '@apollo/client'
import pluralize from 'pluralize'
import {
  AdminGetAuditLogsDocument,
  AdminGetOperatorDocument,
  Core_AuditLog,
  OvermoonAdminGetAddressDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import _ from 'lodash'
import { styled, CircularProgress } from '@mui/material'

export const OVERMOON_GET_ADDRESS = gql`
  query OvermoonAdminGetAddress($input: Core_GetAddressRequestInput!) {
    address(input: $input) {
      address {
        formattedAddress
      }
    }
  }
`

const LoadingWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})

type AuditLogModalProps = {
  open: boolean
  onClose: () => void
  entityUuid: string
  entityIsInvestor?: boolean
}

const AuditLogModal = (props: AuditLogModalProps) => {
  const { open, onClose, entityUuid, entityIsInvestor } = props

  const [processedData, setProcessedData] = useState<AuditTableEntry[]>([])
  const [loading, setLoading] = useState(false)

  const { refetch: fetchOperator } = useQuery(AdminGetOperatorDocument, {
    skip: true,
  })

  const { refetch } = useQuery(AdminGetAuditLogsDocument, {
    skip: !entityUuid || !open,
    variables: {
      input: {
        tags: [entityUuid],
        isInvestor: entityIsInvestor,
      },
    },
  })

  const { refetch: fetchAddress } = useQuery(OvermoonAdminGetAddressDocument, {
    skip: true,
  })

  const processData = async () => {
    setLoading(true)
    const data = await refetch()
    const preProcessedData = data
      .data!.auditLogs!.auditLogs!.map(
        (log: Core_AuditLog | null | undefined) => {
          const previousJson = JSON.parse(log?.previousValue || '{}')
          const updatedJson = JSON.parse(log?.updatedValue || '{}')
          const { operation, tableName } = log as Core_AuditLog

          let eventName = ''
          // For changes on legal entities, we want to alter the event name based on what was updated
          const alteredKeys = Object.keys(updatedJson)
          const cleanedTableName = pluralize(
            tableName?.replace('legal_entities_', '') || '',
            1
          )

          switch (tableName) {
            case 'legal_entities':
              if (
                alteredKeys.includes('plaidAccessToken') ||
                alteredKeys.includes('basistheoryToken') ||
                alteredKeys.includes('bankAccountIsPersonal')
              ) {
                eventName = 'Bank Account Information Updated'
              } else if (alteredKeys.includes('cashDistributionEnabled')) {
                eventName = 'Cash Distribution Preference Updated'
              } else if (alteredKeys.length === 0) {
                eventName = ''
              } else {
                eventName = 'Legal Entity Contact Updated'
              }
              break
            default:
              // Add a 'd' at the end to make the operation past tense
              eventName = `${cleanedTableName} ${operation}d`
              break
          }
          const result = {
            ...log,
            eventName: _.startCase(eventName),
            previousValue: previousJson,
            updatedValue: updatedJson,
          }
          return result
        }
      )
      .filter((entry: any) => entry?.eventName !== '')

    for (let i = 0; i < preProcessedData.length; i += 1) {
      const log = preProcessedData[i]
      const previousJson = log?.previousValue || {}
      const updatedJson = log?.updatedValue || {}
      const cleanedPreviousJson = { ...previousJson }
      const cleanedUpdatedJson = { ...updatedJson }

      Object.keys(updatedJson).forEach(async (key) => {
        if (key.toLowerCase().includes('address')) {
          const prevValue = previousJson[key]
          const newValue = updatedJson[key]

          if (prevValue && newValue) {
            const prevAddress = await fetchAddress({
              input: { addressUuid: prevValue },
            })
            const newAddress = await fetchAddress({
              input: { addressUuid: newValue },
            })

            const newKey = key.replace('Uuid', '')

            cleanedPreviousJson[newKey] =
              prevAddress.data?.address?.address?.formattedAddress
            cleanedUpdatedJson[newKey] =
              newAddress.data?.address?.address?.formattedAddress

            delete cleanedPreviousJson[key]
            delete cleanedUpdatedJson[key]
          }
        }
      })

      preProcessedData[i] = {
        ...log,
        previousValue: cleanedPreviousJson,
        updatedValue: cleanedUpdatedJson,
      }
    }

    setProcessedData(preProcessedData as AuditTableEntry[])
    setLoading(false)
  }

  useEffect(() => {
    processData()
  }, [])

  const { notify } = useSnackbar()

  const fetchUserName = async (uuid: string, entityType: string) => {
    if (entityType === 'operator') {
      try {
        const { data: operatorData } = await fetchOperator({
          input: { operatorUuid: uuid },
        })
        return operatorData?.operator?.operator?.fullName || 'Unknown'
      } catch (e) {
        notify(
          'An error occurred while looking up the associated user for this audit log.'
        )
        return 'Error'
      }
    } else {
      return 'User'
    }
  }

  return (
    <BaseModal title="Event Log" open={open} onClose={onClose} closeOnClickoff>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <AuditLogTable
          auditLogEntries={processedData as AuditTableEntry[]}
          fetchUserName={fetchUserName}
          showDetails
          isTransaction={false}
        />
      )}
    </BaseModal>
  )
}

export default AuditLogModal

AuditLogModal.defaultProps = {
  entityIsInvestor: false,
}
