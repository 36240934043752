import { GridProps, TextFieldProps } from '@mui/material'
import { MuiPhoneNumberProps } from 'mui-phone-input-ssr'
import React from 'react'
import { EditorProps } from 'react-draft-wysiwyg'
import { CheckboxProps } from '../Checkbox/Checkbox'
import { DatePickerProps } from '../DatePicker/DatePicker'
import { DropdownProps } from '../Dropdown/Dropdown'
import { FileUploadProps } from '../FileUpload/FileUpload'
import { RadioSelectProps } from '../RadioSelect/RadioSelect'

export enum InputType {
  Text,
  Radio,
  Checkbox,
  Dropdown,
  DatePicker,
  Phone,
  FileUpload,
  NonInput,
  MultiForm,
  Editor,
  TextWithConfirmation,
  Address,
}

export type MultiFormProps = {
  incrementText: string
  decrementText: string
  label: string
  inputConfigs: InputConfig[]
  minForms: number
  maxForms?: number
  customLabels?: string[]
  collapse?: boolean
}

export type NonInputProps = {
  component: React.ReactNode
}

export type TextFieldOptions = TextFieldProps & {
  type:
    | 'text' // No specific validation
    | 'number' // Mask for numbers only
    | 'money' // Mask for numbers + dollar
    | 'email' // Validates email
    | 'name' // Validates name
    | 'year' // Validates year
  pattern?: RegExp
  helperText?: string
}

export type TextWithConfirmationOptions = TextFieldProps & {
  fetchDisplayData: (text: string) => any
  TextToConfirmDisplay: (props: any) => JSX.Element
  helperText?: string
}

export type InputConfig = {
  inputName: string
  inputType: InputType
  gridProps?: GridProps // Props for the Grid item wrapper
  required?: boolean
  rules?: any // Rules for register
  watchField?: string // Field index to watch for if this field should be rendered
  renderOn?: string // Render only if the watchField matches the renderOn value.
  renderExpression?: string // Render expression compiled by Filtrex against other values in the form. Used for complex expressions.
  props:
    | TextFieldOptions
    | RadioSelectProps
    | CheckboxProps
    | DatePickerProps
    | DropdownProps
    | MuiPhoneNumberProps
    | FileUploadProps
    | NonInputProps
    | MultiFormProps
    | EditorProps
    | TextWithConfirmationOptions
}
