import React, { useCallback, useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  useSnackbar,
  DataTable,
  InputType,
} from '@flock/flock-component-library'
import {
  Core_Task as Task,
  Core_Lead as Lead,
  Core_LegalEntity as LegalEntity,
  Core_InitiateOrderV2RequestInput,
  AdminGetLegalEntityDocument,
  AdminGetTasksDocument,
  AdminSearchLeadsDocument,
  Core_Lead,
  AdminOrderOnboardingV2InitiateOrderV2Document,
  AdminUpdateTaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import {
  formatAddressString,
  formatCityStateZip,
  formatPhoneNumber,
} from '@flock/utils'
import { TableCell, TableRow, styled, Button, Typography } from '@mui/material'
import { navigate, RouteComponentProps } from '@reach/router'
import LoadingCard from '../LoadingCard'
import { TaskStatus } from '../TaskManagement/taskTypes'

import ActionFormModal from '../shared/ActionFormModal'
import { allStatesLongForm, NEW_ORDER_CREATION_URL } from '../../constants'

type InitiateInvestorOrderModalParams = {
  isOpen: boolean
  close: () => void
  defaultLead: Lead
  taskUuid: string
  leadsUuidAndAddress: any[]
}

const InitiateInvestorOrderModal = ({
  close,
  isOpen,
  taskUuid,
  defaultLead,
  leadsUuidAndAddress,
}: InitiateInvestorOrderModalParams) => {
  const [initiateOrderV2, { loading }] = useMutation(
    AdminOrderOnboardingV2InitiateOrderV2Document
  )
  const [updateTask] = useMutation(AdminUpdateTaskDocument)

  const { notify } = useSnackbar()

  const handleClose = () => {
    close()
  }

  const onSubmit = async (result: any) => {
    try {
      const leadUuidToTitleCompany = leadsUuidAndAddress.map((lead) => ({
        key: lead.uuid,
        value: result[lead.uuid],
      }))

      const leadUuids = leadsUuidAndAddress.map((lead) => lead.uuid)
      const resultFieldsToExclude = [
        ...leadUuids,
        'existingLegalEntity',
        'existingInvestorAccount',
        'hasJointOwner',
      ]
      const inputFieldsFromResult = Object.keys(result)
        .filter((key) => !resultFieldsToExclude.includes(key))
        .reduce((acc: any, key) => {
          const newAcc = { ...acc }
          newAcc[key] = result[key]
          return newAcc
        }, {})

      const initiateOrderV2Input = {
        leadUuids,
        leadUuidToTitleCompany,
        ...inputFieldsFromResult,
      } as Core_InitiateOrderV2RequestInput

      const investorOrderResult = await initiateOrderV2({
        variables: { initiateOrderV2Input },
      })

      await updateTask({
        variables: {
          updateTaskInput: {
            taskUuid,
            status: TaskStatus.COMPLETED,
          },
        },
        refetchQueries: [AdminGetTasksDocument],
      })

      close()
      notify(
        `Successfully initiated investor order (${investorOrderResult?.data?.initiateOrderV2?.order?.uuid})`,
        'success'
      )
    } catch (e) {
      close()
      notify('Failed to initiate investor order', 'error')
    }
  }

  const jointOwnerConfigs = [
    {
      inputName: 'hasJointOwner',
      inputType: InputType.Checkbox,
      props: {
        label: 'Add a joint signer to this contribution agreement',
        'data-cy': 'orderHasJointOwner',
      },
    },
    {
      inputName: 'jointOwnerName',
      inputType: InputType.Text,
      watchField: 'hasJointOwner',
      required: true,
      props: {
        label: 'Joint Owner Name',
        type: 'text',
        fullWidth: true,
      },
    },
    {
      inputName: 'jointOwnerEmail',
      inputType: InputType.Text,
      watchField: 'hasJointOwner',
      required: true,
      props: {
        label: 'Joint Owner Email',
        type: 'text',
        fullWidth: true,
      },
    },
    {
      inputName: 'jointOwnerRelationToEntity',
      inputType: InputType.Text,
      watchField: 'hasJointOwner',
      required: true,
      props: {
        label: 'Joint Owner Relationship',
        type: 'text',
        fullWidth: true,
      },
    },
  ]

  const nonIndividualLegalEntityConfigs = [
    {
      inputName: 'legalEntityState',
      inputType: InputType.Dropdown,
      renderExpression:
        'legalEntityType != "individual" and legalEntityType != null',
      required: true,
      props: {
        label: 'Legal Entity State',
        fullWidth: true,
        options: allStatesLongForm.map((state) => ({
          text: state,
          value: state,
        })),
      },
    },
    {
      inputName: 'investorName',
      inputType: InputType.Text,
      renderExpression:
        'legalEntityType != "individual" and legalEntityType != null',
      required: true,
      props: {
        label: 'Investor Name',
        type: 'text',
        fullWidth: true,
      },
    },
    {
      inputName: 'investorTitle',
      inputType: InputType.Dropdown,
      renderExpression:
        'legalEntityType != "individual" and legalEntityType != null',
      required: true,
      props: {
        label: 'Investor Title',
        fullWidth: true,
        // TODO: these options should be consumed from the backend, they are valid fields for the contribution agreement form
        options: [
          {
            text: 'Member',
            value: 'Member',
          },
          {
            text: 'President',
            value: 'President',
          },
          {
            text: 'Trustee',
            value: 'Trustee',
          },
        ],
      },
    },
  ]

  const leadTitleConfigs = leadsUuidAndAddress.map((lead): any => ({
    inputName: lead.uuid,
    inputType: InputType.Dropdown,
    required: true,
    props: {
      label: lead.address,
      options: [
        // TODO: consume these options from BE
        {
          text: 'First American Title Insurance Company',
          value: 'First American Title Insurance Company',
        },
        {
          text: 'Blueprint Title Company',
          value: 'Blueprint Title Company',
        },
        {
          text: 'Prominent Escrow Services, Inc.',
          value: 'Prominent Escrow Services, Inc.',
        },
        {
          text: 'Endpoint Title, LLC',
          value: 'Endpoint Title, LLC',
        },
        {
          text: 'TitleVest Agency, LLC',
          value: 'TitleVest Agency, LLC',
        },
        {
          text: 'VanderVeur & Page',
          value: 'VanderVeur & Page',
        },
        {
          text: 'Spruce Land Services LLC',
          value: 'Spruce Land Services LLC',
        },
        {
          text: 'Hankin & Pack PLLC',
          value: 'Hankin & Pack PLLC',
        },
      ],
    },
  }))

  const { refetch } = useQuery(AdminGetLegalEntityDocument, {
    skip: true,
  })

  const fetchDisplayData = async (legalEntityUuid: string) => {
    try {
      const { data } = await refetch({
        input: {
          legalEntityUuid,
        },
      })
      return data?.legalEntity?.legalEntity
    } catch (e) {
      notify('We failed to fetch the given legal entity.', 'error')
      return null
    }
  }

  type TextToConfirmDisplayProps = {
    displayData: LegalEntity
  }

  const ConfirmLegalEntityContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  })
  const ConfirmLegalEntity = ({ displayData }: TextToConfirmDisplayProps) => (
    <ConfirmLegalEntityContainer>
      <Typography variant="h3">Legal Entity</Typography>
      <Typography variant="p1">Name: {displayData?.name}</Typography>
      <Typography variant="p1">Email: {displayData?.email}</Typography>
      <Typography variant="p1">Type: {displayData?.type}</Typography>
    </ConfirmLegalEntityContainer>
  )

  const inputConfigs = [
    {
      inputName: 'legalEntitySection',
      inputType: InputType.NonInput,
      props: {
        component: <Typography variant="h3">Legal Entity Setup</Typography>,
      },
    },
    {
      inputName: 'existingLegalEntity',
      inputType: InputType.Radio,
      required: true,
      props: {
        label: 'New Legal Entity',
        options: [
          {
            text: 'Existing Legal Entity',
            value: 'true',
          },
          {
            text: 'New Legal Entity',
            value: 'false',
          },
        ],
      },
    },
    {
      inputName: 'existingInvestorAccount',
      renderExpression: 'existingLegalEntity == "false"',
      inputType: InputType.Radio,
      required: true,
      props: {
        label: 'New Contributor',
        options: [
          {
            text: 'Existing Contributor',
            value: 'true',
          },
          {
            text: 'New Contributor',
            value: 'false',
          },
        ],
      },
    },
    {
      inputName: 'previousLegalEntityUuid',
      renderExpression:
        'existingLegalEntity == "false" and existingInvestorAccount != "false"',
      inputType: InputType.TextWithConfirmation,
      required: true,
      props: {
        label: 'Previous Legal Entity UUID',
        fullWidth: true,
        fetchDisplayData,
        TextToConfirmDisplay: ConfirmLegalEntity,
        helperText: 'Please add and validate a legal entity uuid',
      },
    },
    {
      inputName: 'legalEntityUuid',
      renderExpression: 'existingLegalEntity != "false"',
      inputType: InputType.TextWithConfirmation,
      required: true,
      props: {
        label: 'Legal Entity UUID',
        fullWidth: true,
        fetchDisplayData,
        TextToConfirmDisplay: ConfirmLegalEntity,
        helperText: 'Please add and validate a legal entity uuid',
      },
    },
    {
      inputName: 'legalEntityType', // TODO: consume options from BE
      renderExpression: 'existingLegalEntity == "false"',
      inputType: InputType.Dropdown,
      required: true,
      props: {
        label: 'Type',
        fullWidth: true,
        options: [
          {
            text: 'Individual',
            value: 'individual',
          },
          {
            text: 'LLC',
            value: 'llc',
          },
          {
            text: 'Corporation',
            value: 'corporation',
          },
          {
            text: 'Trust',
            value: 'trust',
          },
        ],
      },
    },
    {
      inputName: 'legalEntityName',
      renderExpression: 'existingLegalEntity == "false"',
      inputType: InputType.Text,
      required: true,
      props: {
        defaultValue: defaultLead?.fullName,
        label: 'Name',
        type: 'text',
      },
    },
    {
      inputName: 'legalEntityEmail',
      renderExpression: 'existingLegalEntity == "false"',
      inputType: InputType.Text,
      props: {
        defaultValue: defaultLead?.email || 'WARNING: missing email',
        label: 'Email',
        type: 'email',
      },
    },
    {
      inputName: 'legalEntityPhoneNumber',
      renderExpression: 'existingLegalEntity == "false"',
      inputType: InputType.Text,
      props: {
        defaultValue: defaultLead?.phoneNumber
          ? formatPhoneNumber(defaultLead.phoneNumber)
          : 'WARNING: missing phone number',
        label: 'Phone Number',
        type: 'text',
      },
    },
    ...nonIndividualLegalEntityConfigs,
    ...jointOwnerConfigs,
    {
      inputName: 'titlePartnersSection',
      inputType: InputType.NonInput,
      props: {
        component: (
          <Typography variant="h3">Title Partners for Properties</Typography>
        ),
      },
    },
    ...leadTitleConfigs,
  ]

  return (
    <ActionFormModal
      open={isOpen}
      setOpen={handleClose}
      loading={loading}
      onSubmit={onSubmit}
      actionText="Initiate Investor Order"
      inputConfigs={inputConfigs}
    />
  )
}

const AddressesContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const CustomRowRender = (
  rowData: [string, string, string, string[], any, string]
) => {
  const [uuid, createdAt, name, addresses] = rowData

  const createdAtDate = new Date(createdAt)

  return (
    <TableRow key={uuid}>
      <TableCell>{createdAtDate.toLocaleString()}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        <AddressesContainer>
          {addresses.length !== 0 &&
            addresses.map((address) => (
              <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                {address}
              </Typography>
            ))}
        </AddressesContainer>
      </TableCell>
      <TableCell align="right">
        <Button
          variant="secondary"
          color="primary"
          onClick={() => {
            navigate(`${NEW_ORDER_CREATION_URL}/${uuid}`)
          }}
        >
          Create Order
        </Button>
      </TableCell>
    </TableRow>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewContributionsQueueV2 = (_: RouteComponentProps) => {
  const [leadUuids, setLeadUuids] = useState<string[]>([])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalTaskLeadProps, setModalTaskLeadProps] = useState<{
    taskUuid: string
    defaultLead?: Lead
    leadsUuidAndAddress: any[]
  }>({ taskUuid: '', leadsUuidAndAddress: [] })

  const openModal = (
    taskUuid: string,
    defaultLead: Lead,
    leadsUuidAndAddress: any[]
  ) => {
    setModalTaskLeadProps({
      taskUuid,
      defaultLead,
      leadsUuidAndAddress,
    })
    setModalIsOpen(true)
  }

  const closeModal = () => setModalIsOpen(false)

  const { notify } = useSnackbar()

  const {
    loading: taskCreateOrderLoading,
    data: taskCreateOrderData,
    error: taskCreateOrderError,
  } = useQuery(AdminGetTasksDocument, {
    variables: {
      searchTasksInput: {
        statuses: [TaskStatus.NOT_STARTED, ''],
        types: ['create_order'],
      },
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      notify('Failed to get tasks', 'error')
    },
  })

  const {
    loading: leadsLoading,
    data: leadsData,
    error: leadsError,
  } = useQuery(AdminSearchLeadsDocument, {
    variables: {
      searchLeadsInput: {
        uuids: leadUuids,
      },
    },
    skip: leadUuids.length === 0,
    fetchPolicy: 'no-cache',
    onError: () => {
      notify('Failed to get leads', 'error')
    },
  })

  type CreateOrderRequestDetail = {
    orderName: string
    addressesWithLeaseInfo: string[]
    lead_uuids: string[]
  }

  type CreateOrderRequestData = {
    request_detail: string
  }

  const parseCreateOrderRequest = useCallback(
    (task: Task): CreateOrderRequestDetail => {
      const defaultEmptyJsonString = '{"request_detail": {"lead_uuids": []}}'
      const requestData = JSON.parse(
        task?.requestData || defaultEmptyJsonString
      ) as CreateOrderRequestData

      if (requestData.request_detail === '') {
        return {} as CreateOrderRequestDetail
      }

      const requestDetail = JSON.parse(
        requestData.request_detail
      ) as CreateOrderRequestDetail

      return requestDetail
    },
    []
  )

  useEffect(() => {
    if (taskCreateOrderData?.searchTasks?.tasks) {
      const leadUuidsTmp = taskCreateOrderData.searchTasks.tasks.reduce(
        (acc: string[], task: Task) => {
          const requestDetail = parseCreateOrderRequest(task)
          const taskLeadUuids = requestDetail.lead_uuids

          return [...acc, ...taskLeadUuids]
        },
        []
      )

      const uniqueLeadUuids = Array.from(new Set(leadUuidsTmp)) as string[]
      setLeadUuids(uniqueLeadUuids)
    }
  }, [taskCreateOrderData, parseCreateOrderRequest])

  if (taskCreateOrderLoading || leadsLoading) {
    return <LoadingCard text="" />
  } else if (taskCreateOrderError) {
    return <>Error: Failed to load the tasks for new contributions.</>
  } else if (leadsError) {
    return (
      <>Error: Failed to load lead-related data for every new contribution.</>
    )
  } else {
    const buildTaskRows = (tasks: Task[], leads: Lead[]): any[] => {
      const leadsByUuid = leads.reduce(
        (acc: { [key: string]: Lead }, currentValue: Lead) => {
          acc[currentValue.uuid || ''] = currentValue
          return acc
        },
        {}
      )

      const taskRows = tasks.map((task: Task) => {
        const requestDetail = parseCreateOrderRequest(task)
        const taskLeadUuids = requestDetail.lead_uuids
        let firstLead: Lead = {} as Lead
        let leadAddresses: string[] = []
        let leadsUuidAndAddress: any[] = []
        if (taskLeadUuids.length !== 0) {
          firstLead = leadsByUuid[taskLeadUuids[0]]

          const structuredTaskLeadData = taskLeadUuids.reduce(
            (
              previousValue: { [key: string]: any },
              currentValue: string
            ): any => {
              const address = leadsByUuid[currentValue]?.address || undefined
              const formattedAddress = `${formatAddressString(
                address
              )}, ${formatCityStateZip(address)}`
              const lead = {
                uuid: currentValue,
                address: formattedAddress,
                addressUuid: leadsByUuid[currentValue]?.addressUuid,
              }
              return {
                leadAddresses: [
                  ...previousValue.leadAddresses,
                  formattedAddress,
                ],
                leadsUuidAndAddress: [
                  ...previousValue.leadsUuidAndAddress,
                  lead,
                ],
              }
            },
            { leadAddresses: [], leadsUuidAndAddress: [] }
          )

          leadAddresses = structuredTaskLeadData.leadAddresses
          leadsUuidAndAddress = structuredTaskLeadData.leadsUuidAndAddress
        }

        return {
          uuid: task.uuid,
          createdAt: task?.createdAt,
          name: requestDetail.orderName || firstLead?.fullName || '',
          addresses: leadAddresses,
          actionParams: {
            openModal: () =>
              openModal(task.uuid || '', firstLead, leadsUuidAndAddress),
          },
        }
      })

      return taskRows
    }

    const taskRows = buildTaskRows(
      taskCreateOrderData?.searchTasks?.tasks || [],
      (leadsData?.searchLeads?.leads as Core_Lead[]) || []
    )

    const columns = [
      { name: 'uuid', label: 'UUID', options: { display: false } },
      { name: 'createdAt', label: 'Requested At' },
      { name: 'name', label: 'Name' },
      { name: 'addresses', label: 'Properties' },
      { name: 'actionParams', label: '' },
      { name: '', label: '' },
    ]

    const options = {
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
      search: true,
      sort: true,
      selectableRows: 'none',
      responsive: 'standard',
      elevation: 0,
      sortOrder: {
        name: 'slaDeadline',
        direction: 'asc',
      },
      customRowRender: CustomRowRender,
    }

    return (
      <>
        <Typography
          variant="h2"
          sx={{ paddingTop: '64px', paddingBottom: '48px' }}
        >
          New Contributions
        </Typography>
        <DataTable
          title=""
          data={taskRows}
          columns={columns}
          options={options as any}
        />
        <InitiateInvestorOrderModal
          isOpen={modalIsOpen}
          close={closeModal}
          taskUuid={modalTaskLeadProps.taskUuid}
          defaultLead={modalTaskLeadProps.defaultLead as Lead}
          leadsUuidAndAddress={modalTaskLeadProps.leadsUuidAndAddress}
        />
      </>
    )
  }
}

export default NewContributionsQueueV2
