import React from 'react'
import { TableCell, TableRow, styled, Checkbox } from '@mui/material'
import { formatIntegerCents } from '@flock/utils'

const CenteredContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'row',
})

export const investmentColumns = [
  {
    name: 'legalEntityName',
    label: 'Legal Entity Name',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'startDateTime',
    label: 'Start Date',
    options: { sortThirdClickReset: true },
  },
  {
    name: 'totalEquityValue',
    label: 'Equity Value',
    options: { sortThirdClickReset: true },
  },
]

const InvestmentRowRender = (
  data: [string, string, number, number, number]
) => {
  const [legalEntityName, startDatetime, equityValue] = data
  const startDatetime2 = new Date(startDatetime)

  return (
    <TableRow>
      <TableCell>
        <Checkbox />
      </TableCell>
      <TableCell>
        <CenteredContent>{legalEntityName}</CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>
          {startDatetime2.toLocaleDateString('en-US')}
        </CenteredContent>
      </TableCell>
      <TableCell>
        <CenteredContent>{formatIntegerCents(equityValue)}</CenteredContent>
      </TableCell>
    </TableRow>
  )
}

export default InvestmentRowRender
