import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import { Download } from '@mui/icons-material'
import { isEqual } from 'lodash'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import {
  AdminGetLeadDocument,
  AdminUpdateLeadDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useStaticDocuments } from '../hooks/useStaticDocuments'
import LoadingCard from '../LoadingCard'

type LeadStaticDocumentsTableProps = {
  leadUuid: string
  overrides: { documents: { [key: string]: boolean } }
}

const LeadStaticDocumentsTable = (props: LeadStaticDocumentsTableProps) => {
  const { leadUuid, overrides } = props
  const documentOverrides = useMemo(
    () => overrides?.documents || {},
    [overrides]
  )

  const [currentOverrides, setCurrentOverrides] = useState<{
    [key: string]: boolean
  }>(documentOverrides)
  const [isDirty, setIsDirty] = useState(false)
  const [updateLead] = useMutation(AdminUpdateLeadDocument)
  const [saveLoading, setSaveLoading] = useState(false)
  const { loading, staticDocumentConfigs } = useStaticDocuments()

  const { notify } = useSnackbar()

  useEffect(() => {
    if (!isEqual(documentOverrides, currentOverrides)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }, [overrides, currentOverrides, documentOverrides])

  const onToggle = (documentUuid: string, newValue: boolean) => {
    const newOverrides = {
      ...currentOverrides,
      [documentUuid]: newValue,
    }

    setCurrentOverrides(newOverrides)

    if (!isEqual(documentOverrides, newOverrides)) {
      setIsDirty(true)
    } else {
      setIsDirty(false)
    }
  }

  const onSave = async () => {
    setSaveLoading(true)
    try {
      const newOverrides = {
        ...overrides,
        documents: currentOverrides,
      }

      await updateLead({
        variables: {
          updateLeadInput: {
            leadUuid,
            overrides: JSON.stringify(newOverrides),
          },
        },
        refetchQueries: [AdminGetLeadDocument],
      })
      notify('Successfully updated document settings.', 'success')
    } catch (e) {
      notify('Failed to update document settings.', 'error')
    }
    setSaveLoading(false)
  }

  if (loading) {
    return <LoadingCard text="" />
  }

  return (
    <Box display="flex" flexDirection="column" gap="48px">
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">Universal Document Toggles</Typography>
        <Button
          variant="primary"
          size="smallForm"
          onClick={onSave}
          disabled={!isDirty || saveLoading}
        >
          {saveLoading ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </Box>
      {staticDocumentConfigs.map((documentConfig) => {
        const { title, documents } = documentConfig
        return (
          <Box key={title} display="flex" flexDirection="column" gap="16px">
            <Typography variant="h4">{title}</Typography>
            <Table>
              <TableHead>
                <TableCell>Name</TableCell>
                <TableCell>Download</TableCell>
                <TableCell>Visible</TableCell>
              </TableHead>
              <TableBody>
                {documents.map((document) => {
                  const { uuid, name, contentUrl, defaultEnabled } = document
                  const currentValue = currentOverrides[uuid] ?? defaultEnabled

                  return (
                    <TableRow key={uuid}>
                      <TableCell width="512px">{name}</TableCell>
                      <TableCell>
                        <Box display="flex" gap="16px">
                          <IconButton
                            onClick={() => window.open(contentUrl!, '_blank')}
                          >
                            <Download />
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Switch
                          checked={currentValue}
                          onChange={() => onToggle(uuid, !currentValue)}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        )
      })}
    </Box>
  )
}

export default LeadStaticDocumentsTable
