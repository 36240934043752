import React from 'react'
import MuiGrid, { GridProps } from '@mui/material/Grid'
import { ThemeProvider } from '@mui/material/styles'
import flockTheme from '../flockTheme/flockTheme'

const Grid = (props: GridProps) => (
  <ThemeProvider theme={flockTheme}>
    <MuiGrid {...props} />
  </ThemeProvider>
)

export default Grid
