import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { ThemeProvider, Typography } from '@mui/material'
import { useQuery } from '@apollo/client'
import { formatIntegerCents } from '@flock/utils'
import {
  AdminGetAllInvestmentsInfoDocument,
  AdminGetLegalEntitiesTransactionsReportUrlDocument,
  Core_LegalEntityInvestmentInfo,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { GridForm, InputType } from '@flock/shared-ui/src/components/GridForm'
import { DataTable, useSnackbar } from '@flock/flock-component-library'
import { blueOvermoonTheme } from '@flock/shared-ui/src/theme'
import { RouteComponentProps } from '@reach/router'
import { investmentColumns } from './InvestmentRowRender'

const PageContainer = styled('div')({
  minWidth: '70vw',
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
})

const SubHeaderContainer = styled('div')({
  marginTop: '1rem',
  marginBottom: '1rem',
  paddingLeft: '3rem',
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReportsPage = (_: RouteComponentProps) => {
  const { notify } = useSnackbar()

  const { refetch: getLegalEntitiesTransactionsReport } = useQuery(
    AdminGetLegalEntitiesTransactionsReportUrlDocument,
    {
      skip: true,
      onError: () => {
        notify(
          'Failed to get legal entities transaction report csv s3 url',
          'error'
        )
      },
    }
  )

  type FormInputs = {
    startDate: Date
    endDate: Date
    transactionTypes: string[]
  }

  const getLegalEntitiesTransactionsReportUrl = async (
    inputs: FormInputs,
    legalEntityUuids: string[]
  ) => {
    const { startDate, endDate, transactionTypes } = inputs
    try {
      const urlData = await getLegalEntitiesTransactionsReport({
        input: {
          legalEntityUuids,
          startDate,
          endDate,
          transactionTypes,
          includeStaged: true,
        },
      })

      const s3Url = urlData?.data?.getLegalEntitiesTransactionsReportUrl?.url
      window.open(s3Url as string, '_self')
    } catch (e) {
      notify(
        'An error occurred while downloading the document. Please refresh or try again.',
        'error'
      )
    }
  }

  const [selectedLegalEntityUuids, setSelectedLegalEntityUuids] = useState<
    string[]
  >([])

  const [investments, setInvestments] = useState<
    Core_LegalEntityInvestmentInfo[]
  >([])

  useQuery(AdminGetAllInvestmentsInfoDocument, {
    onError: () => {
      notify('Failed to get investments info', 'error')
    },
    onCompleted: (data) => {
      const rawInvestments =
        data?.getAllInvestmentsInfo?.legalEntityInvestmentInfo
      const processedInvestments = rawInvestments?.map(
        (investment: Core_LegalEntityInvestmentInfo | undefined | null) => {
          const investmentCopy = {
            ...investment,
          } as Core_LegalEntityInvestmentInfo
          investmentCopy.startDateTime = new Date(
            investment?.startDateTime
          ).toLocaleDateString('en-US')

          investmentCopy.totalEquityValue = formatIntegerCents(
            investment?.totalEquityValue as number
          ) as any
          return investmentCopy
        }
      )
      setInvestments(processedInvestments as Core_LegalEntityInvestmentInfo[])
    },
  })

  const investmentNumberToInvestmentUuid: { [key: number]: string } = {}

  // iterate through investments and create a map of investment number to investment uuid
  investments?.forEach(
    (investment: Core_LegalEntityInvestmentInfo, index: number) => {
      investmentNumberToInvestmentUuid[index] =
        investment.legalEntityUuid as string
    }
  )

  const investmentTableOptions = {
    filter: false,
    download: true,
    print: false,
    viewColumns: false,
    search: true,
    sort: true,
    selectableRows: 'multiple',
    responsive: 'standard',
    elevation: 0,
    onRowSelectionChange: (__: any, allRows: any) => {
      const uuids: string[] = []

      allRows.forEach((row: { dataIndex: number }) => {
        uuids.push(investmentNumberToInvestmentUuid[row.dataIndex])
      })

      setSelectedLegalEntityUuids(uuids)
    },
  }

  return (
    <ThemeProvider theme={blueOvermoonTheme}>
      <PageContainer>
        <PageTitleContainer>
          <Typography variant="h2"> Fund Investor Reporting</Typography>
        </PageTitleContainer>
        <SubHeaderContainer>
          <GridForm
            onSubmit={(result) => {
              getLegalEntitiesTransactionsReportUrl(
                result,
                selectedLegalEntityUuids
              )
            }}
            ctaText="Submit"
            ctaBoxProps={{
              pb: '32px',
              width: '100%',
            }}
            inputConfigs={[
              {
                name: 'startDate',
                type: InputType.DatePicker,
                required: true,
                props: {
                  label: 'Select Start Date',
                },
                gridItemProps: {
                  sm: 5,
                },
              },
              {
                name: 'filler',
                type: InputType.CustomComponent,
                props: {
                  component: <div />,
                },
                gridItemProps: {
                  sm: 2,
                },
              },
              {
                name: 'endDate',
                type: InputType.DatePicker,
                required: true,
                props: {
                  label: 'Select End Date',
                },
                gridItemProps: {
                  sm: 5,
                },
              },
              {
                name: 'transactionTypes',
                type: InputType.Dropdown,
                required: true,
                defaultValue: [
                  'contribution',
                  'redemption',
                  'transfer_out',
                  'transfer_in',
                  'distribution',
                  'share_appreciation',
                  'reinvestment',
                  'adjustment',
                  'share_count_true_up',
                ],
                gridItemProps: {
                  pt: '24px',
                  pb: '24px',
                },
                props: {
                  SelectProps: {
                    multiple: true,
                  },
                  type: 'text',
                  label: 'Transaction Type',
                  fullWidth: true,
                  options: [
                    {
                      label: 'Contribution',
                      value: 'contribution',
                    },
                    {
                      label: 'Redemption',
                      value: 'redemption',
                    },
                    {
                      label: 'Transfer Out',
                      value: 'transfer_out',
                    },
                    {
                      label: 'Transfer In',
                      value: 'transfer_in',
                    },
                    {
                      label: 'Distribution',
                      value: 'distribution',
                    },
                    {
                      label: 'Share Appreciation',
                      value: 'share_appreciation',
                    },
                    {
                      label: 'Reinvestment',
                      value: 'reinvestment',
                    },
                    {
                      label: 'Adjustment',
                      value: 'adjustment',
                    },
                    {
                      label: 'Share Count True Up',
                      value: 'share_count_true_up',
                    },
                  ],
                },
              },
              {
                name: 'investmentsInput',
                type: InputType.CustomComponent,
                props: {
                  component: (
                    <DataTable
                      title=""
                      data={investments}
                      columns={investmentColumns}
                      options={investmentTableOptions as any}
                      // @ts-ignore
                      checkboxSelection
                    />
                  ),
                },
              },
            ]}
          />
        </SubHeaderContainer>

        <br />
        <br />
      </PageContainer>
    </ThemeProvider>
  )
}

export default ReportsPage
