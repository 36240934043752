import React, { useState, useMemo, ChangeEvent, useCallback } from 'react'
import {
  DataTable,
  LoadingCard,
  useSnackbar,
} from '@flock/flock-component-library'
import { styled } from '@mui/material/styles'
import { Typography, TextField, Box } from '@mui/material'
import { debounce } from 'lodash'
import { useQuery } from '@apollo/client'

import {
  AdminSearchLeadsDocument,
  Core_Lead,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { CustomLeadRowRender, leadColumns, formatLead } from './LeadRowRender'
import CreateOperatorModal from '../TaskManagement/CreateOperatorModal'
import { SALES_LEAD_MANAGEMENT_URL } from '../../constants'

const SearchWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '1rem',
})

const SearchField = styled(TextField)({
  width: '100%',
})

const PageTitleContainer = styled('div')({
  marginTop: '4rem',
  marginBottom: '3rem',
  display: 'flex',
  justifyContent: 'space-between',
})

const LeadManagementPage = () => {
  const { notify } = useSnackbar()

  let querySearch = ''
  if (typeof window !== `undefined`) {
    const { search } = window.location
    const params = new URLSearchParams(search)
    querySearch = params.get('search') || ''
    querySearch = querySearch.toLowerCase()
  }

  const options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    sort: true,
    selectableRows: 'none',
    responsive: 'standard',
    customRowRender: CustomLeadRowRender(SALES_LEAD_MANAGEMENT_URL),
    elevation: 0,
  }

  const [searchString, setSearchString] = useState(querySearch)

  const { loading, data } = useQuery(AdminSearchLeadsDocument, {
    variables: {
      searchLeadsInput: {
        searchString,
      },
    },
    onError: () => {
      notify('Failed to get leads', 'error')
    },
  })

  const leads: Core_Lead[] =
    data?.searchLeads?.leads?.map(formatLead as any) || []

  const setSearchStringToSearchFieldInput = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearchString(e.target.value.toLowerCase())
    },
    []
  )

  const debounceSetSearchStringToSearchFieldInput = useMemo(
    () => debounce(setSearchStringToSearchFieldInput, 700),
    [setSearchStringToSearchFieldInput]
  )

  return (
    <Box paddingBottom="48px">
      <PageTitleContainer>
        <Typography variant="h2">Lead Management</Typography>
        <Box>
          <CreateOperatorModal />
        </Box>
      </PageTitleContainer>
      <SearchWrapper>
        <SearchField
          placeholder="Search leads"
          defaultValue={querySearch}
          onChange={debounceSetSearchStringToSearchFieldInput}
          data-cy="leadSearchField"
        />
      </SearchWrapper>
      {loading ? (
        <LoadingCard text="Loading..." />
      ) : (
        <DataTable
          title=""
          data={leads}
          columns={leadColumns}
          options={options as any}
        />
      )}
    </Box>
  )
}

export default LeadManagementPage
