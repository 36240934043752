/* eslint-disable no-case-declarations */
import React, { Ref, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useForm, Controller, UseFormProps, useWatch } from 'react-hook-form'
import ReactInputMask from 'react-input-mask'
import { MuiPhoneNumberProps } from 'mui-phone-input-ssr'
import { ButtonProps, Collapse, TextFieldProps } from '@mui/material'
import { ChevronLeft, ExpandLess, ExpandMore } from '@mui/icons-material'
import { EditorProps } from 'react-draft-wysiwyg'
import { SxProps } from '@mui/system'

import { compileExpression } from 'filtrex'
import { EditorState } from 'draft-js'

import {
  InputConfig,
  InputType,
  MultiFormProps,
  NonInputProps,
  TextFieldOptions,
  TextWithConfirmationOptions,
} from './formTypes'
import TextField from '../TextField/TextField'
import PhoneInput from '../PhoneInput/PhoneInput'
import TextWithConfirmation from '../TextWithConfirmation/TextWithConfirmation'
import RadioSelect, { RadioSelectProps } from '../RadioSelect/RadioSelect'
import Checkbox, { CheckboxProps } from '../Checkbox/Checkbox'
import Dropdown, { DropdownProps } from '../Dropdown/Dropdown'
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker'
import Button from '../Button/Button'
import FileUpload, { FileUploadProps } from '../FileUpload/FileUpload'
import Typography from '../Typography/Typography'
import flockColors from '../flockColors/flockColors'
import EditorField, { cleanedHTML } from '../EditorField/EditorField'
import Grid from '../Grid/Grid'
import AddressAutocompleteInput from '../AddressAutocompleteInput/AddressAutocompleteInput'
import { MOBILE_BREAKPOINT } from '../constants/constants'

type RegisterInputProps = {
  onChange: () => void
  onBlur: () => void
  name: string
  inputRef: Ref<any>
}

type AddressInputProps = {
  setAddressData: () => void
  setShowError: () => void
  label: string
  googleMapsApiKey: string
  dataCy: string
}

// Extracts the inputRef from a register call via React Hook Form
// https://react-hook-form.com/migrate-v6-to-v7/
export const useRegister = (
  register: any,
  inputName: string,
  options?: any
): RegisterInputProps => {
  const { ref: inputRef, ...rest } = register(inputName, options)
  return {
    inputRef,
    ...rest,
  }
}

const FormContainer = styled(Grid)({
  minWidth: '5rem',
  width: '100%',
})

const BackButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  cursor: 'pointer',
  width: 'fit-content',
  marginLeft: '0.5rem',
  paddingLeft: 0,

  transition: 'color 0.5s ease',
  '&:hover': {
    color: flockColors.hoverBlue,
  },

  [MOBILE_BREAKPOINT]: {
    marginTop: '0rem',
    fontSize: '1.125rem',
  },
})

const InputWrapper = styled(Grid)({
  margin: '0.5rem',
  marginLeft: '1rem',
  marginRight: '1rem',
})

const CtaWrapper = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
})

const SubmitCtaWrapper = styled(CtaWrapper)({
  display: 'flex',
  flexDirection: 'column',
  margin: '1rem',
  marginBottom: 0,
  paddingTop: '2rem',
  paddingBottom: '2rem',
  position: 'sticky',
  bottom: 0,
  backgroundColor: 'white',
})

const MultiformWrapper = styled(Grid)({
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  border: `1px solid ${flockColors.lightGray}`,
  borderRadius: '1rem',
})

const StyledTypography = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

type FormProps = {
  inputConfigs: InputConfig[]
  ctaText?: string
  onSubmit: (result: any, reset?: () => void) => void
  onBack?: () => void
  ctaProps?: ButtonProps & { 'data-cy'?: string }
  stickyCta?: boolean

  globalTextFieldProps?: Partial<TextFieldProps>
  globalRadioSelectProps?: Partial<RadioSelectProps>
  globalCheckboxProps?: Partial<CheckboxProps>
  globalDatePickerProps?: Partial<DatePickerProps>
  globalDropdownProps?: Partial<DropdownProps>
  globalPhoneInputProps?: Partial<MuiPhoneNumberProps>
  globalFileUploadProps?: Partial<FileUploadProps>
  globalEditorProps?: Partial<EditorProps>
  formProps?: Partial<UseFormProps>
  inputWrapperStyle?: SxProps
  onUpdated?: (values: any) => void
}

const Form = (props: FormProps) => {
  const {
    inputConfigs,
    ctaText,
    onSubmit,
    onBack,
    ctaProps,
    stickyCta,
    globalTextFieldProps,
    globalRadioSelectProps,
    globalCheckboxProps,
    globalDatePickerProps,
    globalDropdownProps,
    globalPhoneInputProps,
    globalFileUploadProps,
    globalEditorProps,
    formProps,
    inputWrapperStyle,
    onUpdated,
  } = props

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    ...formProps,
  })

  const watchedFields = useWatch({ control })
  const [isCollapsedMap, setIsCollapsedMap] = useState<Map<any, boolean>>(
    () => new Map()
  )
  useEffect(() => {
    if (onUpdated) {
      onUpdated(watchedFields)
    }
  }, [watchedFields])
  const processResult = (result: any) => {
    const newResult = {
      ...result,
    }
    // Iterate through input configs and look for any multiform inputs
    inputConfigs.forEach((config: InputConfig) => {
      const { inputType, inputName } = config
      if (inputType === InputType.MultiForm) {
        const multiformProps = config.props as MultiFormProps
        const { inputConfigs: multiformInputConfigs } = multiformProps
        // The number of forms generated is stored in the result object as the config's input name
        const numForms = result[inputName]
        const formResults = []
        // Iterate for the number of forms generated
        for (let i = 0; i < numForms; i += 1) {
          let resultObj: any = {}
          // For each input, extract the input from the result and put it into an object alongside
          // the other inputs in the same form
          multiformInputConfigs.forEach((multiformInputConfig: InputConfig) => {
            const multiformInputName = multiformInputConfig.inputName
            resultObj[multiformInputName] = result[`${multiformInputName}${i}`]
            newResult[`${multiformInputName}${i}`] = undefined
          })
          if (
            multiformProps.customLabels &&
            multiformProps.customLabels.length === numForms
          ) {
            resultObj = {
              customLabel: multiformProps.customLabels[i],
              data: resultObj,
            }
          }
          formResults.push(resultObj)
        }
        newResult[inputName] = formResults
      }
    })

    onSubmit(newResult, reset)
  }

  const renderInput = (config: InputConfig, multiFormSuffix?: number) => {
    const {
      watchField,
      inputName,
      inputType,
      renderOn,
      gridProps,
      renderExpression,
    } = config
    if (watchField) {
      const fieldValue =
        watchedFields[
          `${watchField}${multiFormSuffix !== undefined ? multiFormSuffix : ''}`
        ]
      if (renderOn && fieldValue !== renderOn) {
        return null
      }
      if (!renderOn && (!fieldValue || fieldValue.toString() !== 'true')) {
        return null
      }
    }
    if (renderExpression) {
      const conditionalExpression = compileExpression(renderExpression)
      if (!conditionalExpression(watchedFields)) {
        return null
      }
    }
    const sharedProps = {
      name: inputName,
      inputProps: {
        'aria-label': inputName,
      },
      fullWidth: true,
      error: !!errors[inputName],
    }
    const key = `${inputName}-${inputType}`

    let renderedComponent = null

    switch (config.inputType) {
      case InputType.Text:
        const {
          type,
          pattern: validationPattern,
          helperText,
          ...textFieldProps
        } = config.props as TextFieldOptions
        let mask: string
        let pattern: RegExp | undefined = validationPattern
        switch (type) {
          case 'number':
            mask = '99999999999999999999'
            break
          case 'money':
            mask = '$9999999999999999999'
            break
          case 'name':
            pattern = /^\w.*\s.*\w/
            break
          case 'email':
            pattern = /^\S+@\S+\.\S+$/
            break
          case 'year':
            pattern = /^(19|20)\d{2}$/
            break
          default:
            break
        }

        const rules = {
          required: config.required,
          pattern,
        }

        const errorText =
          errors[config.inputName]?.message ||
          helperText ||
          'This field is required'

        if (type === 'text' || !type) {
          renderedComponent = (
            <TextField
              {...sharedProps}
              {...globalTextFieldProps}
              {...textFieldProps}
              {...useRegister(register, config.inputName, rules)}
              helperText={errors[config.inputName] && errorText}
            />
          )
        } else if (type === 'email') {
          renderedComponent = (
            <TextField
              {...sharedProps}
              {...globalTextFieldProps}
              {...textFieldProps}
              {...useRegister(register, config.inputName, rules)}
              helperText={
                errors[config.inputName] && 'Please enter a valid email'
              }
            />
          )
        } else if (type === 'name') {
          renderedComponent = (
            <TextField
              {...sharedProps}
              {...globalTextFieldProps}
              {...textFieldProps}
              {...useRegister(register, config.inputName, rules)}
              helperText={
                errors[config.inputName] && 'Please enter a first and last name'
              }
            />
          )
        } else if (type === 'year') {
          renderedComponent = (
            <TextField
              {...sharedProps}
              {...globalTextFieldProps}
              {...textFieldProps}
              {...useRegister(register, config.inputName, rules)}
              helperText={
                errors[config.inputName] &&
                'Please enter a valid year after 1899'
              }
            />
          )
        } else {
          renderedComponent = (
            <Controller
              render={({ field }) => {
                // @ts-ignore
                const { disabled } = textFieldProps
                return (
                  <ReactInputMask
                    mask={mask}
                    // @ts-ignore
                    maskChar=""
                    {...field}
                    disabled={disabled}
                  >
                    {() => (
                      <TextField
                        {...sharedProps}
                        {...globalTextFieldProps}
                        {...textFieldProps}
                        variant="outlined"
                        helperText={errors[config.inputName] && errorText}
                      />
                    )}
                  </ReactInputMask>
                )
              }}
              control={control}
              defaultValue={textFieldProps.defaultValue}
              name={config.inputName}
              rules={rules}
            />
          )
        }
        break
      case InputType.Phone:
        const phoneInputProps = config.props as MuiPhoneNumberProps
        renderedComponent = (
          <Controller
            render={({ field }) => {
              const { ref, ...otherFieldProps } = field
              return (
                <PhoneInput
                  {...sharedProps}
                  {...globalPhoneInputProps}
                  {...phoneInputProps}
                  helperText={
                    errors[config.inputName] &&
                    'Please input a valid phone number'
                  }
                  {...otherFieldProps}
                  error={!!errors[config.inputName]}
                />
              )
            }}
            control={control}
            name={config.inputName}
            defaultValue={phoneInputProps.defaultValue}
            rules={{
              required: config.required,
              pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
            }}
          />
        )
        break
      case InputType.Radio:
        const radioSelectProps = config.props as RadioSelectProps
        renderedComponent = (
          <RadioSelect
            {...sharedProps}
            {...globalRadioSelectProps}
            {...radioSelectProps}
            RadioProps={{
              ...radioSelectProps.RadioProps,
              ...useRegister(register, config.inputName, {
                required: config.required,
              }),
            }}
            helperText={errors[config.inputName] && 'This field is required'}
          />
        )
        break
      case InputType.Address:
        const addressProps = config.props as AddressInputProps
        renderedComponent = (
          <>
            <StyledTypography variant="body2">
              {addressProps.label}
            </StyledTypography>
            <AddressAutocompleteInput
              googleMapsApiKey={addressProps.googleMapsApiKey}
              autocompleteStyle={{}}
              textFieldProps={{
                variant: 'outlined',
                sx: {
                  width: '100%',
                },
                // @ts-ignore
                'data-cy': addressProps.dataCy,
                ...useRegister(register, config.inputName, {
                  required: config.required,
                }),
              }}
              setAddressData={addressProps.setAddressData}
              setShowError={addressProps.setShowError}
            />
          </>
        )
        break
      case InputType.Checkbox:
        const checkboxProps = config.props as CheckboxProps
        renderedComponent = (
          <Checkbox
            {...sharedProps}
            {...globalCheckboxProps}
            {...checkboxProps}
            {...useRegister(register, config.inputName)}
            checked={watchedFields[sharedProps.name]}
          />
        )
        break
      case InputType.Dropdown:
        const dropdownProps = config.props as DropdownProps
        renderedComponent = (
          <Controller
            name={config.inputName}
            control={control}
            render={({ field }) => {
              const { ref, ...otherFieldProps } = field
              return (
                <Dropdown
                  {...sharedProps}
                  {...globalDropdownProps}
                  {...dropdownProps}
                  {...otherFieldProps}
                />
              )
            }}
            defaultValue={
              dropdownProps.defaultValue || dropdownProps.options[0].value
            }
            rules={{
              required: config.required,
            }}
          />
        )
        break
      case InputType.DatePicker:
        const datePickerProps = config.props as DatePickerProps
        renderedComponent = (
          <Controller
            name={config.inputName}
            control={control}
            render={({ field }) => {
              const { ref, ...otherFieldProps } = field
              return (
                <DatePicker
                  {...sharedProps}
                  {...globalDatePickerProps}
                  {...datePickerProps}
                  helperText={
                    errors[config.inputName] && 'This field is required'
                  }
                  {...otherFieldProps}
                />
              )
            }}
            defaultValue={datePickerProps.defaultValue}
            rules={{
              required: config.required,
            }}
          />
        )
        break
      case InputType.FileUpload:
        const fileUploadProps = config.props as FileUploadProps
        renderedComponent = (
          <Controller
            name={config.inputName}
            control={control}
            render={({ field }) => {
              const { ref, ...otherFieldProps } = field
              return (
                <FileUpload
                  {...globalFileUploadProps}
                  {...sharedProps}
                  {...fileUploadProps}
                  {...otherFieldProps}
                  helperText={
                    errors[config.inputName] && 'This field is required'
                  }
                />
              )
            }}
            rules={{
              required: config.required,
            }}
          />
        )
        break
      case InputType.Editor:
        const editorProps = config.props as EditorProps

        const setFormValue = (editorState: EditorState) => {
          setValue(config.inputName, cleanedHTML(editorState))
        }
        editorProps.onEditorStateChange = setFormValue
        renderedComponent = (
          <div>
            <EditorField
              {...globalEditorProps}
              {...editorProps}
              {...useRegister(register, config.inputName, {
                required: config.required,
              })}
            />
          </div>
        )
        break
      case InputType.NonInput:
        const nonInputProps = config.props as NonInputProps
        renderedComponent = nonInputProps.component
        break
      case InputType.TextWithConfirmation:
        const innerTextFieldProps = config.props as TextWithConfirmationOptions
        renderedComponent = (
          <Controller
            name={config.inputName}
            control={control}
            rules={{ required: config.required }}
            render={({ field }) => {
              const { ref, ...otherFieldProps } = field
              return (
                <TextWithConfirmation
                  {...globalTextFieldProps}
                  {...sharedProps}
                  {...otherFieldProps}
                  {...innerTextFieldProps}
                  helperText={
                    errors[config.inputName] && innerTextFieldProps.helperText
                  }
                />
              )
            }}
          />
        )
        break
      default:
        return null
    }
    return (
      <InputWrapper
        key={key}
        item
        xs={12}
        {...gridProps}
        sx={inputWrapperStyle}
      >
        {renderedComponent}
      </InputWrapper>
    )
  }

  return (
    <FormContainer container>
      {onBack && (
        <BackButton onClick={onBack}>
          <ChevronLeft fontSize="large" />
          Back
        </BackButton>
      )}
      {inputConfigs.map((config) => {
        const { inputName, inputType, watchField, renderOn, renderExpression } =
          config
        if (watchField) {
          const fieldValue = watchedFields[watchField]
          if (renderOn && fieldValue !== renderOn) {
            return null
          }
          if (!renderOn && (!fieldValue || fieldValue.toString() !== 'true')) {
            return null
          }
        }
        if (renderExpression) {
          const conditionalExpression = compileExpression(renderExpression)
          if (!conditionalExpression(watchedFields)) {
            return null
          }
        }
        if (inputType === InputType.MultiForm) {
          const numForms = watchedFields[inputName]
          const multiformProps = config.props as MultiFormProps
          const {
            inputConfigs: multiformInputConfigs,
            incrementText,
            decrementText,
            label,
            minForms,
            maxForms,
            customLabels,
            collapse,
          } = multiformProps
          const renderedForms = []
          for (let i = 0; i < numForms; i += 1) {
            renderedForms.push(
              <MultiformWrapper key={`${config.inputName}${i}`} container>
                <InputWrapper item xs={12}>
                  <Typography variant="h4">
                    {customLabels && customLabels?.length === numForms
                      ? customLabels[i]
                      : `${label} ${numForms > 1 ? i + 1 : ''}`}
                  </Typography>
                </InputWrapper>
                {multiformInputConfigs.map((multiformConfig) =>
                  renderInput(
                    {
                      ...multiformConfig,
                      inputName: `${multiformConfig.inputName}${i}`,
                    },
                    i
                  )
                )}
              </MultiformWrapper>
            )
          }
          return !collapse ? (
            <>
              {renderedForms}
              {(!maxForms || (maxForms && numForms < maxForms)) && (
                <CtaWrapper item xs={12} sx={inputWrapperStyle}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setValue(config.inputName, numForms + 1)
                    }}
                  >
                    {incrementText}
                  </Button>
                </CtaWrapper>
              )}
              {numForms > minForms && (
                <CtaWrapper
                  sx={{ marginTop: 0, ...inputWrapperStyle }}
                  item
                  xs={12}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      setValue(config.inputName, numForms - 1)
                    }}
                  >
                    {decrementText}
                  </Button>
                </CtaWrapper>
              )}
            </>
          ) : (
            <InputWrapper
              item
              xs={12}
              sx={{
                outlineColor: 'gray',
                outlineWidth: '.5px',
                outlineStyle: 'solid',
                padding: '5px',
                paddingLeft: '10px',
                paddingRight: '10px',
              }}
            >
              <span
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="h3">{label}</Typography>
                {isCollapsedMap.get(inputName) ? (
                  <ExpandMore
                    onClick={() =>
                      setIsCollapsedMap(
                        (prevOpenMap) =>
                          // @ts-ignore
                          new Map([...prevOpenMap, [inputName, false]])
                      )
                    }
                  />
                ) : (
                  <ExpandLess
                    onClick={() =>
                      setIsCollapsedMap(
                        (prevOpenMap) =>
                          // @ts-ignore
                          new Map([...prevOpenMap, [inputName, true]])
                      )
                    }
                  />
                )}
              </span>
              <Collapse in={!isCollapsedMap.get(inputName)}>
                {renderedForms}
                {(!maxForms || (maxForms && numForms < maxForms)) && (
                  <CtaWrapper item xs={12} sx={inputWrapperStyle}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setValue(config.inputName, numForms + 1)
                      }}
                    >
                      {incrementText}
                    </Button>
                  </CtaWrapper>
                )}
                {numForms > minForms && (
                  <CtaWrapper
                    sx={{ marginTop: 0, ...inputWrapperStyle }}
                    item
                    xs={12}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setValue(config.inputName, numForms - 1)
                      }}
                    >
                      {decrementText}
                    </Button>
                  </CtaWrapper>
                )}
              </Collapse>
            </InputWrapper>
          )
        }
        return renderInput(config)
      })}

      {stickyCta ? (
        <SubmitCtaWrapper item xs={12} sx={inputWrapperStyle}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(processResult)}
            {...ctaProps}
          >
            {ctaText}
          </Button>
        </SubmitCtaWrapper>
      ) : (
        <CtaWrapper item xs={12} sx={inputWrapperStyle}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit(processResult)}
            {...ctaProps}
          >
            {ctaText}
          </Button>
        </CtaWrapper>
      )}
    </FormContainer>
  )
}

Form.defaultProps = {
  onBack: undefined,
  ctaText: 'Submit',
  ctaProps: {},
  stickyCta: false,
  globalTextFieldProps: {},
  globalRadioSelectProps: {},
  globalCheckboxProps: {},
  globalDatePickerProps: {},
  globalDropdownProps: {},
  globalPhoneInputProps: {},
  globalFileUploadProps: {},
  globalEditorProps: {},
  formProps: {},
  inputWrapperStyle: {},
  onUpdated: undefined,
}

export default Form
