import React from 'react'
import { Typography, CircularProgress, styled } from '@mui/material'
import { MOBILE_BREAKPOINT } from '@flock/flock-component-library'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2rem',
  height: '50vh',

  [MOBILE_BREAKPOINT]: {
    height: 'calc(100vh - 11rem)',
  },
})

const LoadingWrapper = styled('div')({
  paddingTop: '2rem',
})

type LoadingCardProps = {
  text: string
}

const LoadingCard = (props: LoadingCardProps) => {
  const { text } = props
  return (
    <Container>
      <Typography variant="h2" align="center">
        {text}
      </Typography>
      <LoadingWrapper>
        <CircularProgress />
      </LoadingWrapper>
    </Container>
  )
}

export default LoadingCard
