import React from 'react'
import MuiCircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import flockColors from '../flockColors/flockColors'

const StyledCircularProgress = styled(MuiCircularProgress)({
  color: flockColors.blue,
})

const CircularProgress = (props: CircularProgressProps) => (
  <StyledCircularProgress {...props} />
)

export default CircularProgress
