/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const GET_TASKS = gql`
  query AdminGetTasks($searchTasksInput: Core_GetTasksByRequestInput!) {
    searchTasks(input: $searchTasksInput) {
      tasks {
        uuid
        operatorUuid
        type
        description
        sourceUuid
        requestData
        status
        createdAt
        startedAt
        completedAt
        slaDeadline
      }
    }
  }
`

export const GET_TASK = gql`
  query AdminGetTask($input: Core_GetTaskRequestInput!) {
    task(input: $input) {
      task {
        uuid
        operatorUuid
        type
        description
        requestData
        resolutionData
        status
        startedAt
        slaDeadline
        completedAt
      }
    }
  }
`
