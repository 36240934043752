import React from 'react'
import { TableCell, TableRow, styled } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { flockColors } from '@flock/flock-component-library'
import {
  Core_OrderV3,
  Core_OrderV3PropertyContribution,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskStatus,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { navigate } from 'gatsby'
import { filterOrderV3Tasks } from '@flock/utils'
import { ORDER_ONBOARDING_URL } from '../../constants'
import {
  AddressRender,
  OrderMetadata,
  SubscriberNameRender,
} from './OrderV3Utils'

const CenteredContent = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'row',
})

const HoveredTableCell = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

export const orderColumns = [
  { name: 'uuid', label: 'UUID', options: { display: false } },
  { name: 'legalEntityUuid', label: 'Subscriber Name' },
  { name: 'contributions', label: 'Properties' },
  { name: 'targetCloseDate', label: 'Closing Date' },
  {
    name: 'propertyQuestionnairesCompleted',
    label: "Property Questionnaire's Completed",
  },
  { name: 'inspectionsScheduled', label: 'Inspections Scheduled' },
  { name: 'titlesClosed', label: 'Titles Closed' },
  { name: 'onboardingEmailSent', label: 'Onboarding Email Sent' },
  { name: 'subDocsSent', label: 'SubDocs Sent' },
  { name: 'createdAt', label: 'CreatedAt', options: { display: false } },
  { name: '', label: '' },
]

export const createOrderRows = (orders: Core_OrderV3[]) =>
  orders.map((order: Core_OrderV3) => ({
    uuid: order.uuid,
    // find the legal entity uuid from the list of legal entity where one authorized signer is primary
    legalEntityUuid:
      order.orderLegalEntities.find((legalEntity) =>
        legalEntity.authorizedSigners.find(
          (authorizedSigner) => authorizedSigner.isPrimary
        )
      )?.legalEntityUuid || order.orderLegalEntities[0]?.legalEntityUuid,
    createdAt: order.createdAt,
    contributions: order.orderPropertyContributions.map(
      (contribution: Core_OrderV3PropertyContribution) => ({
        uuid: contribution.propertyContributionUuid,
        addressUuid: contribution.addressUuid,
      })
    ),
    targetCloseDate: order.targetCloseDate,
    propertyQuestionnairesCompleted: `${
      filterOrderV3Tasks(order, {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeyPropertyQuestionnaireCompletedPropertyContribution,
        ],
        statuses: [Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted],
      }).length
    } / ${order.orderPropertyContributions.length}`,
    inspectionsScheduled: `${
      filterOrderV3Tasks(order, {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeyInspectionScheduledPropertyContribution,
        ],
        statuses: [Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted],
      }).length
    } / ${order.orderPropertyContributions.length}`,
    onboardingEmailSent:
      filterOrderV3Tasks(order, {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeyOnboardingEmailSentLegalEntity,
        ],
        statuses: [Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted],
      }).length === 1,
    subDocsSent:
      filterOrderV3Tasks(order, {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSentLegalEntity,
        ],
        statuses: [Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted],
      }).length === 1,
    titlesClosed: `${
      filterOrderV3Tasks(order, {
        keys: [
          Core_OrderV3TaskKey.OrderV3TaskKeyOwnerTitleInsurancePreparedPropertyContribution,
        ],
        statuses: [Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted],
      }).length
    } / ${order.orderPropertyContributions.length}`,
  }))

const StyledTableCells = styled(TableCell)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const StyledTableRows = styled(TableRow)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: flockColors.lighterGray,
    transition: 'background-color 0.5s ease',
  },
})

const getValueForCompletionCheck = (completed: boolean) => {
  let displayValue
  switch (completed) {
    case true:
      displayValue = <div>&#9989;</div>
      break
    case false:
      displayValue = <div>&#10060;</div>
      break
    default:
      displayValue = 'N/A'
  }
  return displayValue
}

const OrderV3RowRender =
  (
    openDeleteOrder: (existingOrder: OrderMetadata) => void,
    openIndividualOrderV3Modal: (existingOrder: OrderMetadata) => void
  ) =>
  (
    data: [
      string,
      string,
      Core_OrderV3PropertyContribution[],
      string,
      string,
      string,
      string,
      boolean,
      boolean,
      string
    ]
  ) => {
    const [
      uuid,
      legalEntityUuid,
      contributions,
      targetCloseDate,
      propertyQuestionnairesCompleted,
      inspectionsScheduled,
      titlesClosed,
      onboardingEmailSent,
      subDocsSent,
      createdAt,
    ] = data

    const closeDate = new Date(targetCloseDate)
    const order = {
      uuid,
      legalEntityUuid,
      createdAt,
    }

    return (
      <StyledTableRows
        onClick={() => {
          openIndividualOrderV3Modal(order)
        }}
        key={uuid}
      >
        <StyledTableCells
          onClick={() => {
            openIndividualOrderV3Modal(order)
          }}
        >
          <CenteredContent>
            <SubscriberNameRender legalEntityUuid={legalEntityUuid} />
          </CenteredContent>
        </StyledTableCells>
        <TableCell>
          <CenteredContent>
            {contributions.map(
              (propertyContribution: Core_OrderV3PropertyContribution) => (
                <AddressRender
                  addressUuid={propertyContribution.addressUuid}
                  key={propertyContribution.addressUuid}
                />
              )
            )}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>
            {closeDate.toLocaleDateString('en-US')}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{propertyQuestionnairesCompleted}</CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{inspectionsScheduled}</CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>{titlesClosed}</CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>
            {getValueForCompletionCheck(onboardingEmailSent)}
          </CenteredContent>
        </TableCell>
        <TableCell>
          <CenteredContent>
            {getValueForCompletionCheck(subDocsSent)}
          </CenteredContent>
        </TableCell>
        <HoveredTableCell
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            openDeleteOrder(order)
          }}
        >
          <DeleteOutlineIcon />
        </HoveredTableCell>
        <HoveredTableCell
          align="right"
          onClick={() => {
            navigate(`${ORDER_ONBOARDING_URL}/${uuid}`)
          }}
          style={{ cursor: 'pointer' }}
        >
          <ArrowForwardIos />
        </HoveredTableCell>
      </StyledTableRows>
    )
  }

export default OrderV3RowRender
