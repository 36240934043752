import React from 'react'
import MuiPaper, { PaperProps } from '@mui/material/Paper'
import { styled } from '@mui/material/styles'

const StyledPaper = styled(MuiPaper)({
  borderRadius: '5px',
})

const Paper = (props: PaperProps) => <StyledPaper {...props} />

export default Paper
