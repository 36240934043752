import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import React, { useState } from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import { styled } from '@mui/material/styles'

const libraries = ['places'] as 'places'[]

type AddressData = {
  formattedAddress: string
  streetAddress: string
  streetNumber: string
  city: string
  state: string
  zipcode: string
  county: string
  lat: number
  lng: number
}

type AddressInputProps = {
  setAddressData: (addressData: AddressData | null) => void
  setShowError: (showError: boolean) => void
  autocompleteStyle?: any
  textFieldProps: TextFieldProps
  googleMapsApiKey: string
}

const StyledAutocomplete = styled(Autocomplete)({})

const AddressAutocompleteInput = (props: AddressInputProps) => {
  const {
    setAddressData,
    setShowError,
    autocompleteStyle,
    textFieldProps,
    googleMapsApiKey,
  } = props
  const [autocomplete, setAutocomplete] = useState<any>()
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey || '',
    libraries,
  })

  const onAutocompleteLoad = (ac: any) => {
    setAutocomplete(ac)
  }

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace()

    if (!place?.address_components) {
      setAddressData(null)
      setShowError(true)
      return
    }

    let city
    let zipcode
    let state
    let county
    let streetNumber
    let streetAddress
    let country

    for (let i = 0; i < place.address_components.length; i += 1) {
      // https://googlemaps.github.io/google-maps-services-java/v0.1.3/javadoc/com/google/maps/model/AddressType.html
      const currentComponent = place.address_components[i]

      if (currentComponent.types.indexOf('locality') !== -1) {
        city = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('postal_code') !== -1) {
        zipcode = currentComponent.long_name
      }
      if (
        currentComponent.types.indexOf('administrative_area_level_1') !== -1
      ) {
        state = currentComponent.short_name
      }
      if (
        currentComponent.types.indexOf('administrative_area_level_2') !== -1
      ) {
        county = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('street_number') !== -1) {
        streetNumber = currentComponent.long_name
      }
      if (currentComponent.types.indexOf('route') !== -1) {
        streetAddress = currentComponent.short_name
      }
      if (currentComponent.types.indexOf('country') !== -1) {
        country = currentComponent.short_name
      }
    }
    if (country === 'US' && /\d/.test(place.name)) {
      const addressData = {
        streetAddress,
        formattedAddress: place.formatted_address,
        city,
        zipcode,
        state,
        county,
        streetNumber,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }

      setShowError(false)
      setAddressData(addressData as AddressData)
    } else {
      setAddressData(null)
      setShowError(true)
    }
  }

  return (
    <>
      {!isLoaded ? (
        <div>
          <div>
            <TextField
              placeholder={"Enter your property's address..."}
              InputProps={{
                disableUnderline: true,
              }}
              aria-label="Enter your property's address"
              {...textFieldProps}
            />
          </div>
        </div>
      ) : (
        <>
          <StyledAutocomplete
            onLoad={onAutocompleteLoad}
            onPlaceChanged={onPlaceChanged}
            types={['address']}
            sx={autocompleteStyle}
          >
            <TextField
              placeholder={"Enter your property's address..."}
              InputProps={{
                disableUnderline: true,
                // @ts-ignore
                'data-cy': 'addressInput',
              }}
              aria-label="Enter your property's address"
              {...textFieldProps}
            />
          </StyledAutocomplete>
        </>
      )}
    </>
  )
}

export default AddressAutocompleteInput

AddressAutocompleteInput.defaultProps = {
  autocompleteStyle: null,
}
