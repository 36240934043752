// eslint-disable-next-line import/prefer-default-export
export const formatPhoneNumber = (phoneNumberString: string): string => {
  const cleaned = phoneNumberString.replace(/\D/g, '')
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    const intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return ''
}

export const prettyToInternationalPhoneNumber = (phoneNumber: string) =>
  `+1${phoneNumber.replace(/\D/g, '')}`

export const formatPercentage = (
  n: number,
  decimalPlaces: number = 0
): string => {
  const wholeNumberPercentUnrounded = n * 100
  const temporaryFactorForRounding = 10 ** decimalPlaces
  const wholeNumberPercentRounded =
    Math.round(wholeNumberPercentUnrounded * temporaryFactorForRounding) /
    temporaryFactorForRounding
  return `${wholeNumberPercentRounded.toFixed(decimalPlaces)}%`
}

/*
 Converts all words in the string to title case
 https://stackoverflow.com/a/196991/13877993
 undefined/null => ''
 '' => ''
  'hello' => 'Hello'
  'hello world' => 'Hello World'
  'HELLO WORLD' => 'Hello World'
  'jim-bob' => 'Jim-bob'
  "don't" => "Don't"
*/
export const titleCase = (word: string): string => {
  if (!word) return ''
  return word.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}
