import {
  Box,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { startCase } from 'lodash'
import { SelectableRows, Responsive } from 'mui-datatables'
import DataTable from '../DataTable/DataTable'
import OpacityLink from '../OpacityLink/OpacityLink'
import BaseModal from '../BaseModal/BaseModal'
import Grid from '../Grid/Grid'
import Typography from '../Typography/Typography'
import { MOBILE_BREAKPOINT } from '../constants'

type JsonMap = {
  [key: string]: any
}

const formatDisplayValue = (value: any) => {
  let formattedValue = '-'
  if (typeof value === 'number') {
    formattedValue = value.toLocaleString()
  } else if (typeof value === 'string') {
    if (!Number.isNaN(Date.parse(value))) {
      formattedValue = new Date(value).toLocaleString()
    } else {
      formattedValue = value
    }
  } else if (value || value === false) {
    formattedValue = value.toString()
  }
  return formattedValue
}

const StyledTableCell = styled(TableCell)({
  paddingTop: '0.25rem',
  paddingBottom: '0.25rem',
  paddingLeft: 0,
})

export type AuditTableEntry = {
  uuid: string
  eventName: string
  cumulativeEquityValue: string
  tableName: string
  operation: string
  previousValue: JsonMap
  updatedValue: JsonMap
  userEntity: string
  userEntityType: string
  createdAt: string
}

const tableOptions = (customRowRender: (data: any) => any) => ({
  filter: false,
  download: false,
  print: false,
  viewColumns: false,
  rowsPerPageOptions: [],
  search: false,
  sort: true,
  selectableRows: 'none' as SelectableRows,
  responsive: 'simple' as Responsive,
  customRowRender,
  elevation: 0,
})

type DetailItemProps = {
  label: string
  children: React.ReactNode
}

const DetailItem = (props: DetailItemProps) => {
  const { label, children } = props

  return (
    <Grid item xs={12}>
      <Typography sx={{ fontWeight: 'bold' }}>{label}</Typography>
      <Typography>{children}</Typography>
    </Grid>
  )
}

type DetailTableProps = {
  entry: AuditTableEntry
  fetchUserName: (entityUuid: string, entityType: string) => Promise<string>
}

const DetailTable = (props: DetailTableProps) => {
  const { entry, fetchUserName } = props
  const {
    eventName,
    previousValue,
    updatedValue,
    userEntity,
    userEntityType,
    createdAt,
  } = entry
  const [userName, setUserName] = useState('')
  const [loading, setLoading] = useState(false)
  const [showRaw, setShowRaw] = useState(false)

  const fetchUser = async () => {
    setLoading(true)
    const name = await fetchUserName(userEntity, userEntityType)
    setUserName(name)
    setLoading(false)
  }

  useEffect(() => {
    fetchUser()
  }, [entry])

  const keys = new Set<string>()
  Object.keys(previousValue).forEach((key: string) => {
    keys.add(key)
  })
  Object.keys(updatedValue).forEach((key: string) => {
    keys.add(key)
  })

  const keyArray = Array.from(keys)
  const formattedDateTime = new Date(createdAt).toLocaleString()

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        sx={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}
      >
        <DetailItem label="Event Name">{eventName}</DetailItem>
        <DetailItem label="Initiated By">
          {loading ? <CircularProgress size={18} /> : userName}
        </DetailItem>
        <DetailItem label="Event Time">{formattedDateTime}</DetailItem>
        <DetailItem label="">
          <Table>
            <TableHead>
              <StyledTableCell>
                <Typography fontWeight="bold">Field</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight="bold">Previous Value</Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography fontWeight="bold">Updated Value</Typography>
              </StyledTableCell>
            </TableHead>
            {keyArray.map((key: string) => {
              const oldValue = formatDisplayValue(previousValue[key])
              const newValue = formatDisplayValue(updatedValue[key])

              return (
                <TableRow>
                  <StyledTableCell>
                    <Typography fontWeight="bold">{startCase(key)}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>{oldValue}</Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography>{newValue}</Typography>
                  </StyledTableCell>
                </TableRow>
              )
            })}
          </Table>
        </DetailItem>
      </Grid>

      <Typography>
        <OpacityLink onClick={() => setShowRaw(!showRaw)}>
          Show Raw Data
        </OpacityLink>
      </Typography>

      {showRaw && (
        <Typography>
          <pre>{JSON.stringify(entry, undefined, 2)}</pre>
        </Typography>
      )}
    </>
  )
}

const AuditLogRowRenderer =
  (
    onOpenDetails: (entry: AuditTableEntry) => void,
    showDetails: boolean,
    isTransactionRows: boolean
  ) =>
  (
    data: [
      string,
      string,
      string,
      string,
      string,
      JsonMap,
      JsonMap,
      string,
      string,
      string
    ]
  ) => {
    const [
      uuid,
      eventName,
      cumulativeEquityValue,
      tableName,
      operation,
      previousValue,
      updatedValue,
      userEntity,
      userEntityType,
      createdAt,
    ] = data
    const formattedEntityType = userEntityType === 'operator' ? 'Admin' : 'User'

    const entry = {
      uuid,
      eventName,
      cumulativeEquityValue,
      tableName,
      operation,
      previousValue,
      updatedValue,
      userEntity,
      userEntityType,
      createdAt,
    }

    const formattedDateTime = new Date(createdAt).toLocaleString()

    return (
      <TableRow>
        <TableCell>{eventName}</TableCell>
        {isTransactionRows && (
          <TableCell data-cy="auditLogEquityValue">
            {cumulativeEquityValue}
          </TableCell>
        )}
        <TableCell>{formattedEntityType}</TableCell>
        <TableCell>{formattedDateTime}</TableCell>
        {showDetails && (
          <TableCell>
            <OpacityLink onClick={() => onOpenDetails(entry)}>
              Details
            </OpacityLink>
          </TableCell>
        )}
      </TableRow>
    )
  }

type AuditLogTableProps = {
  auditLogEntries: AuditTableEntry[]
  // fetchOperator will retrieve the operator name
  fetchUserName: (entityUuid: string, entityType: string) => Promise<string>
  showDetails: boolean
  isTransaction: boolean
}

const AuditLogTable = (props: AuditLogTableProps) => {
  const { auditLogEntries, fetchUserName, showDetails, isTransaction } = props
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT)
  const auditLogColumns = [
    { name: 'uuid', options: { display: false } },
    { name: 'eventName', label: 'Event' },
    { name: 'cumulativeEquityValue', label: 'Cumulative Equity Value' },
    { name: 'tableName', options: { display: false } },
    { name: 'operation', options: { display: false } },
    { name: 'previousValue', options: { display: false } },
    { name: 'updatedValue', options: { display: false } },
    { name: 'userEntity', options: { display: false } },
    { name: 'userEntityType', label: 'Initiator' },
    { name: 'createdAt', label: 'Time' },
    { name: '', label: '', options: !showDetails ? { display: false } : {} },
  ]

  const equityValueIndex = auditLogColumns.findIndex(
    (element) => element.name === 'cumulativeEquityValue'
  )
  if (!isTransaction) {
    auditLogColumns[equityValueIndex].options = { display: false }
  } else {
    auditLogColumns[equityValueIndex].options = undefined
  }

  const [detailModalOpen, setDetailModalOpen] = useState(false)
  const [selectedLog, setSelectedLog] = useState<AuditTableEntry>()

  const onOpenDetails = (entry: AuditTableEntry) => {
    setSelectedLog(entry)
    setDetailModalOpen(true)
  }

  return (
    <Box
      sx={{
        thead: {
          display: 'table-header-group !important',
        },
        td: {
          display:
            isMobile && auditLogEntries.length === 0 ? 'revert' : 'table-cell',
        },
      }}
    >
      <DataTable
        title=""
        data={auditLogEntries}
        columns={auditLogColumns}
        options={tableOptions(
          AuditLogRowRenderer(onOpenDetails, showDetails, isTransaction)
        )}
      />
      <BaseModal
        title="Event Details"
        open={detailModalOpen}
        onClose={() => setDetailModalOpen(false)}
      >
        <DetailTable
          entry={selectedLog as AuditTableEntry}
          fetchUserName={fetchUserName}
        />
      </BaseModal>
    </Box>
  )
}

export default AuditLogTable
