import React, { useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import {
  EditorState,
  RichUtils,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from 'draft-js'
import DOMPurify from 'dompurify'
import draftToHtml from 'draftjs-to-html'

import Typography from '../Typography/Typography'

export function cleanedHTML(editorState: EditorState) {
  return DOMPurify.sanitize(
    draftToHtml(convertToRaw(editorState.getCurrentContent())),
    { USE_PROFILES: { html: true } }
  )
}

interface EditorWithDefault extends EditorProps {
  defaultValue?: string | undefined
}

const EditorField = (props: EditorWithDefault) => {
  // toolbar style not needed for now
  const { editorStyle, onEditorStateChange, defaultValue } = props

  // require on change function
  if (!onEditorStateChange) {
    return null
  }

  let defaultEditorState = EditorState.createEmpty()
  // @ts-ignore
  if (defaultValue) {
    try {
      // @ts-ignore
      const blocksFromHTML = convertFromHTML(defaultValue)
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      defaultEditorState = EditorState.createWithContent(content)
    } catch (e) {
      console.error(e)
    }
  }

  const [editorState, setEditorState] = useState(defaultEditorState)

  const onEditorStateAndValueChange = (state: EditorState) => {
    // This changes value in Form
    onEditorStateChange(state)
    // This changes state
    setEditorState(state)
  }

  // treats enter as line break <br /> rather than <p>
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleReturn = (_: any, editorState: EditorState) => {
    onEditorStateAndValueChange(RichUtils.insertSoftNewline(editorState))
    return true
  }
  // TODO: handle pasting new lines correctly with handlePastedText prop

  return (
    <>
      {/** @ts-ignore */}
      <Editor
        editorState={editorState}
        editorStyle={editorStyle}
        onEditorStateChange={onEditorStateAndValueChange}
        handleReturn={handleReturn}
      />
      <Typography variant="h4" marginTop="10px">
        Editor Preview
      </Typography>
      <div
        className="sanitized-html"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: cleanedHTML(editorState) }} // should be safe from xss attack due to sanitization
      />
    </>
  )
}

EditorField.defaultProps = {
  defaultValue: null,
}

export default EditorField
