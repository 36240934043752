import React from 'react'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminDeleteLegalEntityDocument,
  AdminGetLegalEntityDocument,
  AdminSearchLegalEntitiesDocument,
  Core_LegalEntity,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { useMutation } from '@apollo/client'
import {
  Modal,
  Table,
  styled,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
} from '@mui/material'

const ModalWrapper = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '35rem',
  maxHeight: '75vh',
  overflowY: 'scroll',
  padding: '2rem',
})

const ButtonWrapper = styled('div')({
  marginTop: '1rem',
  display: 'flex',
  justifyContent: 'flex-end',
})

const CancelButton = styled(Button)({
  marginRight: '0.5rem',
})

type EditLegalEntityModalParams = {
  legalEntity: Core_LegalEntity | undefined
  isOpen: boolean
  close: () => void
}

const DeleteLegalEntityModal = (props: EditLegalEntityModalParams) => {
  const { legalEntity, isOpen, close } = props
  const { notify } = useSnackbar()
  const handleClose = () => {
    close()
  }

  const [deleteLegalEntity, { loading }] = useMutation(
    AdminDeleteLegalEntityDocument
  )

  const onSubmit = async () => {
    const deleteLegalEntityInput = {
      legalEntityUuid: legalEntity?.uuid as string,
    }
    try {
      await deleteLegalEntity({
        variables: {
          deleteLegalEntityInput,
        },
        refetchQueries: [
          AdminGetLegalEntityDocument,
          AdminSearchLegalEntitiesDocument,
        ],
      })
      notify('Successfully deleted legal entity', 'success')
      close()
    } catch (e) {
      notify('Failed to delete legal entity', 'error')
    }
  }
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalWrapper>
        <Typography
          variant="h2"
          sx={{ paddingLeft: '1rem', paddingBottom: '1rem' }}
        >
          Delete Legal Entity
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{legalEntity?.name}</TableCell>
              <TableCell>{legalEntity?.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <ButtonWrapper>
          <CancelButton color="error" onClick={handleClose}>
            Cancel
          </CancelButton>
          <Button variant="secondary" onClick={onSubmit}>
            {loading ? 'Deleting...' : 'Delete Legal Entity'}
          </Button>
        </ButtonWrapper>
      </ModalWrapper>
    </Modal>
  )
}

export default DeleteLegalEntityModal
