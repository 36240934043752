import { Box, Button, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import PageWrapper from '../../shared/PageWrapper'
import useDistributionsManagementPage from './useDistributionsManagementPage'

const DistributionsManagementPage = () => {
  const { generateBankAccountsCsv, loading } = useDistributionsManagementPage()
  return (
    <PageWrapper title="Distributions Management">
      <Typography>
        Use the button below to download the bank account information for every
        owner in the fund.
      </Typography>
      <Box>
        <Button
          variant="primary"
          size="small"
          onClick={generateBankAccountsCsv}
        >
          {loading ? <CircularProgress size="24px" /> : 'Download'}
        </Button>
      </Box>
    </PageWrapper>
  )
}

export default DistributionsManagementPage
