import React from 'react'
import { flockTheme } from '../../theme'
import { TrackedLink } from '../TrackedLink'
import { TrackedLinkProps } from '../TrackedLink/TrackedLink'

const NavLink = (props: TrackedLinkProps) => {
  const { to, color } = props

  return (
    <TrackedLink
      sx={{
        mt: {
          xs: '16px',
          sm: '16px',
          md: '16px',
        },
        width: '100%',
        textAlign: 'left',
        display: 'block',
        color: color || flockTheme.palette.primary.main,
        '&:first-child': {
          mt: 0,
        },
        fontWeight:
          to && window?.location?.href?.includes(to) ? '600' : 'normal',
      }}
      variant="cta2"
      {...props}
    />
  )
}

export default NavLink
