import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { IconButton, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import { styled } from '@mui/material/styles'
import flockColors from '../flockColors/flockColors'
import Typography from '../Typography/Typography'

const Wrapper = styled('div')({
  fontFamily: 'Inter',
})

const FieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const ErrorText = styled(Typography)({
  color: flockColors.red,
  fontSize: '0.75rem',
})

const DragAndDropDiv = styled('div')({
  background: flockColors.lighterGray,
  color: flockColors.darkGray,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '5rem',
  marginBottom: '1rem',
  border: `0.125rem dashed ${flockColors.darkGray}`,
  cursor: 'pointer',
})

export enum FileUploadFileType {
  CSV = '.csv',
  PNG = '.png',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PDF = '.pdf',
  DOCX = '.docx',
  DOC = '.doc',
  XLS = '.xls',
  XLSX = '.xlsx',
  TXT = '.txt',
}

export type FileUploadProps = {
  onChange: (files: Array<File>) => void
  onBlur: () => void
  label?: string
  helperText?: string
  accept?: FileUploadFileType | FileUploadFileType[]
  defaultFiles?: File[]
}

const FileUpload = (props: FileUploadProps) => {
  const { onChange, onBlur, label, helperText, accept, defaultFiles } = props
  const [files, setFiles] = useState<File[]>(defaultFiles || [])
  const onDrop = (newFiles: File[]) => {
    let updatedFiles = files.slice()
    updatedFiles = updatedFiles.concat(newFiles)
    setFiles(updatedFiles)
    onChange(updatedFiles)
    onBlur()
  }

  const removeFile = (idx: number) => {
    const updatedFiles = files.slice()
    updatedFiles.splice(idx, 1)
    setFiles(updatedFiles)
    onChange(updatedFiles)
    onBlur()
  }

  let dropZoneProps: any = {
    onDrop,
  }
  if (accept) {
    dropZoneProps = { ...dropZoneProps, accept }
  }
  const { getRootProps, getInputProps, isDragActive } =
    useDropzone(dropZoneProps)

  return (
    <Wrapper>
      <FieldLabel variant="body2">{label || 'Uploaded File(s)'}</FieldLabel>
      <DragAndDropDiv {...getRootProps({ refKey: 'ref' })}>
        <input {...getInputProps()} data-cy="fileUploadInput" />
        {isDragActive ? (
          <p>Drop file to upload</p>
        ) : (
          <p>Drag and drop file or click here to upload</p>
        )}
      </DragAndDropDiv>
      {files.length === 0 ? (
        <Typography>-</Typography>
      ) : (
        <>
          {files.map((file: File, idx: number) => (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography>{file.name}</Typography>
              <IconButton onClick={() => removeFile(idx)} sx={{ padding: 0 }}>
                <Close />
              </IconButton>
            </Box>
          ))}
        </>
      )}
      <ErrorText>{helperText}</ErrorText>
    </Wrapper>
  )
}

FileUpload.defaultProps = {
  helperText: '',
}

export default FileUpload
