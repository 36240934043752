import React from 'react'
import MuiTextField, { TextFieldProps } from '@mui/material/TextField'
import { styled } from '@mui/material/styles'
import { MenuItem } from '@mui/material'
import { KeyboardArrowDown } from '@mui/icons-material'
import WebFont from '../webfontloader'
import { MOBILE_BREAKPOINT } from '../constants/constants'
import Typography from '../Typography/Typography'
import flockColors from '../flockColors/flockColors'

WebFont.load({
  google: {
    families: ['Inter&display=swap'],
  },
})

const TextFieldWrapper = styled('div')({
  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const FieldLabel = styled(Typography)({
  fontWeight: 'bold',
  paddingBottom: '0.5rem',
})

const SmallFieldLabel = styled(Typography)({
  color: flockColors.darkGray,
  paddingBottom: '0.25rem',
})

const StyledTextField = styled(MuiTextField)({
  '& > div > div': {
    paddingBottom: '0.65rem',
    paddingTop: '0.65rem',
    minWidth: '5rem',
    display: 'flex',
    alignItems: 'center',
  },

  '& > div > input': {
    fontFamily: 'Inter',
  },

  '& > p': {
    marginLeft: 0,
    fontFamily: 'Inter',
  },

  [MOBILE_BREAKPOINT]: {
    width: '100%',
  },
})

const Option = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
})

const DropdownIcon = styled(KeyboardArrowDown)({
  marginLeft: '-2rem',
  zIndex: 1,
  paddingRight: '0.5rem',
})

type DropdownOption = {
  text: string
  value: string | number
}

export type DropdownProps = TextFieldProps & {
  options: DropdownOption[]
}

const Dropdown = (props: DropdownProps) => {
  const { label, size, options, ...otherProps } = props
  return (
    <TextFieldWrapper>
      {size === 'small' ? (
        <SmallFieldLabel variant="body2">{label}</SmallFieldLabel>
      ) : (
        <FieldLabel>{label}</FieldLabel>
      )}
      <StyledTextField
        {...otherProps}
        select
        size={size}
        defaultValue={otherProps.defaultValue || options[0].value}
        SelectProps={{
          IconComponent: () => <DropdownIcon />,
        }}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.text}
          </Option>
        ))}
      </StyledTextField>
    </TextFieldWrapper>
  )
}

Dropdown.defaultProps = {
  size: 'small',
}

export default Dropdown
