import React from 'react'
import {
  DatePicker as MuiDatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { styled } from '@mui/material/styles'
import { TextFieldProps } from '@mui/material'
import TextField from '../TextField/TextField'
import flockColors from '../flockColors/flockColors'
import Typography from '../Typography/Typography'

export type DatePickerProps = any & {
  fieldProps?: TextFieldProps
  defaultValue?: Date
  helperText?: string
  selectTime?: boolean
}

const ErrorText = styled(Typography)({
  color: flockColors.red,
  fontSize: '0.75rem',
})

const StyledTextField = styled(TextField)({
  width: '100%',
  '& > div > input': {
    minWidth: 'unset',
  },
})

const DatePicker = (props: DatePickerProps) => {
  const {
    label,
    fieldProps,
    defaultValue,
    helperText,
    selectTime,
    ...otherProps
  } = props
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {selectTime ? (
        <DateTimePicker
          {...otherProps}
          value={otherProps.value || defaultValue || null}
          renderInput={(params) => (
            <StyledTextField {...fieldProps} {...params} label={label} />
          )}
        />
      ) : (
        <MuiDatePicker
          {...otherProps}
          value={otherProps.value || defaultValue || null}
          renderInput={(params) => (
            <StyledTextField {...fieldProps} {...params} label={label} />
          )}
        />
      )}

      <ErrorText>{helperText}</ErrorText>
    </LocalizationProvider>
  )
}

DatePicker.defaultProps = {
  fieldProps: null,
  defaultValue: null,
  helperText: null,
}

export default DatePicker
