import { useMutation } from '@apollo/client'
import { useSnackbar } from '@flock/flock-component-library'
import {
  AdminStaticDocumentsCreateStaticDocumentDocument,
  AdminStaticDocumentsGetStaticDocumentsDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { AnyInputConfig, InputType } from '@flock/shared-ui'
import React, { useState } from 'react'
import GridFormModal from '../shared/GridFormModal'
import { LeadDocumentType } from '../shared/sharedTypes'

const inputConfigs: AnyInputConfig[] = [
  {
    name: 'name',
    type: InputType.Text,
    required: true,
    props: {
      label: 'Display Name',
    },
  },
  {
    name: 'documentType',
    type: InputType.Dropdown,
    required: true,
    props: {
      label: 'Document Type',
      options: [
        {
          label: 'Educational',
          value: LeadDocumentType.EDUCATIONAL,
        },
        {
          label: 'Performance',
          value: LeadDocumentType.PERFORMANCE,
        },
        {
          label: 'Legal',
          value: LeadDocumentType.LEGAL,
        },
      ],
    },
  },
  {
    name: 'defaultEnabled',
    type: InputType.Checkbox,
    props: {
      label: 'Visible by default',
    },
  },
  {
    name: 'uploadedFile',
    type: InputType.FileUpload,
    required: true,
    props: {
      label: 'Upload your file.',
    },
  },
]

type CreateStaticDocumentParams = {
  name: string
  documentType: string
  defaultEnabled: boolean
  uploadedFile: File[]
}

type CreateStaticDocumentModalProps = {
  open: boolean
  onClose: () => void
}

const CreateStaticDocumentModal = (props: CreateStaticDocumentModalProps) => {
  const { open, onClose } = props
  const { notify } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const [createStaticDocument] = useMutation(
    AdminStaticDocumentsCreateStaticDocumentDocument
  )

  const onSubmit = async (result: CreateStaticDocumentParams) => {
    setLoading(true)
    const { name, documentType, defaultEnabled, uploadedFile } = result
    const file = uploadedFile[0]
    const createStaticDocumentInput = {
      name,
      documentType,
      defaultEnabled,
      file,
    }

    try {
      await createStaticDocument({
        variables: {
          input: createStaticDocumentInput,
        },
        refetchQueries: [AdminStaticDocumentsGetStaticDocumentsDocument],
      })
      notify('Successfully created static document', 'success')
      onClose()
    } catch (e) {
      notify('Failed to create static document', 'error')
    }
    setLoading(false)
  }

  return (
    <GridFormModal
      title="Create Document"
      open={open}
      onClose={onClose}
      inputConfigs={inputConfigs}
      onSubmit={onSubmit}
      loading={loading}
      gridProps={{
        spacing: 3,
      }}
    />
  )
}

export default CreateStaticDocumentModal
